#confirmation-legacy {
  font-family: "Poppins-Light", sans-serif;
  font-size: 16px;
  line-height: 23px;
  text-align: center;
  color: #F9F9F9;
  padding-top: 30px;
  padding-bottom: 100px; }
  #confirmation-legacy .confirmation-container {
    margin-bottom: 40px;
    max-width: 500px;
    margin: 0 auto;
    background-color: #FFFFFF;
    border-radius: 3px;
    padding: 0 0 20px 0; }
    #confirmation-legacy .confirmation-container .hero-container {
      background-size: contain;
      background-repeat: no-repeat;
      max-width: 727px; }
      @media (max-width: 550px) {
        #confirmation-legacy .confirmation-container .hero-container {
          background-size: 190%; } }
      #confirmation-legacy .confirmation-container .hero-container .hero-content .summary-title {
        font-family: "Poppins-SemiBold", sans-serif;
        font-size: 45px;
        line-height: 55px;
        color: #FFFFFF;
        padding: 80px 0 20px 0;
        margin: 0; }
        @media (max-width: 1279px) {
          #confirmation-legacy .confirmation-container .hero-container .hero-content .summary-title {
            font-size: 30px; } }
      #confirmation-legacy .confirmation-container .hero-container .hero-content .details-wrapper {
        text-align: center;
        margin: 0 auto; }
        #confirmation-legacy .confirmation-container .hero-container .hero-content .details-wrapper .appt-detail-container {
          background: #FFFFFF;
          margin: 20px auto 10px auto;
          padding: 0 20px 20px 20px;
          max-width: 540px;
          max-height: 320px;
          -webkit-box-shadow: 0px 2px 5px 0px #e8e8e8;
          -moz-box-shadow: 0px 2px 5px 0px #e8e8e8;
          box-shadow: 0px 2px 5px 0px #e8e8e8;
          font-family: "Poppins-Regular", sans-serif;
          line-height: 23px;
          color: #5E6369;
          border-radius: 10px; }
          #confirmation-legacy .confirmation-container .hero-container .hero-content .details-wrapper .appt-detail-container .edit-address {
            font-family: "Poppins-SemiBold", sans-serif;
            font-size: 13px;
            color: #7089BE;
            text-decoration: none; }
          #confirmation-legacy .confirmation-container .hero-container .hero-content .details-wrapper .appt-detail-container .avatar .a1, #confirmation-legacy .confirmation-container .hero-container .hero-content .details-wrapper .appt-detail-container .avatar .a2 {
            background-size: cover;
            margin: -20px auto 0 auto;
            height: 40px; }
          #confirmation-legacy .confirmation-container .hero-container .hero-content .details-wrapper .appt-detail-container .avatar .a1 {
            width: 40px; }
          #confirmation-legacy .confirmation-container .hero-container .hero-content .details-wrapper .appt-detail-container .avatar .a2 {
            width: 61px; }
          #confirmation-legacy .confirmation-container .hero-container .hero-content .details-wrapper .appt-detail-container .modality {
            font-size: 16px;
            line-height: 17px;
            color: #2C3239;
            padding-top: 5px; }
          #confirmation-legacy .confirmation-container .hero-container .hero-content .details-wrapper .appt-detail-container .mt-gender {
            font-size: 15px;
            line-height: 17px;
            color: #5F6368;
            padding: 10px 0 15px 0; }
          #confirmation-legacy .confirmation-container .hero-container .hero-content .details-wrapper .appt-detail-container .bottom {
            padding-top: 10px; }
          #confirmation-legacy .confirmation-container .hero-container .hero-content .details-wrapper .appt-detail-container .left {
            height: 100%;
            text-align: left;
            vertical-align: top;
            padding: 5px 0; }
          #confirmation-legacy .confirmation-container .hero-container .hero-content .details-wrapper .appt-detail-container .right {
            width: 50%;
            height: 100%;
            display: inline-block;
            text-align: left;
            vertical-align: top; }
          #confirmation-legacy .confirmation-container .hero-container .hero-content .details-wrapper .appt-detail-container .icon-container {
            vertical-align: top;
            width: 25px;
            margin-top: 3px; }
            #confirmation-legacy .confirmation-container .hero-container .hero-content .details-wrapper .appt-detail-container .icon-container .icon-1 {
              width: 15px;
              height: 15px; }
            #confirmation-legacy .confirmation-container .hero-container .hero-content .details-wrapper .appt-detail-container .icon-container .icon-2 {
              width: 11px;
              height: 15px; }
            #confirmation-legacy .confirmation-container .hero-container .hero-content .details-wrapper .appt-detail-container .icon-container .icon-3 {
              width: 13px;
              height: 15px; }
            #confirmation-legacy .confirmation-container .hero-container .hero-content .details-wrapper .appt-detail-container .icon-container .icon-4 {
              width: 15px;
              height: 15px; }
          #confirmation-legacy .confirmation-container .hero-container .hero-content .details-wrapper .appt-detail-container .icon-container, #confirmation-legacy .confirmation-container .hero-container .hero-content .details-wrapper .appt-detail-container .copy-container {
            display: inline-block; }
          @media (max-width: 550px) {
            #confirmation-legacy .confirmation-container .hero-container .hero-content .details-wrapper .appt-detail-container {
              padding: 0 50px 20px 50px; }
              #confirmation-legacy .confirmation-container .hero-container .hero-content .details-wrapper .appt-detail-container .bottom {
                padding-top: 0; }
              #confirmation-legacy .confirmation-container .hero-container .hero-content .details-wrapper .appt-detail-container .right, #confirmation-legacy .confirmation-container .hero-container .hero-content .details-wrapper .appt-detail-container .left {
                width: 100%;
                margin: 0 auto;
                padding: 5px 0; } }
    #confirmation-legacy .confirmation-container .confirmation-header {
      font-size: 32px;
      font-family: "Poppins-SemiBold", sans-serif;
      line-height: 30px;
      color: #5E6369;
      margin: 0 50px 10px 50px; }
      @media (max-width: 1024px) {
        #confirmation-legacy .confirmation-container .confirmation-header {
          font-size: 20px; } }
    #confirmation-legacy .confirmation-container .progress-container {
      max-width: 374px;
      margin: 0 auto; }
      #confirmation-legacy .confirmation-container .progress-container .progress-copy .text {
        display: inline-block;
        width: 80px;
        text-align: center;
        font-family: "Poppins-SemiBold", sans-serif;
        font-size: 12px; }
      #confirmation-legacy .confirmation-container .progress-container #progress-bar {
        background-size: 100%;
        margin: 0 auto;
        width: 320px;
        height: 10px; }
    #confirmation-legacy .confirmation-container #thank-you {
      font-size: 30px;
      margin: 20px; }
    #confirmation-legacy .confirmation-container .summary-notification-copy {
      margin: 25px 0; }
    #confirmation-legacy .confirmation-container .gray-line {
      max-width: 570px;
      border-top: 1px solid rgba(80, 101, 146, 0.15);
      margin: 40px auto 50px auto; }
      @media (max-width: 1279px) {
        #confirmation-legacy .confirmation-container .gray-line {
          max-width: 273px; } }
    #confirmation-legacy .confirmation-container .detail-wrapper, #confirmation-legacy .confirmation-container .detail-wrapper-grey {
      margin: 5px auto;
      text-align: center; }
    #confirmation-legacy .confirmation-container .detail-wrapper {
      padding-bottom: 50px; }
    #confirmation-legacy .confirmation-container .detail-wrapper-grey {
      color: #5E6369;
      padding-top: 0px !important;
      margin-top: 40px; }
    #confirmation-legacy .confirmation-container .expect-icon {
      background-size: 100%;
      background-repeat: no-repeat;
      height: 100px;
      width: 100px;
      margin: 50px auto 11px auto; }
      @media (max-width: 1279px) {
        #confirmation-legacy .confirmation-container .expect-icon {
          margin: 40px auto 11px auto;
          height: 84px;
          width: 84px; } }
    #confirmation-legacy .confirmation-container .download {
      display: block; }
      #confirmation-legacy .confirmation-container .download .download-header {
        margin: 60px 0 10px 0;
        color: #29323B; }
      #confirmation-legacy .confirmation-container .download .download-app-store, #confirmation-legacy .confirmation-container .download .download-google-store {
        display: inline-block;
        background-size: cover;
        width: 170px;
        height: 56px;
        margin: 15px 5px; }
        @media (max-width: 450px) {
          #confirmation-legacy .confirmation-container .download .download-app-store, #confirmation-legacy .confirmation-container .download .download-google-store {
            width: 120px;
            height: 40px; } }
    #confirmation-legacy .confirmation-container .continue-btn-container {
      text-align: center;
      margin: 50px auto 40px auto; }
      #confirmation-legacy .confirmation-container .continue-btn-container .continue-btn {
        background: #F58266;
        color: #FFFFFF;
        border-radius: 50px;
        font-family: "Poppins-SemiBold", sans-serif;
        font-size: 18px;
        line-height: 22px;
        padding: 10.5px 82px !important;
        text-decoration: none;
        border: none; }
        @media (max-width: 1024px) {
          #confirmation-legacy .confirmation-container .continue-btn-container .continue-btn {
            font-size: 16px !important;
            line-height: 14px !important;
            margin: 30px auto 0 auto !important;
            padding: 7.4px 82px !important; } }
      #confirmation-legacy .confirmation-container .continue-btn-container .continue-btn:focus {
        outline: none; }
    #confirmation-legacy .confirmation-container a {
      cursor: pointer;
      text-decoration: none; }
    #confirmation-legacy .confirmation-container a:hover,
    #confirmation-legacy .confirmation-container a:visited,
    #confirmation-legacy .confirmation-container a:link,
    #confirmation-legacy .confirmation-container a:active {
      text-decoration: none; }
    @media (max-width: 800px) {
      #confirmation-legacy .confirmation-container {
        background: #FFFFFF;
        padding-top: 0;
        width: 100%;
        max-width: 100%; } }
