.label-padding {
  margin: 8px 0; }

.editUserModal.overlay {
  background: #F9F9F9;
  padding-top: 70px;
  padding-bottom: 70px;
  min-height: 100vh; }
  .editUserModal.overlay .signup-header {
    font-family: "Poppins-SemiBold", sans-serif;
    font-size: 45px;
    line-height: 55px;
    color: #39455E;
    text-align: center;
    margin-top: 40px; }
  .editUserModal.overlay .marketing-optin {
    font-family: "Poppins-Light", sans-serif;
    margin: 20px 0 !important;
    color: #5F6368;
    font-size: 12px;
    line-height: 17px;
    text-align: center; }
  .editUserModal.overlay .optin-text {
    font-family: "Poppins-Light", sans-serif;
    padding: 0 0 10px 10px; }
  .editUserModal.overlay .popup {
    margin: 0 auto;
    background: #FFFFFF;
    width: 80%;
    max-width: 725px;
    position: relative;
    transition: all 5s ease-in-out; }
    .editUserModal.overlay .popup .header h3 {
      text-align: center;
      font-family: "Poppins-SemiBold", sans-serif; }
    .editUserModal.overlay .popup .header a {
      text-align: center;
      border-radius: 0px;
      margin: 0 !important;
      color: #000000 !important;
      background-color: #D9D9D9;
      font-family: "Poppins-SemiBold", sans-serif !important;
      padding: 10px !important;
      font-size: 14px !important; }
    .editUserModal.overlay .popup .header a.active {
      background-color: #FFFFFF !important; }
    .editUserModal.overlay .popup .content {
      clear: both;
      max-height: 30%;
      overflow: auto;
      padding: 20px 0 80px 0;
      max-width: 475px;
      margin: 0 auto; }
      .editUserModal.overlay .popup .content input:focus {
        border: 1.5px solid #7089BE !important; }
      .editUserModal.overlay .popup .content .form-control {
        box-shadow: 0 !important;
        -webkit-box-shadow: none !important;
        -moz-box-shadow: none !important;
        color: #2C3239;
        border: 1px solid transparent;
        border-radius: 50px;
        background: #F9F9F9;
        margin-bottom: 12px; }
      .editUserModal.overlay .popup .content .signup-form-lable {
        display: block;
        color: #5F6368;
        width: auto;
        font-size: 13px;
        font-family: "Poppins-Medium", sans-serif;
        background: #FFFFFF;
        padding: 0 5px 0 3px;
        -webkit-transition: all 0.5s ease;
        transition: all 0.5s ease;
        pointer-events: none; }
      .editUserModal.overlay .popup .content .phone-label-margin {
        margin-bottom: -30px; }
      .editUserModal.overlay .popup .content .btn-container {
        text-align: center; }
        .editUserModal.overlay .popup .content .btn-container .active {
          height: 52px;
          background-color: #F58266;
          color: #FFFFFF;
          font-family: "Poppins-SemiBold", sans-serif;
          font-size: 18px;
          line-height: 40px;
          max-width: 250px;
          margin: 0 auto;
          text-align: center; }
      .editUserModal.overlay .popup .content .back-btn-container {
        margin-top: 20px;
        text-align: center; }
        .editUserModal.overlay .popup .content .back-btn-container .back-btn {
          color: #F58266;
          font-family: "Poppins-SemiBold", sans-serif;
          font-size: 18px;
          line-height: 22px;
          box-shadow: none; }
  .editUserModal.overlay .intl-tel-input {
    width: 100%; }
  .editUserModal.overlay .invalid {
    border: 1px solid #ff0000; }
  .editUserModal.overlay .form-group {
    margin: 3px 0px; }
  .editUserModal.overlay .terms {
    color: #5D6369;
    font-family: "Poppins-Light", sans-serif;
    font-size: 12px;
    text-align: center;
    width: 100%;
    margin: 5px 0px; }
  .editUserModal.overlay .terms a {
    text-decoration: none;
    color: #5D6369; }
  .editUserModal.overlay .forgotPassword {
    float: right;
    color: #7089BE;
    text-decoration: none; }
  .editUserModal.overlay .line-wrapper {
    overflow: hidden;
    text-align: center;
    display: block;
    margin: 15px 0px;
    color: #D9D9D9; }
  .editUserModal.overlay .line-wrapper:before,
  .editUserModal.overlay .line-wrapper:after {
    background-color: #D9D9D9;
    content: "";
    display: inline-block;
    height: 1px;
    position: relative;
    vertical-align: middle;
    width: 50%; }
  .editUserModal.overlay .line-wrapper:before {
    right: 0.5em;
    margin-left: -50%; }
  .editUserModal.overlay .line-wrapper:after {
    left: 0.5em;
    margin-right: -50%; }

@media screen and (max-width: 700px) {
  .editUserModal .popup {
    width: 90%; } }
