.dashed-tracker-wrapper {
  display: flex;
  align-content: center;
  justify-content: center;
  width: 50vw;
  margin: auto;
  margin-bottom: 35px;
  text-align: center; }

.dash {
  height: 8px;
  margin-bottom: 8px; }

.progress-step {
  flex: 1 1 100%;
  margin-right: 8px; }

.progress-step:last-child {
  margin-right: 0px; }
  .progress-step:last-child .dash {
    border-radius: 0px 4px 4px 0px; }

.progress-step:first-child > .dash {
  border-radius: 4px 0px 0px 4px; }

.dashLabel {
  text-align: center;
  font-size: 12px;
  line-height: 16px; }

.bg-orange {
  background: #F58266; }

.bg-light-orange {
  background: #FFC4B5; }

.bg-gray {
  background: #E2E2E2; }

.color-gray {
  color: #757575; }

.color-light-gray {
  color: #AFAFAF !important; }

.color-white {
  color: #FFFFFF; }

.display-flex {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%; }

@media (max-width: 800px) {
  .dashed-tracker-wrapper {
    width: 100%;
    margin: 0px;
    padding: 7px 16px; } }
