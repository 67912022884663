.text-align-center {
    text-align: center;
}

.mb-9 {
    margin-bottom: 9px;
}

.mb-3 {
    margin-bottom: 3px;
}

.btn-link,
.btn-link:hover,
.btn-link:active,
.btn-link:focus {
    text-decoration: none;

    .black-color {
        color: black;
    }
}

.dark-grayed {
    color: #757575;
}

.size-14-16 {
    font-size: 14px;
    line-height: 16px;
}

.size-14-24 {
    font-size: 14px;
    line-height: 24px;
}

.size-12-20 {
    font-size: 12px;
    line-height: 20px;
}

.selected-day-horizontal {
    color: white;

    .btn-link,
    .btn-link:hover,
    .btn-link:active,
    .btn-link:focus {
        text-decoration: none;
        background-color: #586B94;
        border-radius: 50%;

        .black-color {
            color: white;
        }
    }
}

.padding-12 {
    padding: 12px;
}

.medium-font {
    font-family: "Poppins-Medium", sans-serif;
}

.fix-col-width {
    width: 50px;
}

.d-inline-block {
    display: inline-block;
}

.table>tbody>tr>td,
.table>tbody>tr>th,
.table>tfoot>tr>td,
.table>tfoot>tr>th,
.table>thead>tr>td,
.table>thead>tr>th {
    border-top: none;
}