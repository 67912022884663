.chats-list .avatar-img {
  justify-content: 'center';
  align-items: "center";
  border-radius: 50%;
  border: none !important;
  z-index: 0 !important; }

.chats-list .shrink-size {
  width: 28px;
  height: 28px; }

.chats-list .MuiAvatarGroup-avatar {
  margin-left: -16px !important;
  margin-top: 10px !important; }

.chats-list .MuiAvatarGroup-avatar:first-child {
  margin-left: 0px !important;
  margin-top: 0px !important; }

.chats-list .MuiDivider-root {
  height: .5px; }
