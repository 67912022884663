.p-fixed {
  position: fixed; }

.top-0 {
  top: 0; }

.right-0 {
  right: 0px; }

.left-0 {
  left: 0; }

.top-16 {
  top: 16px; }

.right-16 {
  right: 16px; }

.nav-header-style {
  font-family: "Poppins-Medium", sans-serif;
  color: #FFFFFF;
  width: 100%;
  background: transparent;
  height: 80px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  z-index: 999999999999;
  padding: 0vw 2.5vw; }
  .nav-header-style .signup-link {
    color: #fff;
    text-decoration: none; }

.logo-wrapper {
  margin: 0 auto;
  width: 13vw;
  max-width: calc(100%/3); }
  .logo-wrapper .soothe-logo-icon {
    width: 100%; }

.logo-wrapper:hover {
  text-decoration: none; }

.header-actions {
  font-size: 16px;
  line-height: 20px;
  text-align: end;
  width: calc(100%/3); }

.user-avatar-style {
  width: 32px;
  height: 32px;
  border-radius: 50%;
  background-color: white;
  cursor: pointer; }

.btn-login,
.btn-login:hover,
.btn-login:active,
.btn-login:focus {
  background: #F58266;
  border-radius: 48px;
  margin-left: 40px;
  color: white; }

.back-arrow-wrapper,
.back-arrow-wrapper:hover,
.back-arrow-wrapper:active,
.back-arrow-wrapper:focus {
  font-family: "Poppins-Medium", sans-serif;
  font-size: 18px;
  line-height: 24px;
  text-decoration: none;
  color: #FFFFFF;
  width: calc(100%/3); }

.nav-header-style.with-white-bg {
  border-bottom: 1px solid #E2E2E2;
  color: #586B94;
  background: #fff; }
  .nav-header-style.with-white-bg .back-arrow-wrapper,
  .nav-header-style.with-white-bg .back-arrow-wrapper:hover,
  .nav-header-style.with-white-bg .back-arrow-wrapper:active,
  .nav-header-style.with-white-bg .back-arrow-wrapper:focus {
    color: black; }
  .nav-header-style.with-white-bg .signup-link {
    color: #586B94; }

.MuiMenuItem-root {
  color: black !important;
  font-family: "Poppins-Regular", sans-serif !important;
  padding: 12px 16px !important; }

.sm-shown {
  display: none; }

.visibility-hidden {
  visibility: hidden; }

.m-0 {
  margin: 0px; }

.mr-15 {
  margin-right: 15px; }

.action-btn,
.action-btn:hover,
.action-btn:focus,
.action-btn:active {
  background: #F58266;
  border-radius: 48px;
  color: white;
  margin-left: 0px; }

.over-2-grids .header-actions {
  width: 100%; }

.with-border-bottom {
  border-bottom: 1px solid #E2E2E2; }

.drop-shadow a {
  filter: drop-shadow(1px 1px 2px gray); }

.header-link-style {
  font-family: "Poppins-Medium", sans-serif;
  margin-left: 30px;
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
  color: #000000; }

.header-link-style.active,
.header-link-style:hover,
.header-link-style:focus {
  color: #586B94 !important; }

.ml-16 {
  margin-left: 16px; }

@media (max-width: 800px) {
  .nav-header-style {
    height: 60px;
    padding: 11px 16px; }
  .back-arrow-wrapper {
    padding: 12px 24px 12px 0px; }
  .header-actions {
    font-size: 14px;
    line-height: 16px;
    padding: 12px;
    text-align: end; }
  .signup-link,
  .signup-link:hover {
    background: #F58266;
    border-radius: 48px;
    color: white;
    margin-left: 0px; }
  .btn-login {
    display: none; }
  .sm-hidden {
    display: none; }
  .sm-shown {
    display: initial; }
  .nav-header-style.with-white-bg .signup-link {
    color: #fff; }
  .logo-wrapper {
    width: 40px; } }
