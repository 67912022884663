.size-12-20 {
  font-size: 12px;
  line-height: 20px; }

.max-width-75 {
  max-width: 75%;
  margin: auto;
  padding: 0px; }

.background-darkest-blue {
  background-color: #39455E; }

.content-primary-white,
.content-primary-white:hover,
.content-primary-white:focus,
.content-primary-white:active {
  color: #fff;
  text-decoration: none; }

.display-flex {
  display: flex;
  padding: 0px; }

.justify-content-space-between {
  justify-content: space-between; }

.align-items-baseline {
  align-items: baseline; }

.fix-to-bottom {
  position: fixed;
  bottom: 0;
  right: 0;
  left: 0; }

.text-decoration-none {
  text-decoration: none; }

.text-align-center {
  text-align: center; }

.pt-32 {
  padding-top: 32px; }

.pb-32 {
  padding-bottom: 32px; }

.mtb-20 {
  margin-top: 20px;
  margin-bottom: 20px; }

footer .MuiList-root .MuiTypography-root,
footer .MuiList-root .MuiSvgIcon-root {
  color: #fff; }

@media (max-width: 992px) {
  .max-width-80,
  .max-width-75,
  .max-width-50 {
    max-width: 100%;
    margin: auto;
    padding: 16px; }
  .sm-p-0 {
    padding: 0px; }
  .sm-p-16 {
    padding: 16px; }
  .sm-m-16 {
    margin: 16px; }
  .sm-ptb-0-40 {
    padding-top: 0px;
    padding-bottom: 40px; }
  .sm-none {
    display: none; }
  .sm-pt-14 {
    padding-top: 14px; }
  .sm-width-100 {
    width: 100%; }
  .sm-border-radius-0 {
    border-radius: 0px; }
  .sm-max-modal-height {
    max-height: 100%; }
  .sm-mt-0 {
    margin-top: 0px; }
  .sm-mt-88 {
    margin-top: 88px; }
  .sm-p-16-20 {
    padding: 16px 20px; }
  .sm-size-18-24 {
    font-size: 18px;
    line-height: 24px; }
  .sm-mr-0 {
    margin-right: 0; } }
