.soothe-plus .subcription-details {
  text-align: center;
  font-family: "Poppins-Regular", sans-serif;
  font-size: 15px;
  color: #5F6368;
  padding: 10px 0 30px 0; }

.soothe-plus .detail-summary {
  margin: 0 0 10px 0; }

.soothe-plus .soothe-plus-details {
  font-family: "Poppins-Regular", sans-serif; }
  .soothe-plus .soothe-plus-details .container {
    max-width: 335px;
    padding: 0;
    color: #5F6368; }
  .soothe-plus .soothe-plus-details .plus-current, .soothe-plus .soothe-plus-details .plus-credits {
    margin: 20px 0 30px 0; }

.soothe-plus .book-container {
  text-align: center;
  max-width: 470px;
  margin: 0 auto; }
  .soothe-plus .book-container .subscribe-cta {
    border-radius: 50px;
    background-color: #F58266;
    color: #FFFFFF;
    font-family: "Poppins-SemiBold", sans-serif !important;
    letter-spacing: .5px;
    font-size: 18px;
    line-height: 42px;
    outline: none;
    max-width: 350px;
    height: 52px;
    text-align: center;
    margin-top: 30px; }
  .soothe-plus .book-container .subscribe-cta:focus {
    color: #FFFFFF; }

.cancel-instructions {
  max-width: 400px;
  margin: 0 auto; }

.cancel-reasons-container {
  padding-top: 30px;
  text-align: left;
  max-width: 330px;
  margin: 0 auto; }
  .cancel-reasons-container .cancel-checkbox-container .cancel-input {
    vertical-align: top;
    display: inline-block; }
  .cancel-reasons-container .cancel-checkbox-container .cancel-input {
    display: none !important; }
  .cancel-reasons-container .cancel-checkbox-container .label-item {
    opacity: 1;
    margin: 5px 0 0 0; }
    .cancel-reasons-container .cancel-checkbox-container .label-item img {
      margin-top: 3px;
      width: 20px;
      height: 20px;
      cursor: pointer;
      vertical-align: top; }
    .cancel-reasons-container .cancel-checkbox-container .label-item span {
      display: inline-block;
      max-width: 290px; }
  .cancel-reasons-container .cancel-checkbox-container .cancel-input:checked + label {
    opacity: 1; }
  .cancel-reasons-container .cancel-checkbox-container input[type='radio']:checked {
    position: absolute;
    visibility: hidden; }

.cancellation-btn {
  margin-top: 40px; }
  .cancellation-btn a {
    background-color: #F58266;
    border-radius: 50px;
    color: #FFFFFF;
    font-family: "Poppins-SemiBold", sans-serif !important;
    height: 44px;
    max-width: 250px;
    font-size: 18px;
    line-height: 33px; }
  .cancellation-btn a:hover {
    color: #FFFFFF; }

.cancellation-change {
  padding-top: 20px; }
  .cancellation-change .cancel-change {
    color: #F58266;
    font-family: "Poppins-SemiBold", sans-serif;
    font-size: 18px; }
