.frame-title {
  text-align: center !important;
  font-size: 44px !important;
  line-height: 52px !important; }

.ml-16 {
  margin-left: 16px; }

.edit-icon {
  background-color: #EEEEEE;
  display: flex;
  display: -webkit-flex;
  padding: 10px;
  border-radius: 36px;
  width: 36px; }

.ht-36 {
  height: 36px; }

.max-width-50 {
  max-width: 50%; }

@media (max-width: 800px) {
  .max-width-50 {
    max-width: 100%; } }
