#phone-num-optin {
  /************** loading btn start **************/
  /************** loading btn end **************/ }
  #phone-num-optin textarea:focus, #phone-num-optin input:focus {
    outline: none; }
  #phone-num-optin .form-control:focus {
    border-color: #ccc;
    -webkit-box-shadow: none;
    box-shadow: none; }
  #phone-num-optin .hero {
    background: url("phone-optin-hero.jpg") no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 65vh;
    height: auto;
    min-width: 800px;
    width: 100%;
    left: 0; }
    #phone-num-optin .hero .optin {
      color: #FFFFFF;
      margin: 50px 0 0 40px; }
      #phone-num-optin .hero .optin .optin-copy h1 {
        font-family: "gotham-htf-bold", sans-serif;
        font-size: 32px;
        margin-bottom: 40px; }
      #phone-num-optin .hero .optin .optin-copy .body-copy {
        font-family: "gotham-htf-book", sans-serif, sans-serif;
        font-size: 20px;
        margin-bottom: 30px;
        max-width: 600px; }
      #phone-num-optin .hero .optin .optin-copy .prompt-copy {
        font-family: "gotham-htf-bold", sans-serif;
        font-size: 25px;
        margin-bottom: 10px; }
      #phone-num-optin .hero .optin .phone-input {
        display: inline;
        max-width: 220px;
        border-radius: 0; }
      #phone-num-optin .hero .optin .submit-input {
        display: inline;
        margin-top: -3px;
        width: 180px;
        color: #FFFFFF;
        background: #67D3CC;
        border-radius: 0;
        font-family: "gotham-htf-bold", sans-serif; }
  #phone-num-optin .hero:before {
    content: '';
    position: absolute;
    height: 65vh;
    top: 80px;
    right: 0;
    bottom: 0;
    left: 0;
    background-image: linear-gradient(to left, rgba(0, 0, 0, 0), black);
    opacity: .9; }
  #phone-num-optin .hero-mobile {
    background: url("phone-optin-hero-mobile.jpg") no-repeat center center;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    min-height: 40vh;
    min-width: 320px;
    width: 100%;
    height: auto;
    left: 0; }
  #phone-num-optin .optin-container {
    text-align: center; }
    #phone-num-optin .optin-container h1 {
      font-family: "gotham-htf-bold", sans-serif;
      font-size: 31px;
      margin-bottom: 40px; }
    #phone-num-optin .optin-container .body-copy {
      padding: 0 15px;
      font-family: "gotham-htf-book", sans-serif;
      font-size: 23px;
      margin-bottom: 20px; }
    #phone-num-optin .optin-container .form-section {
      padding: 40px 0;
      background: #FBFBFB; }
      #phone-num-optin .optin-container .form-section .prompt-copy {
        font-family: "gotham-htf-bold", sans-serif;
        font-size: 25px;
        margin-bottom: 10px; }
      #phone-num-optin .optin-container .form-section .phone-input {
        display: inline;
        max-width: 220px;
        border-right: none;
        border-radius: 0;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        font-family: "gotham-htf-book", sans-serif; }
      #phone-num-optin .optin-container .form-section .submit-input {
        display: inline;
        margin-top: -4px;
        width: 80px;
        color: #67D3CC;
        background: #FFFFFF;
        border: 1px solid #ccc;
        border-left: none;
        border-radius: 0;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        box-shadow: none;
        font-family: "gotham-htf-bold", sans-serif; }
  #phone-num-optin .glyphicon-refresh-animate {
    -animation: spin .7s infinite linear;
    -webkit-animation: spin2 .7s infinite linear; }

@-webkit-keyframes spin2 {
  from {
    -webkit-transform: rotate(0deg); }
  to {
    -webkit-transform: rotate(360deg); } }

@keyframes spin {
  from {
    transform: scale(1) rotate(0deg); }
  to {
    transform: scale(1) rotate(360deg); } }
