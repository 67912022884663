.display-block {
  display: block;
}

.medium-font {
  font-family: "Poppins-Medium", sans-serif;
}

.regular-font {
  font-family: "Poppins-Regular", sans-serif;
}

.max-width-75 {
  max-width: 75vw;
  margin: auto;
}

.max-width-65 {
  max-width: 65vw;
  margin: auto;
}

.max-width-60 {
  max-width: 60vw;
  margin: auto;
}

.max-width-50 {
  width: 50vw;
  margin: auto;
}

.max-width-55 {
  width: 55vw;
  margin: auto;
}

.max-width-47 {
  max-width: 47vw;
  margin: auto;
}

.max-width-30 {
  max-width: 30vw;
  margin: auto;
}

.max-width-25 {
  max-width: 25vw;
  margin: auto;
}

.display-flex {
  display: flex;
  display: -webkit-flex;
  justify-content: center;
  -webkit-justify-content: center;
  align-items: center;
  height: 100%;
}

.main-text {
  font-size: 52px;
  line-height: 64px;
  text-align: center;
  color: #FFFFFF;
  margin-bottom: 32px;
}

.centered-list {
  text-align: center;
  font-size: 36px;
  line-height: 44px;
  padding: 0px;
}

.centered-list-item {
  padding: 16px 0px;
  display: block;
  width: 100%;
  list-style: none;
  cursor: pointer;
}

.color-white .centered-list-item:not(:last-child) {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2);
}

.color-gray .centered-list-item:not(:last-child) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.centered-list-item.selected-item {
  color: #F58266 !important;
}

.color-red {
  color: #C11500;
}

.color-white {
  color: white;
}

.color-gray {
  color: #545454;
}

.color-light-gray {
  color: #757575;
}

.color-black {
  color: black;
}

.color-blue {
  color: #7089BE;
}

.color-green {
  color: #05944F;
}

.background-primary {
  background-color: #FFFFFF;
}

.gray-strike-through {
  color: #AFAFAF;
  text-decoration: line-through;
}

.justify-content-center {
  justify-content: center !important;
  -webkit-justify-content: center !important;
}

.rdtPicker {
  font-family: "Poppins-Regular", sans-serif !important;
  padding: 0px;
}

.rdtPicker td.rdtToday:before {
  display: none;
}

.negative-msg {
  font-size: 14px;
  line-height: 20px;
  color: #C11500;
}

.size-44-52 {
  font-size: 44px;
  line-height: 52px;
}

.size-36-44 {
  font-size: 36px;
  line-height: 44px;
}

.size-32-40 {
  font-size: 32px;
  line-height: 40px;
}

.size-28-36 {
  font-size: 28px;
  line-height: 36px;
}

.size-24-32 {
  font-size: 24px;
  line-height: 32px;
}

.size-20-28 {
  font-size: 20px;
  line-height: 28px;
}

.size-18-24 {
  font-size: 18px;
  line-height: 24px;
}

.size-18-28 {
  font-size: 18px;
  line-height: 28px;
}

.size-16-24 {
  font-size: 16px;
  line-height: 24px;
}

.size-16-20 {
  font-size: 16px;
  line-height: 20px;
}

.size-14-16 {
  font-size: 14px;
  line-height: 16px;
}

.size-14-20 {
  font-size: 14px;
  line-height: 20px;
}

.size-12-20 {
  font-size: 12px;
  line-height: 20px;
}

.mt-10vh {
  margin-top: 10vh;
}

.mt-1205vh {
  margin-top: 12.5vh;
}

.mt-15vh {
  margin-top: 15vh;
}

.mt-20vh {
  margin-top: 20vh;
}

.mt-big {
  margin-top: 80px;
}

.mt-56 {
  margin-top: 56px;
}

.txt-center {
  text-align: center;
}

.txt-right {
  text-align: right;
}

.txt-left {
  text-align: left;
}

.mb-48 {
  margin-bottom: 48px;
}

.mb-44 {
  margin-bottom: 44px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-36 {
  margin-bottom: 36px;
}

.mb-32 {
  margin-bottom: 32px;
}

.mb-24 {
  margin-bottom: 24px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-4 {
  margin-bottom: 4px;
}

.mt-14 {
  margin-top: 14px;
}

.mt-12 {
  margin-top: 12px;
}

.mtb-12 {
  margin: 12px 0px;
}

.mtb-32 {
  margin: 32px 0px;
}

.mt-80 {
  margin-top: 80px;
}

.mt-8 {
  margin-top: 8px;
}

.ptrb-16 {
  padding-top: 16px;
  padding-right: 16px;
  padding-bottom: 16px;
}

.pr-4 {
  padding-right: 4px;
}

.pl-4 {
  padding-left: 4px;
}

.pt-24 {
  padding-top: 24px;
}

.p-0-60-24 {
  padding: 0px 60px 24px;
}

.p-12 {
  padding: 12px;
}

.p-16-0 {
  padding: 16px 0px;
}

.p-24-0 {
  padding: 24px 0px;
}

.p-24 {
  padding: 24px;
}

.p-50-0 {
  padding: 50px 0px;
}

.plt-16 {
  padding-top: 16px;
  padding-left: 16px;
}

.pb-24 {
  padding-bottom: 24px;
}

.pb-100 {
  padding-bottom: 100px;
}

.infoButton,
.infoButton:hover,
.infoButton:active,
.infoButton:focus {
  padding: 10px 12px;
  background-color: #F1F6FF;
  border-radius: 9999px;
  font-size: 14px;
  line-height: 16px;
  color: #586B94;
  max-width: 100%;
  overflow: hidden;
  align-self: flex-start;
}

.gray-input {
  height: 48px;
  background: #EEEEEE;
  border-radius: 4px;
  width: 100%;
  color: black;
  border: 0px solid #F58266;
  font-size: 14px;
  line-height: 20px;
}

.gray-input:focus {
  border: 1px solid #F58266;
}

.p-relative {
  position: relative;
}

.light-gray-hr {
  width: 100%;
  height: 1px;
  color: #e2e2e2;
}

.gray-textarea {
  background: #EEEEEE;
  border-radius: 4px;
  width: 100%;
  color: black;
  border: 0px solid #F58266;
  font-size: 16px;
  line-height: 24px;
  padding: 16px 16px 17px;
}

.gray-textarea:focus {
  border: 1px solid #F58266;
}

input::placeholder {
  color: #757575;
}

.sm-shown {
  display: none;
}

.br-btm-gray {
  border-bottom: 4px solid rgba(0, 0, 0, 0.08);
}

.br-btm-gray-thin:not(:last-of-type) {
  border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}

.position-initial {
  position: initial;
}

.tooltip {
  right: 11%;
  left: unset !important;
  width: 40vw !important;
  max-width: unset !important;
}

.tooltip.bottom .tooltip-arrow {
  border-bottom-color: #0c2b45 !important;
  right: 5%;
  left: unset !important;
}

.tooltip .tooltip-inner {
  color: #ffffff;
  background-color: #0c2b45;
  font-size: 14px;
  line-height: 16px;
  padding: 10px 12px;
  font-family: "Poppins-Medium", sans-serif;
  width: 40vw !important;
  max-width: unset !important;
  text-align: left;
}

.gmnoprint,
.gmnoscreen,
.gm-style-cc {
  display: none;
}

.close-icon-link,
.close-icon-link:hover,
.close-icon-link:active,
.close-icon-link:focus,
.close-icon-link:visited {
  width: 100%;
  background-color: #FFFFFF;
  color: #000;
  position: absolute;
  cursor: pointer;
  left: 0;
  top: 0;
  padding: 24px;
  z-index: 1;
}

.close-icon-link .close-icon,
.close-icon-link:hover .close-icon,
.close-icon-link:active .close-icon,
.close-icon-link:focus .close-icon,
.close-icon-link:visited .close-icon {
  width: 14px;
}

.bg-transparent {
  background-color: transparent !important;
}

.row {
  display: flex;
  display: -webkit-flex;
  flex-wrap: wrap;
}

.opaque-bg-wrapper {
  background-color: #FFFFFF;
  padding: 24px 0px 0px;
  width: fit-content;
  margin: auto;
  margin-top: -24px;
}

.dark-bg-wrapper .dashLabel.color-gray {
  color: #FFF !important;
}

.dark-bg-wrapper .dashLabel.color-light-gray {
  color: #AFAFAF !important;
}

.radial-bg {
  background: radial-gradient(108.22% 130.88% at 91.47% 100%, #FEEDE9 0%, #CCD2F5 100%);
}

.radial-color {
  font-size: 46px;
  background-clip: text;
  -webkit-text-fill-color: transparent;
  background: radial-gradient(108.22% 130.88% at 91.47% 100%, #FEEDE9 0%, #CCD2F5 100%);
}

.white-bg {
  background-color: white;
}

.light-gray-bg {
  background-color: #F6F6F6;
}

.light-btn,
.light-btn:hover,
.light-btn:active,
.light-btn:focus {
  font-family: "Poppins-Medium", sans-serif;
  font-size: 18px;
  line-height: 24px;
  color: #39455E;
  border-radius: 30px;
  margin: 0px 16px;
  padding: 16px 32px;
  background: #F2F4FF;
}

.dark-btn,
.dark-btn:hover,
.dark-btn:active,
.dark-btn:focus {
  font-family: "Poppins-Medium", sans-serif;
  font-size: 18px;
  line-height: 24px;
  color: #FFFFFF;
  border-radius: 30px;
  margin: 0px 16px;
  padding: 16px 32px;
  background: #464A5B;
}

.br-radius-top {
  border-radius: 16px 16px 0px 0px;
}

.slick-track {
  margin-left: unset !important;
}

.border-radius-16 {
  border-radius: 16px;
}

.w-80 {
  width: 80%;
}

.p-16-24 {
  padding: 16px 24px;
}

.full-width-btn {
  width: 100%;
}

@media (max-width: 1200px) {
  .max-width-55 {
    width: 70vw;
    margin: auto;
  }
}

@media (max-width: 1100px) {
  .max-width-60 {
    max-width: 100%;
    width: 100%;
    padding: 16px;
  }
}

@media (max-width: 800px) {
  .mt-big {
    margin-top: 60px;
  }

  .sm-hidden {
    display: none;
  }

  .sm-shown {
    display: block;
  }

  .max-width-25,
  .max-width-75,
  .max-width-65,
  .max-width-47,
  .max-width-30,
  .max-width-60,
  .max-width-55,
  .max-width-50 {
    max-width: 100%;
    width: 100%;
    padding: 16px;
  }

  .sm-mt-44 {
    margin-top: 44px;
  }

  .main-text {
    font-size: 32px;
    line-height: 40px;
  }

  .centered-list {
    font-size: 24px;
    line-height: 32px;
  }

  .sm-color-black {
    color: black !important;
  }

  .sm-size-32-40 {
    font-size: 32px;
    line-height: 40px;
  }

  .sm-size-18-24 {
    font-size: 18px;
    line-height: 24px;
  }

  .sm-size-18-28 {
    font-size: 18px;
    line-height: 28px;
  }

  .sm-size-16-20 {
    font-size: 16px;
    line-height: 20px;
  }

  .sm-size-12-20 {
    font-size: 12px;
    line-height: 20px;
  }

  .sm-txt-left {
    text-align: left !important;
  }

  .mb-106 {
    margin-bottom: 106px;
  }

  .tooltip,
  .tooltip .tooltip-inner {
    width: 90vw !important;
    max-width: unset !important;
  }

  .opaque-bg-wrapper {
    width: auto;
    margin-top: 0px;
  }

  .p-0-60-24 {
    padding: 0px 24px 24px;
  }

  .sm-mb-16 {
    margin-bottom: 16px;
  }

  .sm-pb-100 {
    padding-bottom: 100px;
  }

  .dark-bg-wrapper .dashLabel.color-white {
    color: #757575;
  }
}