.soothe-plus {
  background: #F9F9F9;
  padding: 80px 0; }
  .soothe-plus .details-wrapper {
    max-width: 920px; }
  .soothe-plus .soothe-plus-wrapper {
    background-color: #FFFFFF !important;
    background-size: contain !important;
    background-repeat: no-repeat !important;
    max-width: 625px;
    margin: 0 auto;
    width: 100%;
    display: inline-block;
    box-shadow: 0px 0px 5px 3px rgba(214, 215, 217, 0.32);
    padding-bottom: 50px; }
    @media (max-width: 1250px) {
      .soothe-plus .soothe-plus-wrapper {
        display: block;
        margin: 0 auto; } }
  .soothe-plus .line-item-wrapper {
    background: #FFF;
    max-width: 626px;
    width: 100%;
    height: 230px;
    margin: 0 auto;
    padding: 30px 0;
    font-family: "Poppins-Regular", sans-serif;
    display: inline-block;
    float: right;
    border-radius: 5px;
    box-shadow: 0px 0px 5px 3px rgba(214, 215, 217, 0.32); }
    @media (max-width: 600px) {
      .soothe-plus .line-item-wrapper {
        padding: 30px 10px; } }
    .soothe-plus .line-item-wrapper .book-container {
      font-family: "Poppins-Light", sans-serif;
      font-size: 16px;
      color: #5F6368;
      max-width: 560px; }
      .soothe-plus .line-item-wrapper .book-container .session-info, .soothe-plus .line-item-wrapper .book-container .subtotal-price {
        float: left;
        width: 100%; }
      .soothe-plus .line-item-wrapper .book-container #length, .soothe-plus .line-item-wrapper .book-container #label {
        float: left; }
      .soothe-plus .line-item-wrapper .book-container .price, .soothe-plus .line-item-wrapper .book-container #monthly-price {
        float: right; }
      .soothe-plus .line-item-wrapper .book-container .subtotal-price {
        padding: 20px 0 20px 0;
        border-bottom: 1px solid lightgray;
        margin-bottom: 20px; }
      .soothe-plus .line-item-wrapper .book-container .total-price {
        padding-top: 30px; }
        .soothe-plus .line-item-wrapper .book-container .total-price .total {
          font-family: "Poppins-SemiBold", sans-serif;
          font-size: 16px;
          color: #2C3239;
          float: left; }
        .soothe-plus .line-item-wrapper .book-container .total-price .price {
          font-family: "Poppins-SemiBold", sans-serif;
          font-size: 20px;
          color: #7089BE; }
    @media (max-width: 1250px) {
      .soothe-plus .line-item-wrapper {
        display: block;
        margin: 50px auto 0 auto;
        float: none; } }
  .soothe-plus .heading {
    padding: 60px 0 40px 0;
    text-align: center; }
    .soothe-plus .heading h1 {
      font-family: "Poppins-SemiBold", sans-serif;
      font-size: 45px;
      line-height: 55px;
      color: #FFFFFF; }
      @media (max-width: 1250px) {
        .soothe-plus .heading h1 {
          font-size: 32px;
          line-height: 42px; } }
  .soothe-plus .summary-props {
    padding: 0 30px 20px 30px !important; }
  .soothe-plus .value-props {
    margin: 0 auto;
    padding: 0px;
    max-width: 540px;
    background: white;
    border-radius: 5px;
    box-shadow: 0px 0px 5px 3px rgba(214, 215, 217, 0.32);
    text-align: center; }
    .soothe-plus .value-props h1 {
      padding-top: 28px;
      font-family: "Poppins-Regular", sans-serif;
      font-size: 16px;
      line-height: 17px;
      color: #2C3239; }
    .soothe-plus .value-props .prop-checkbox {
      max-width: 240px;
      width: 100%;
      font-family: "Poppins-Light", sans-serif;
      font-size: 16px;
      display: inline-block;
      background-repeat: no-repeat;
      background-position: 0;
      background-size: 20px 20px;
      padding: 10px 0 10px 35px;
      text-align: left;
      vertical-align: top; }
  .soothe-plus .detail-wrapper {
    display: flex;
    margin: 5px 0px;
    color: #2C3239;
    font-family: "Poppins-Regular", sans-serif;
    font-size: 16px;
    margin: 50px auto; }
    .soothe-plus .detail-wrapper .icon-wrapper {
      width: 30px;
      float: left;
      margin-top: 4px; }
      .soothe-plus .detail-wrapper .icon-wrapper .icon {
        background-size: 100% !important;
        background-repeat: no-repeat !important;
        height: 10px;
        width: 15px; }
    .soothe-plus .detail-wrapper .edit-card {
      width: 20px;
      height: 20px; }
    .soothe-plus .detail-wrapper .add-card-center {
      margin: 0 auto;
      text-decoration: none; }
    .soothe-plus .detail-wrapper .add-card {
      width: 32px;
      height: 32px; }
    .soothe-plus .detail-wrapper .detail {
      flex: 1;
      margin: 0px;
      font-family: "Poppins-Regular", sans-serif; }
    .soothe-plus .detail-wrapper a {
      color: #2C3239;
      font-family: "Poppins-Regular", sans-serif; }
    .soothe-plus .detail-wrapper a:hover {
      cursor: pointer !important; }
  .soothe-plus .detail-wrapper {
    box-shadow: 0px 0px 5px 3px rgba(214, 215, 217, 0.32);
    border-radius: 5px;
    padding: 30px;
    max-width: 470px;
    height: 85px; }
  .soothe-plus .payment-title {
    text-align: center;
    font-family: "Poppins-SemiBold", sans-serif;
    font-size: 32px;
    color: #5F6368;
    padding-top: 50px; }
  .soothe-plus .annual-subscription-disclaimer {
    color: #5D6369; }
    .soothe-plus .annual-subscription-disclaimer .check-box {
      width: 5%;
      display: inline-block;
      vertical-align: top; }
    .soothe-plus .annual-subscription-disclaimer .check-box-copy {
      width: 95%;
      display: inline-block;
      text-align: left;
      padding-left: 5px; }
    .soothe-plus .annual-subscription-disclaimer .annual-disclaimer {
      font-family: "Poppins-Light", sans-serif;
      font-size: 15px;
      line-height: 23px;
      z-index: -1;
      color: #2C3239; }
      .soothe-plus .annual-subscription-disclaimer .annual-disclaimer .subscription-terms {
        color: #7089BE; }
    .soothe-plus .annual-subscription-disclaimer .annual-disclaimer-italic {
      margin: 20px 0;
      padding: 0;
      font-size: 12px;
      line-height: 17px;
      color: #5D6369; }
  .soothe-plus .back-btn, .soothe-plus .back-btn:hover {
    color: #F58266;
    box-shadow: none; }

.confirmation {
  text-align: center;
  min-height: 310px;
  font-family: "Poppins-Regular", sans-serif; }
  .confirmation .confirmation-copy p {
    margin: 20px 0; }

.cancellation-btn {
  margin-top: 20px; }
