.modal-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 999;
  overflow-y: scroll;
  padding-top: 64px; }

.modal-content {
  margin: auto;
  min-width: 35%;
  background: #FFFFFF;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);
  border-radius: 16px;
  padding: 24px;
  overflow-y: scroll;
  max-height: calc(90vh - 64px); }

.modal-title {
  font-size: 28px;
  line-height: 36px;
  color: #000000;
  font-family: "Poppins-Medium", sans-serif;
  margin-bottom: 16px; }

.modal-apply-button, .modal-apply-button:hover, .modal-apply-button:active, .modal-apply-button:focus {
  padding: 16px 20px;
  font-size: 18px;
  line-height: 24px;
  color: #FFFFFF;
  background: #F58266;
  border-radius: 30px;
  font-family: "Poppins-Medium", sans-serif; }

.modal-cancel-button {
  margin-right: 16px;
  padding: 16px 20px;
  border-radius: 30px;
  font-size: 18px;
  line-height: 24px;
  color: #39455E;
  font-family: "Poppins-Medium", sans-serif; }

.txt-align-right {
  text-align: right; }

.margin-top-40 {
  margin-top: 40px; }
