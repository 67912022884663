.icon-style {
  max-width: 18px; }

.br-btm {
  border-bottom: 1px solid #E2E2E2; }

.title-style {
  font-size: 16px;
  line-height: 20px;
  color: #000000; }

.details-style {
  font-size: 14px;
  line-height: 20px;
  color: #545454;
  font-family: "Poppins-Regular", sans-serif;
  max-width: 75%;
  margin-top: 4px; }

.p-16 {
  padding: 16px; }

.ptlb-16 {
  padding: 16px 16px 16px 0px; }

.txt-center {
  text-align: center; }

.text-with-icon:last-child {
  margin-bottom: 12px; }

.parent-flex {
  display: flex; }

.flex-1 {
  flex: 1; }

.arrow-icon-style {
  width: 8px;
  align-self: center; }
