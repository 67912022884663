.creditCardsModal.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 99999; }
  .creditCardsModal.overlay .form-control {
    box-shadow: 0 !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important; }
  @media screen and (max-width: 700px) {
    .creditCardsModal.overlay .popup {
      width: 95% !important; } }
  .creditCardsModal.overlay .popup h2 {
    margin: 5px 5px 15px 5px;
    color: #333;
    text-align: center;
    font-size: 17px;
    font-family: "Poppins-SemiBold", sans-serif; }
  .creditCardsModal.overlay .popup .content {
    max-height: 30%;
    overflow: auto; }
    .creditCardsModal.overlay .popup .content .creditCards {
      overflow: auto;
      padding-top: 10px; }
      .creditCardsModal.overlay .popup .content .creditCards .credit-card-wrapper {
        font-family: "Poppins-Regular", sans-serif;
        border-radius: 5px;
        margin: 10px 5px;
        padding: 4px;
        display: flex;
        margin-bottom: 10px !important; }
        .creditCardsModal.overlay .popup .content .creditCards .credit-card-wrapper input[type=radio] {
          margin: auto 5px; }
        .creditCardsModal.overlay .popup .content .creditCards .credit-card-wrapper .delete-btn-container {
          display: inline-block;
          margin-bottom: -25px !important;
          vertical-align: middle; }
          .creditCardsModal.overlay .popup .content .creditCards .credit-card-wrapper .delete-btn-container .delete-icon {
            width: 20px;
            height: 20px;
            margin: 12px 10px 0 0; }
        .creditCardsModal.overlay .popup .content .creditCards .credit-card-wrapper .credit-card-content {
          height: 60px;
          flex: 1;
          margin-left: 10px; }
          @media screen and (max-width: 766px) {
            .creditCardsModal.overlay .popup .content .creditCards .credit-card-wrapper .credit-card-content {
              height: 80px; } }
          .creditCardsModal.overlay .popup .content .creditCards .credit-card-wrapper .credit-card-content .cc-icon {
            height: 35px;
            width: 60px;
            display: inline-block;
            margin-top: 12px;
            margin-right: 16px; }
          .creditCardsModal.overlay .popup .content .creditCards .credit-card-wrapper .credit-card-content .cc-details {
            display: inline-block;
            margin-bottom: 0px !important;
            vertical-align: middle; }
  .creditCardsModal.overlay .popup .buttons-wrapper {
    margin: 0px 0px 20px 0px;
    max-width: 470px; }
    .creditCardsModal.overlay .popup .buttons-wrapper .btn {
      background-color: #FFFFFF !important;
      font-family: "Poppins-SemiBold", sans-serif !important;
      font-size: 18px !important;
      line-height: 22px !important;
      color: #F58266 !important; }
    .creditCardsModal.overlay .popup .buttons-wrapper .btn:focus {
      border: none; }
    .creditCardsModal.overlay .popup .buttons-wrapper .new-credit-card {
      font-family: "Poppins-Medium", sans-serif !important;
      font-size: 16px;
      color: #7089BE !important;
      border-radius: 3px;
      padding: 30px 0px;
      line-height: 26px;
      width: 100%;
      margin: 0 auto;
      text-align: center;
      border: 0;
      height: 85px;
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='rgba(95, 99, 104, 0.3)' stroke-width='2' stroke-dasharray='6%2c 9' stroke-dashoffset='14' stroke-linecap='round'/%3e%3c/svg%3e");
      border-radius: 5px;
      text-decoration: none; }
      .creditCardsModal.overlay .popup .buttons-wrapper .new-credit-card .add-card-icon {
        width: 20px;
        height: 20px; }
    .creditCardsModal.overlay .popup .buttons-wrapper .btn:active {
      -webkit-box-shadow: none; }
