.chat-text-input {
  padding: 10px 45px 10px 12px;
  width: 100%;
  height: 40px;
  background: #FFFFFF;
  border: 1px solid #757575;
  color: #545454;
  position: absolute;
  bottom: 0px;
  border-radius: 100px; }

.chat-text-input::placeholder {
  color: #757575; }

.chat-input-send-btn {
  visibility: hidden;
  position: absolute;
  bottom: 5px;
  right: 5px;
  height: 30px;
  width: 30px;
  border-radius: 50%;
  background: #F58266;
  padding: 0px; }

.chat-input-send-icon.MuiSvgIcon-root {
  height: 100%;
  width: 65%; }

.relative-input-wrapper {
  position: relative; }

.relative-input-wrapper.show .chat-input-send-btn {
  visibility: visible; }

.report-dropdown .MuiPopover-paper {
  border-radius: 4px !important;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12); }

.report-dropdown .MuiMenuItem-root:not(:last-child) {
  padding-bottom: 0px !important;
  border-bottom: none !important; }

.report-dropdown .MuiList-root .MuiSvgIcon-root {
  font-size: 20px; }

.abs-r-4 {
  position: absolute;
  right: 4px; }

.chat-screen-dimensions {
  max-width: 78%;
  margin: 26px auto 20px; }

.cstm-details-style .details-style {
  max-width: 90%; }

@media (max-width: 800px) {
  .chat-text-input {
    position: fixed;
    left: 0;
    right: 0;
    width: 100vw;
    margin: 0; }
  .chat-input-send-btn {
    position: fixed;
    right: 20px; } }
