#soothe-navbar, #soothe-navbar-home {
  width: 100%;
  background: white;
  height: 64px;
  display: flex;
  justify-content: space-between;
  z-index: 999999999999; }
  #soothe-navbar .locked-body, #soothe-navbar-home .locked-body {
    overflow: hidden; }
  #soothe-navbar .hamburger-icon, #soothe-navbar-home .hamburger-icon {
    display: none;
    background-image: url(https://d1j2j3pphg9ab.cloudfront.net/wp-content/uploads/2020/04/27033927/hamburger.svg);
    background-size: 14px 14px;
    width: 14px;
    height: 14px; }
  #soothe-navbar .logo-wrapper, #soothe-navbar-home .logo-wrapper {
    display: flex;
    margin-left: 80px;
    padding-top: 15px;
    width: 200px; }
    @media (max-width: 500px) {
      #soothe-navbar .logo-wrapper, #soothe-navbar-home .logo-wrapper {
        margin-left: 30px; } }
    #soothe-navbar .logo-wrapper .soothe-logo-icon, #soothe-navbar-home .logo-wrapper .soothe-logo-icon {
      background-image: url("./../Images/soothe-logo-vector.svg");
      background-repeat: no-repeat;
      background-size: 112px 30px;
      height: 30px;
      width: 112px;
      display: block;
      text-decoration: none; }
  #soothe-navbar .logo-wrapper:hover, #soothe-navbar-home .logo-wrapper:hover {
    text-decoration: none; }
  #soothe-navbar .booking-back-arrow, #soothe-navbar-home .booking-back-arrow {
    position: absolute;
    padding: 20px 0 0 60px;
    font-family: "Poppins-Medium", sans-serif;
    font-size: 16px;
    color: #2C3239;
    text-decoration: none; }
    @media (max-width: 1024px) {
      #soothe-navbar .booking-back-arrow, #soothe-navbar-home .booking-back-arrow {
        padding: 20px 0 0 18px; } }
  #soothe-navbar .logo-wrapper-booking, #soothe-navbar-home .logo-wrapper-booking {
    margin: 0 auto;
    padding-top: 15px;
    width: 200px; }
    #soothe-navbar .logo-wrapper-booking .soothe-logo-icon, #soothe-navbar-home .logo-wrapper-booking .soothe-logo-icon {
      background-image: url("./../Images/soothe-logo-vector.svg");
      background-repeat: no-repeat;
      background-size: 112px 30px;
      height: 30px;
      width: 112px;
      display: block;
      text-decoration: none;
      margin: 0 auto; }
  #soothe-navbar .logo-wrapper-booking:hover, #soothe-navbar-home .logo-wrapper-booking:hover {
    text-decoration: none; }
  #soothe-navbar .header-items, #soothe-navbar-home .header-items {
    display: flex;
    justify-content: space-between;
    width: 40%;
    margin-right: 80px;
    padding-top: 20px; }
    #soothe-navbar .header-items a:hover, #soothe-navbar .header-items a:focus, #soothe-navbar .header-items .header-item:hover, #soothe-navbar-home .header-items a:hover, #soothe-navbar-home .header-items a:focus, #soothe-navbar-home .header-items .header-item:hover {
      color: #7089BE !important; }
    #soothe-navbar .header-items .header-item, #soothe-navbar-home .header-items .header-item {
      font-family: "Poppins-Regular", sans-serif;
      font-size: 15px;
      color: #2C3239;
      letter-spacing: 0;
      line-height: 22px;
      text-decoration: none; }
    #soothe-navbar .header-items .header-item-active, #soothe-navbar-home .header-items .header-item-active {
      color: #7089BE;
      font-family: "Poppins-Medium", sans-serif; }
    #soothe-navbar .header-items .book-cta-container .book-cta, #soothe-navbar-home .header-items .book-cta-container .book-cta {
      background-color: #F58266;
      border: solid 3px #F58266;
      border-radius: 50px;
      padding: 4px 35px;
      font-size: 16px;
      line-height: 22px;
      color: #FFF;
      font-family: "Poppins-SemiBold", sans-serif;
      letter-spacing: 1px;
      -webkit-transition: all 0.3s ease 0s;
      transition: all 0.3s ease 0s; }
    #soothe-navbar .header-items .book-cta-container .book-cta:hover, #soothe-navbar-home .header-items .book-cta-container .book-cta:hover {
      color: #FFFFFF !important; }
  #soothe-navbar .header-item-account-icon, #soothe-navbar-home .header-item-account-icon {
    background-image: url(https://d1j2j3pphg9ab.cloudfront.net/wp-content/uploads/2020/04/26063251/account-icon.svg);
    background-size: 20px 20px;
    height: 20px;
    width: 20px; }
  #soothe-navbar .header-item-account-icon-mobile, #soothe-navbar-home .header-item-account-icon-mobile {
    background-image: url(https://d1j2j3pphg9ab.cloudfront.net/wp-content/uploads/2020/04/26063251/account-icon.svg);
    background-size: 18px 18px;
    height: 18px;
    width: 18px;
    display: none; }
  @media only screen and (max-width: 1024px) {
    #soothe-navbar #soothe-navbar, #soothe-navbar-home #soothe-navbar {
      position: relative; }
    #soothe-navbar .hamburger-icon, #soothe-navbar-home .hamburger-icon {
      display: block;
      position: absolute;
      margin-top: 22px;
      left: 15px;
      cursor: pointer; }
    #soothe-navbar .logo-wrapper, #soothe-navbar-home .logo-wrapper {
      display: block;
      margin-left: auto;
      margin-right: auto; }
      #soothe-navbar .logo-wrapper .soothe-logo-icon, #soothe-navbar-home .logo-wrapper .soothe-logo-icon {
        background-image: url(https://d1j2j3pphg9ab.cloudfront.net/wp-content/uploads/2020/05/25162812/soothe-logo-hands-blue.png);
        background-size: 30px 30px;
        height: 30px;
        width: 30px;
        display: block;
        text-decoration: none; }
    #soothe-navbar .header-items, #soothe-navbar-home .header-items {
      display: none;
      position: fixed;
      height: 100%;
      width: 100%;
      background: rgba(57, 69, 94, 0.99999);
      z-index: 999;
      flex-direction: column;
      padding-bottom: 50px; }
      #soothe-navbar .header-items .header-item, #soothe-navbar-home .header-items .header-item {
        margin: auto;
        color: white;
        font-size: 22px; }
      #soothe-navbar .header-items .close-wrapper, #soothe-navbar-home .header-items .close-wrapper {
        display: none;
        margin: auto; }
        #soothe-navbar .header-items .close-wrapper .close-icon, #soothe-navbar-home .header-items .close-wrapper .close-icon {
          background-image: url(https://d1j2j3pphg9ab.cloudfront.net/wp-content/uploads/2020/06/04045253/exit-overlay.png);
          background-size: 24px 24px;
          height: 24px;
          width: 24px;
          display: block;
          cursor: pointer; }
    #soothe-navbar .header-item-account-icon-mobile, #soothe-navbar-home .header-item-account-icon-mobile {
      display: block;
      position: absolute;
      margin-top: 22px;
      right: 15px;
      cursor: pointer; }
    #soothe-navbar .header-item-account-icon, #soothe-navbar-home .header-item-account-icon {
      display: none; }
    #soothe-navbar .soothe-logo-icon, #soothe-navbar-home .soothe-logo-icon {
      margin: auto; }
    #soothe-navbar .soothe-logo-text, #soothe-navbar-home .soothe-logo-text {
      display: none; } }

#soothe-navbar-home-mobile {
  position: fixed;
  width: 100%;
  z-index: 999999999999; }
  #soothe-navbar-home-mobile .hamburger-icon {
    background-image: url(https://d1j2j3pphg9ab.cloudfront.net/wp-content/uploads/2020/04/27033927/hamburger.svg);
    background-size: 14px 14px;
    width: 14px;
    height: 14px;
    position: absolute;
    right: 15px;
    cursor: pointer;
    top: 5px; }
  #soothe-navbar-home-mobile .logo-wrapper {
    position: absolute;
    left: 15px; }
    #soothe-navbar-home-mobile .logo-wrapper .soothe-logo-icon {
      background-image: url(https://d1j2j3pphg9ab.cloudfront.net/wp-content/uploads/2020/05/25162812/soothe-logo-hands-blue.png);
      background-size: 30px 30px;
      height: 30px;
      width: 30px;
      display: block;
      text-decoration: none; }
  #soothe-navbar-home-mobile .book-cta-container {
    text-align: right;
    margin-right: 47px; }
    #soothe-navbar-home-mobile .book-cta-container .book-cta {
      background-color: #F58266;
      border: solid 3px #F58266;
      border-radius: 50px;
      padding: 3px 15px;
      font-size: 12px;
      line-height: 22px;
      color: #FFF;
      font-family: "Poppins-SemiBold", sans-serif;
      letter-spacing: 1px;
      -webkit-transition: all 0.3s ease 0s;
      transition: all 0.3s ease 0s; }
    #soothe-navbar-home-mobile .book-cta-container .book-cta:hover {
      color: #FFFFFF !important; }
  #soothe-navbar-home-mobile .header-items {
    display: none;
    position: fixed;
    height: 100%;
    width: 100%;
    background: rgba(57, 69, 94, 0.99999);
    z-index: 999;
    flex-direction: column;
    padding-bottom: 50px;
    top: 0; }
    #soothe-navbar-home-mobile .header-items .header-item {
      margin: 20px auto;
      color: #FFFFFF !important;
      font-size: 20px;
      font-family: "Poppins-Regular", sans-serif; }
    #soothe-navbar-home-mobile .header-items .header-item-active {
      color: #7089BE;
      font-family: "Poppins-Medium", sans-serif; }
    #soothe-navbar-home-mobile .header-items a:hover, #soothe-navbar-home-mobile .header-items a:focus, #soothe-navbar-home-mobile .header-items .header-item:hover {
      color: #7089BE !important;
      cursor: pointer; }
    #soothe-navbar-home-mobile .header-items .divider-one {
      max-width: 120px;
      margin: 5px auto;
      border-top: 1px solid #CFDEFF;
      opacity: 0.5; }
    #soothe-navbar-home-mobile .header-items .divider-two {
      max-width: 260px;
      margin: 40px auto;
      border-top: 1px solid #CFDEFF;
      opacity: 0.5; }
    #soothe-navbar-home-mobile .header-items .top-bar-container .logo-wrapper, #soothe-navbar-home-mobile .header-items .top-bar-container .book-cta-container {
      margin: 20px auto;
      display: inline-block; }
    #soothe-navbar-home-mobile .header-items .top-bar-container .book-cta-container {
      margin: 20px -150px 20px 10px; }
    #soothe-navbar-home-mobile .header-items .top-bar-container .logo-wrapper-mobile {
      z-index: 999999999999 !important; }
      #soothe-navbar-home-mobile .header-items .top-bar-container .logo-wrapper-mobile .soothe-logo-icon-mobile {
        background-image: url(./../Images/soothe_icon_mobile.png) !important;
        background-size: 30px 30px;
        height: 30px;
        width: 30px;
        display: block;
        text-decoration: none; }
    #soothe-navbar-home-mobile .header-items .top-bar-container .close-wrapper {
      display: none;
      margin: 20px auto; }
      #soothe-navbar-home-mobile .header-items .top-bar-container .close-wrapper .close-icon {
        background-image: url(https://d1j2j3pphg9ab.cloudfront.net/wp-content/uploads/2020/06/04045253/exit-overlay.png);
        background-size: 24px 24px;
        height: 24px;
        width: 24px;
        display: block;
        cursor: pointer;
        right: 15px;
        position: absolute; }
  #soothe-navbar-home-mobile .header-item-account-icon-mobile {
    background-image: url(https://d1j2j3pphg9ab.cloudfront.net/wp-content/uploads/2020/04/26063251/account-icon.svg);
    background-size: 18px 18px;
    height: 18px;
    width: 18px;
    display: block;
    margin-top: 22px;
    cursor: pointer;
    margin: 0 auto; }
  #soothe-navbar-home-mobile .header-item-account-icon {
    display: none; }
  #soothe-navbar-home-mobile .soothe-logo-icon {
    margin: auto; }
  #soothe-navbar-home-mobile .soothe-logo-text {
    display: none; }

.soothe-navbar-generic {
  box-shadow: 0px 0px 5px 3px rgba(214, 215, 217, 0.32); }

.p-fixed {
  position: fixed; }

.top-0 {
  top: 0; }

.fl-left {
  float: left; }

.m-0 {
  margin: 0 !important; }

.cart-label {
  padding: 10px 12px;
  background: #F1F6FF;
  border-radius: 36px;
  margin: 15px 4.4vw;
  float: right;
  font-family: "Poppins-Regular", sans-serif;
  align-items: center;
  display: flex; }

.cart-text {
  font-size: 14px;
  line-height: 16px;
  color: #586B94;
  margin: 0px 8px; }

.cart-badge {
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
  color: #FFFFFF;
  background: #586B94;
  border-radius: 50%;
  margin: 0px 8px;
  padding: 3px 8px; }

.max-w-100 {
  max-width: 100vw; }

.back-arrow {
  margin: auto 12px; }
