.cell-subtitle {
  color: #545454;
  text-align: center; }

.centered-cell:not(:last-child) {
  margin-right: 16px; }

.cell-title {
  font-family: "Poppins-Medium", sans-serif;
  font-size: 16px;
  line-height: 20px; }
