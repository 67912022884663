#custom-error-modal {
  position: fixed;
  z-index: 9999;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(39, 47, 67, 0.65);
  z-index: 99999999999999; }
  #custom-error-modal .modal-content {
    font-family: "Poppins-Light", sans-serif;
    font-size: 16px;
    line-height: 23px;
    color: #28323B;
    background-color: #fefefe;
    margin: auto;
    padding: 20px;
    border: 1px solid #888;
    width: 40%;
    text-align: center; }
    @media (max-width: 600px) {
      #custom-error-modal .modal-content {
        width: 90% !important; } }
    #custom-error-modal .modal-content h1 {
      text-align: center !important;
      font-family: "Poppins-SemiBold", sans-serif;
      font-size: 24px;
      line-height: 30px;
      color: #2B323A; }
    #custom-error-modal .modal-content .content {
      margin: 5px auto;
      padding: 0;
      letter-spacing: .5px; }
    #custom-error-modal .modal-content .btn-container {
      text-align: center !important; }
      #custom-error-modal .modal-content .btn-container .btn {
        font-family: "Poppins-SemiBold", sans-serif;
        font-size: 18px;
        line-height: 22px;
        background: #F58266;
        border-color: #F58266;
        border-radius: 50px;
        color: #FFFFFF;
        margin-top: 20px !important;
        padding: 12px;
        width: 200px;
        height: 44px; }
  #custom-error-modal .close {
    color: #2C3239;
    font-size: 28px;
    font-weight: bold;
    opacity: 1; }
  #custom-error-modal .close:hover,
  #custom-error-modal .close:focus {
    text-decoration: none;
    cursor: pointer; }
