.creditCardModal.overlay .popup {
  margin: 70px auto;
  padding: 20px;
  background: #FFF;
  width: 40%;
  position: relative;
  transition: all 5s ease-in-out;
  border-radius: 3px;
  max-height: calc(100vh - 30px);
  overflow-y: auto; }

.creditCardsModal.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7);
  z-index: 99999; }
  .creditCardsModal.overlay .popup {
    margin: 70px auto;
    padding: 20px;
    background: #FFFFFF;
    width: 40%;
    position: relative;
    transition: all 5s ease-in-out; }
    @media screen and (max-width: 700px) {
      .creditCardsModal.overlay .popup {
        width: 90% !important; } }
    .creditCardsModal.overlay .popup h2 {
      margin: 5px 5px 15px 5px;
      color: #333;
      text-align: center;
      line-height: 28px !important;
      font-size: 24px !important;
      font-family: "Poppins-Medium", sans-serif !important;
      color: #29323B !important; }
    .creditCardsModal.overlay .popup .content {
      max-height: 30%;
      overflow: auto; }
      .creditCardsModal.overlay .popup .content .creditCards {
        overflow: auto; }
        .creditCardsModal.overlay .popup .content .creditCards .selected {
          border: 0.5px solid #F58266; }
        .creditCardsModal.overlay .popup .content .creditCards .credit-card-wrapper {
          box-shadow: 0px 0px 5px 3px rgba(214, 215, 217, 0.32);
          margin: 10px 5px;
          padding: 15px 20px !important;
          display: flex; }
          .creditCardsModal.overlay .popup .content .creditCards .credit-card-wrapper .radio-item {
            display: none !important; }
          .creditCardsModal.overlay .popup .content .creditCards .credit-card-wrapper .label-item {
            margin-top: 0px; }
            .creditCardsModal.overlay .popup .content .creditCards .credit-card-wrapper .label-item img {
              width: 15px;
              height: 10px;
              cursor: pointer; }
          .creditCardsModal.overlay .popup .content .creditCards .credit-card-wrapper .radio-item:checked + label {
            opacity: 1;
            width: 10px;
            height: 15px; }
          .creditCardsModal.overlay .popup .content .creditCards .credit-card-wrapper input[type='radio']:checked {
            position: absolute;
            visibility: hidden; }
          .creditCardsModal.overlay .popup .content .creditCards .credit-card-wrapper input[type=radio] {
            margin: auto 5px; }
          .creditCardsModal.overlay .popup .content .creditCards .credit-card-wrapper .credit-card-content {
            height: 60px;
            flex: 1;
            margin-left: 10px;
            display: inline-block; }
            @media screen and (max-width: 766px) {
              .creditCardsModal.overlay .popup .content .creditCards .credit-card-wrapper .credit-card-content {
                height: 80px; } }
            .creditCardsModal.overlay .popup .content .creditCards .credit-card-wrapper .credit-card-content .cc-icon {
              height: 35px;
              width: 60px;
              display: inline-block;
              margin-top: 12px;
              margin-right: 16px; }
            .creditCardsModal.overlay .popup .content .creditCards .credit-card-wrapper .credit-card-content .cc-details-type {
              font-family: "Poppins-Regular", sans-serif;
              font-size: 16px;
              color: #2C3239;
              padding: 10px 0 0 20px; }
            .creditCardsModal.overlay .popup .content .creditCards .credit-card-wrapper .credit-card-content .cc-details {
              display: inline-block;
              margin-bottom: 20px;
              vertical-align: middle;
              font-family: "Poppins-Light", sans-serif;
              font-size: 16px;
              color: #5F6368; }
          .creditCardsModal.overlay .popup .content .creditCards .credit-card-wrapper .btn {
            background: #FFFFFF; }
          .creditCardsModal.overlay .popup .content .creditCards .credit-card-wrapper .delete-card {
            width: 20px;
            height: 20px; }
    .creditCardsModal.overlay .popup .buttons-wrapper {
      margin: 0px 0px 20px 0px; }
      .creditCardsModal.overlay .popup .buttons-wrapper .new-credit-card {
        border-radius: 5px !important;
        color: #2C3239 !important;
        background-color: #FFFFFF !important;
        font-family: "Poppins-Regular", sans-serif !important; }
        .creditCardsModal.overlay .popup .buttons-wrapper .new-credit-card .add-card {
          width: 32px;
          height: 32px; }
      .creditCardsModal.overlay .popup .buttons-wrapper .cancel {
        font-family: "Poppins-SemiBold", sans-serif;
        font-size: 18px;
        line-height: 22px;
        color: #F58266; }
      .creditCardsModal.overlay .popup .buttons-wrapper .btn {
        margin-top: 20px; }
      .creditCardsModal.overlay .popup .buttons-wrapper .btn:active {
        -webkit-box-shadow: none; }
