.max-width-375 {
  max-width: 375px;
  margin: auto; }

.max-width-315 {
  max-width: 315px;
  margin: auto; }

.max-w-65 {
  max-width: 65%;
  margin: auto; }

.rdtPicker, .rdtPicker th {
  border: none !important;
  background-color: transparent !important; }

.rdtPicker table {
  border-collapse: separate;
  border-spacing: 18px 10px; }

.rdtPicker thead tr:first-child th {
  color: #000;
  font-size: 18px;
  line-height: 24px;
  font-weight: normal; }

.rdtPicker th.rdtNext, .rdtPicker th.rdtPrev {
  font-size: 32px; }

.rdtPicker .dow {
  color: #757575;
  font-size: 16px;
  line-height: 20px; }

.rdtPicker td.rdtOld {
  visibility: hidden; }

.rdtPicker td.rdtNew {
  display: none; }

.rdtPicker td {
  color: #757575;
  font-size: 16px;
  line-height: 24px; }

.rdtPicker td.rdtActive, .rdtPicker td.rdtActive:hover {
  background-color: #586B94 !important;
  padding: 0px !important; }

.rdtPicker td.rdtActive ~ * {
  color: #000; }

.rdtPicker td.rdtToday:before {
  display: none !important; }

.rdtPicker td.rdtToday, .rdtPicker td.rdtToday ~ .rdtDay {
  color: #000; }

.p-16 {
  padding: 16px; }

.p-24 {
  padding: 24px; }

.mb-24 {
  margin-bottom: 24px; }

.mb-50 {
  margin-bottom: 50px; }

.table-responsive {
  border: none !important; }

.br-btm-black-1 {
  border-bottom: 1px solid #000; }

.txt-center {
  text-align: center; }

.MuiSlider-track {
  color: #F58266; }

.MuiSlider-thumb {
  color: #fff;
  box-shadow: 0px 3px 8px rgba(0, 0, 0, 0.15), 0px 1px 1px rgba(0, 0, 0, 0.16), 0px 3px 1px rgba(0, 0, 0, 0.1); }

.MuiSlider-rail {
  color: #e2e2e2; }

.rdtStatic .rdtPicker {
  width: 100%; }

.gray-select-style > select {
  background-color: #eee;
  height: 48px;
  border-radius: 4px;
  border: none;
  padding: 12px;
  font-size: 16px;
  line-height: 24px;
  color: #757575; }

.gray-select-style > select:after {
  right: 20px; }

.select-options-dropdown {
  background: #FFFFFF;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);
  border-radius: 4px; }

.extraHeightAnchorCopy {
  visibility: hidden;
  height: 270px;
  margin-top: -270px;
  display: block; }

.force-size .cell-title {
  font-size: 14px;
  line-height: 16px;
  margin-bottom: 6px; }

@media (max-width: 800px) {
  .max-w-65 {
    max-width: 100%; }
  .rdtPicker td.rdtActive, .rdtPicker td.rdtActive:hover {
    padding: 4px 6px !important; } }

@media (max-width: 500px) {
  .rdtPicker td.rdtActive, .rdtPicker td.rdtActive:hover {
    padding: 0px 6px !important; } }
