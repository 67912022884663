.ctaButton-wrapper {
  position: relative;
  text-align: center;
  width: 100%;
  border-top: 1px solid #E2E2E2;
  padding: 16px;
  display: flex;
  justify-content: right;
  align-items: center; }

.ctaButton-wrapper:not(.fxd-btm-right) {
  left: 0; }
.ctaButton-enabled, .ctaButton-enabled:hover, .ctaButton-enabled:active, .ctaButton-enabled:focus {
  padding: 16px 20px;
  background: #F58266;
  border-radius: 30px;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #FFFFFF; }

.ctaButton-width {
  width: 50%; }

.ctaButton-disabled, .ctaButton-disabled:hover, .ctaButton-disabled:active, .ctaButton-disabled:focus {
  padding: 16px 20px;
  background: #F6F6F6;
  border-radius: 30px;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #757575; }

.grayStyled, .grayStyled:hover, .grayStyled:active, .grayStyled:focus {
  background: #F6F6F6;
  border-radius: 30px;
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #757575;
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05); }

.float-right {
  float: right; }

.float-left {
  float: left; }

.has-spinner .spinner {
  display: inline-block;
  opacity: 0;
  width: 0;
  -webkit-transition: opacity 0.25s, width 0.25s;
  -moz-transition: opacity 0.25s, width 0.25s;
  -o-transition: opacity 0.25s, width 0.25s;
  transition: opacity 0.25s, width 0.25s; }

.spinner i {
  -moz-animation: spin 1s infinite linear;
  -webkit-animation: spin 1s infinite linear; }

.has-spinner.active {
  cursor: progress; }
  .has-spinner.active .spinner {
    opacity: 1;
    width: auto;
    /* This doesn't work, just fix for unkown width elements */
    margin-right: 10px; }

.position-relative {
  position: relative; }

.txt-right {
  text-align: right; }

.mr-32 {
  margin-right: 32px; }

.mb-4 {
  margin-bottom: 4px; }

.sm-display {
  display: none; }

@media (max-width: 800px) {
  .ctaButton-wrapper {
    position: fixed;
    display: block;
    bottom: -1px;
    background-color: #FFFFFF; }
    .ctaButton-wrapper .txt-right {
      display: none; }
    .ctaButton-wrapper .ctaButton-disabled, .ctaButton-wrapper .ctaButton-enabled {
      width: 100%; }
  .sm-none {
    display: none; }
  .sm-display {
    display: block; } }
