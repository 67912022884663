.medium-font {
  font-family: "Poppins-Medium", sans-serif; }

.regular-font {
  font-family: "Poppins-Regular", sans-serif; }

.txt-center {
  text-align: center; }

.txt-end {
  text-align: end; }

.size-40-52 {
  font-size: 40px;
  line-height: 52px; }

.size-28-36 {
  font-size: 28px;
  line-height: 36px; }

.size-24-32 {
  font-size: 24px;
  line-height: 32px; }

.size-20-28 {
  font-size: 20px;
  line-height: 28px; }

.size-18-24 {
  font-size: 18px;
  line-height: 24px; }

.size-16-24 {
  font-size: 16px;
  line-height: 24px; }

.size-16-20 {
  font-size: 16px;
  line-height: 20px; }

.size-14-16 {
  font-size: 14px;
  line-height: 16px; }

.size-14-20 {
  font-size: 14px;
  line-height: 20px; }

.size-12-20 {
  font-size: 12px;
  line-height: 20px; }

.color-white,
.color-white:hover,
.color-white:focus {
  color: #FFF; }

.color-black {
  color: #000; }

.color-accent,
.color-accent:hover,
.color-accent:focus {
  color: #586B94; }

.mb-150 {
  margin-bottom: 150px; }

.mb-60 {
  margin-bottom: 60px; }

.mb-36 {
  margin-bottom: 36px; }

.mb-32 {
  margin-bottom: 32px; }

.mb-20 {
  margin-bottom: 20px; }

.mb-16 {
  margin-bottom: 16px; }

.mb-14 {
  margin-bottom: 14px; }

.mb-12 {
  margin-bottom: 12px; }

.mb-10 {
  margin-bottom: 10px; }

.mb-8 {
  margin-bottom: 8px; }

.mb-4 {
  margin-bottom: 4px; }

.mt-60 {
  margin-top: 60px; }

.mt-24 {
  margin-top: 24px; }

.mt-8 {
  margin-top: 8px; }

.mt-36 {
  margin-top: 36px; }

.mt-32 {
  margin-top: 32px; }

.mt-1205vh {
  margin-top: 12.5vh; }

.mr-15 {
  margin-right: 15px; }

.mrl-5 {
  margin: 0px 5px; }

.p-40 {
  padding: 40px; }

.p-24-16 {
  padding: 24px 16px 20px; }

.p-16-24 {
  padding: 16px 24px; }

.p-16-20 {
  padding: 16px 20px; }

.p-24-16-0 {
  padding: 24px 16px 0px; }

.p-16 {
  padding: 16px; }

.p-14 {
  padding: 14px; }

.p-0-8 {
  padding: 0px 8px; }

.pr-0 {
  padding-right: 0px; }

.pl-0 {
  padding-left: 0px; }

.br-gray {
  border: 1px solid #E2E2E2; }

.br-right-gray-1 {
  border-right: 1px solid #E2E2E2; }

.br-btm-gray-1 {
  border-bottom: 1px solid #E2E2E2; }

.br-btm-gray-4 {
  border-bottom: 4px solid rgba(0, 0, 0, 0.08); }

.full-w-h-img {
  width: 100%;
  height: 100%;
  object-fit: cover; }

.br-btm-gray-1 .color-light-gray {
  color: #757575 !important; }

.btn-sherbert,
.btn-sherbert:hover,
.btn-sherbert:focus {
  background-color: #FFC4B5;
  color: #A14538;
  font-size: 14px;
  line-height: 16px;
  padding: 10px 12px; }

.clickable {
  cursor: pointer; }

.cursor-pointer {
  cursor: pointer; }

.bg-light-blue {
  background-color: #F1F6FF; }

.bg-blue {
  background-color: #7089BE; }

.bg-secondary {
  background-color: #F6F6F6; }

.bg-white {
  background-color: #FFFFFF; }

.display-flex {
  display: flex; }

.flex-1 {
  flex: 1; }

.align-baseline {
  align-self: baseline; }

.align-items-center {
  align-items: center; }

.justify-content-spaced {
  justify-content: space-between;
  -webkit-justify-content: space-between; }

.br-radius-50 {
  border-radius: 50%; }

.br-radius-50 .mrl-5:first-child {
  margin: 0px 5px 0px 0px; }

.br-radius-50 .mrl-5:last-child {
  margin: 0px 0px 0px 5px; }

.w-220 {
  width: 220px; }

.w-h-57 {
  width: 57px;
  height: 57px;
  display: flex;
  justify-content: center;
  -webkit-justify-content: center;
  align-items: center; }

.max-w-h-29 {
  width: 100%;
  height: auto;
  max-width: 29px;
  max-height: 29px; }

.underline-style-links .title-style {
  text-decoration: underline;
  color: #39455E; }

.max-width-300 {
  max-width: 300px;
  margin: auto; }

.btn-action,
.btn-action:hover,
.btn-action:focus {
  background-color: #F58266;
  color: white;
  padding: 16px 20px;
  font-size: 18px;
  line-height: 24px; }

.mt-120 {
  margin-top: 120px !important; }

.mb-extra {
  margin-bottom: 60px; }

.padding-8-0 {
  padding: 8px 0px; }

.padding-10-12 {
  padding: 10px 12px; }

.border-radius-1000 {
  border-radius: 1000px; }

.border-radius-36 {
  border-radius: 36px; }

.border-radius-30 {
  border-radius: 30px; }

.border-radius-8 {
  border-radius: 8px; }

.mrl-0 {
  margin-right: 0px;
  margin-left: 0px; }

.full-w-100 {
  width: 100%; }

.bottom-0 {
  bottom: 0; }

.bottom-24 {
  bottom: 24px; }

.maxx-width-55 {
  width: 55vw;
  margin: auto; }

.maxx-width-60 {
  width: 60vw;
  margin: auto; }

.bg-peach {
  background-color: #FEEDE9; }

.color-deep-orange,
.color-deep-orange:hover,
.color-deep-orange:focus {
  color: #D9664F; }

.color-secondary-gray {
  color: #545454; }

.width-44 {
  width: 44px; }

.color-dark-blue {
  color: #39455E; }

.bg-light-blue {
  background: #F1F6FF; }

.d-lg-none {
  display: none; }

@media (max-width: 1200px) {
  .maxx-width-55,
  .maxx-width-60 {
    max-width: 100%;
    width: 100%;
    padding: 16px; }
  .sm-display-block {
    display: block; }
  .d-sm-none {
    display: none; }
  .d-lg-none {
    display: block !important; }
  .sm-mr-0 {
    margin-right: 0px; } }

@media (max-width: 800px) {
  .sm-w-100 {
    width: 100%; }
  .sm-mt-80 {
    margin-top: 80px !important; } }
