.medium-font {
  font-family: "Poppins-Medium", sans-serif; }

.regular-font {
  font-family: "Poppins-Regular", sans-serif; }

.font-style-italic {
  font-style: italic; }

.strike-through {
  text-decoration: line-through; }

.txt-center {
  text-align: center; }

.txt-end {
  text-align: end; }

.size-28-36 {
  font-size: 28px;
  line-height: 36px; }

.size-24-32 {
  font-size: 24px;
  line-height: 32px; }

.size-20-28 {
  font-size: 20px;
  line-height: 28px; }

.size-18-24 {
  font-size: 18px;
  line-height: 24px; }

.size-16-24 {
  font-size: 16px;
  line-height: 24px; }

.size-16-20 {
  font-size: 16px;
  line-height: 20px; }

.size-14-20 {
  font-size: 14px;
  line-height: 20px; }

.size-14-16 {
  font-size: 14px;
  line-height: px; }

.size-12-20 {
  font-size: 12px;
  line-height: 20px; }

.size-12-16 {
  font-size: 12px;
  line-height: 16px; }

.size-20-imp {
  font-size: 20px !important; }

.squeezed-in-with-lh {
  line-height: 10px; }

.color-white,
.color-white:hover,
.color-white:focus {
  color: #FFF; }

.color-black {
  color: #000; }

.color-accent,
.color-accent:hover,
.color-accent:focus {
  color: #586B94; }

.color-yellow {
  color: #BC8B2C; }

.bg-dark-yellow {
  background-color: #FFC043; }

.clr-gray {
  color: #545454; }

.clr-light-gray {
  color: #757575; }

.content-positive {
  color: #05944F; }

.apointment-details .color-gray {
  color: #545454 !important; }

.apointment-details .color-light-gray {
  color: #757575 !important; }

.apointment-details .color-blue-gray {
  color: #39455E !important; }

.grayed-out {
  color: #757575;
  font-size: 20px !important; }

.icon-style:has(.grayed-out) {
  margin: 0px -16px; }

.mb-150 {
  margin-bottom: 150px; }

.mb-60 {
  margin-bottom: 60px; }

.mb-36 {
  margin-bottom: 36px; }

.mb-20 {
  margin-bottom: 20px; }

.mb-16 {
  margin-bottom: 16px; }

.mb-12 {
  margin-bottom: 12px; }

.mb-10 {
  margin-bottom: 10px; }

.mb-8 {
  margin-bottom: 8px; }

.mb-4 {
  margin-bottom: 4px; }

.mt-60 {
  margin-top: 60px; }

.mt-24 {
  margin-top: 24px; }

.mt-36 {
  margin-top: 36px; }

.mt-1205vh {
  margin-top: 12.5vh; }

.mr-15 {
  margin-right: 15px; }

.mrl-5 {
  margin: 0px 5px; }

.p-40 {
  padding: 40px; }

.p-24-16 {
  padding: 24px 16px 20px; }

.p-16-24 {
  padding: 16px 24px; }

.p-16-20 {
  padding: 16px 20px; }

.p-24-16-0 {
  padding: 24px 16px 0px; }

.p-16 {
  padding: 16px; }

.p-14 {
  padding: 14px; }

.p-0-8 {
  padding: 0px 8px; }

.pr-0 {
  padding-right: 0px; }

.pl-0 {
  padding-left: 0px; }

.pt-24 {
  padding-top: 24px; }

.pt-0 {
  padding-top: 0px !important; }

.br-gray {
  border: 1px solid #E2E2E2; }

.br-gray-2 {
  border: 2px solid #E2E2E2; }

.br-right-gray-1 {
  border-right: 1px solid #E2E2E2; }

.br-btm-gray-1 {
  border-bottom: 1px solid #E2E2E2; }

.br-btm-gray-4 {
  border-bottom: 4px solid rgba(0, 0, 0, 0.08); }

.full-w-h-img {
  width: 100%;
  height: 100%;
  object-fit: cover; }

.details-header {
  padding: 24px 16px;
  font-size: 12px;
  line-height: 16px;
  color: #FFFFFF;
  background-color: #586B94; }

.br-btm-gray-1 .color-light-gray {
  color: #757575 !important; }

.details-header.redish {
  background-color: #A14538; }

.btn-sherbert,
.btn-sherbert:hover,
.btn-sherbert:focus {
  background-color: #FFC4B5;
  color: #A14538;
  font-size: 14px;
  line-height: 16px;
  padding: 10px 12px; }

.apointment-details .progress-step .bg-orange {
  background: #FFF !important; }

.apointment-details .progress-step .bg-light-orange {
  background: #FFF !important; }

.apointment-details .progress-step .bg-gray {
  background: #FFF !important;
  opacity: .5; }

.apointment-details .progress-step .color-gray {
  color: #FFF !important; }

.apointment-details .progress-step .color-light-gray {
  color: #FFF !important;
  opacity: .5; }

.clickable {
  cursor: pointer; }

.cursor-pointer {
  cursor: pointer; }

.bg-light-blue {
  background-color: #F1F6FF; }

.bg-blue {
  background-color: #7089BE; }

.bg-secondary {
  background-color: #F6F6F6; }

.bg-white {
  background-color: #FFFFFF; }

.bg-yellow {
  background-color: #FFFAF0; }

.display-flex {
  display: flex; }

.flex-1 {
  flex: 1; }

.align-baseline {
  align-self: baseline; }

.align-items-center {
  align-items: center; }

.justify-content-spaced {
  justify-content: space-between;
  -webkit-justify-content: space-between; }

.br-radius-9999 {
  border-radius: 9999px; }

.br-radius-50 {
  border-radius: 50%; }

.br-radius-50 .mrl-5:first-child {
  margin: 0px 5px 0px 0px; }

.br-radius-50 .mrl-5:last-child {
  margin: 0px 0px 0px 5px; }

.w-220 {
  width: 220px; }

.w-h-57 {
  width: 57px;
  height: 57px;
  display: flex;
  justify-content: center;
  -webkit-justify-content: center;
  align-items: center; }

.max-w-h-29 {
  width: 100%;
  height: auto;
  max-width: 29px;
  max-height: 29px; }

.underline-style-links .title-style {
  text-decoration: underline;
  color: #39455E; }

.apointment-details .clickable .icon-style {
  color: #000;
  max-width: 100%; }

.max-width-300 {
  max-width: 300px;
  margin: auto; }

.min-height-50 {
  min-height: 50vh; }

.apointment-details .rdtPicker {
  font-family: "Poppins-Regular", sans-serif !important;
  padding: 0px;
  width: 100%; }
  .apointment-details .rdtPicker tfoot {
    display: none !important; }

.apointment-details .rdtPicker td.rdtToday:before {
  display: none; }

.apointment-details .rdtPicker,
.apointment-details .rdtPicker th {
  border: none !important;
  background-color: transparent !important; }

.apointment-details .rdtPicker table {
  border-collapse: separate;
  border-spacing: 0px 5px; }

.apointment-details .rdtPicker thead tr:first-child th {
  color: #000;
  font-size: 18px;
  line-height: 24px;
  font-weight: normal; }

.apointment-details .rdtPicker th.rdtNext,
.apointment-details .rdtPicker th.rdtPrev {
  font-size: 32px; }

.apointment-details .rdtPicker .dow {
  color: #757575;
  font-size: 16px;
  line-height: 20px; }

.apointment-details .rdtPicker td.rdtOld {
  visibility: hidden; }

.apointment-details .rdtPicker td.rdtNew {
  display: none; }

.apointment-details .rdtPicker td {
  color: #000;
  font-size: 16px;
  line-height: 32px; }

.apointment-details .rdtPicker td.rdtActive,
.apointment-details .rdtPicker td.rdtActive:hover {
  background-color: #586B94 !important;
  padding: 4px !important; }

.apointment-details .rdtPicker td.rdtActive ~ * {
  color: #000; }

.apointment-details .rdtPicker td.rdtToday:before {
  display: none !important; }

.apointment-details .rdtPicker td.rdtToday,
.apointment-details .rdtPicker td.rdtToday ~ .rdtDay {
  color: #000; }

.apointment-details .gray-select-style > select {
  background-color: #eee;
  height: 48px;
  border-radius: 4px;
  border: none;
  padding: 12px;
  font-size: 16px;
  line-height: 24px;
  color: #545454; }

.apointment-details .gray-select-style > select.form-control:focus {
  border-color: #586B94;
  outline: 0;
  box-shadow: unset; }

.apointment-details .gray-select-style > select:after {
  right: 20px; }

.apointment-details .tip-custom-input-group {
  background: #EEEEEE;
  border-radius: 4px;
  margin: 8px 0px;
  color: #757575;
  font-size: 16px;
  line-height: 24px; }

.apointment-details .tip-custom-input {
  background: transparent;
  border: none;
  height: 48px; }

.apointment-details .tip-currency-addon {
  color: black;
  border: none;
  background-color: transparent; }

.apointment-details .custom-tip-label {
  font-size: 16px;
  line-height: 20px;
  margin: 0px;
  font-weight: normal; }

.apointment-details .negative-msg {
  font-size: 14px;
  line-height: 20px;
  color: #C11500; }

.apointment-details .provider-img {
  width: 48px;
  height: 48px;
  border-radius: 50%; }

.apointment-details .txt-ellipsis-3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical; }

.apointment-details .read-more-link,
.apointment-details .read-more-link:hover,
.apointment-details .read-more-link:active,
.apointment-details .read-more-link:focus {
  text-decoration: underline;
  color: #586B94;
  cursor: pointer; }

.apointment-details .gray-textarea {
  background: #EEEEEE;
  border-radius: 4px;
  width: 100%;
  color: black;
  border: 0px solid #F58266;
  font-size: 16px;
  line-height: 24px;
  padding: 16px 16px 17px; }

.apointment-details .gray-textarea:focus {
  border: 1px solid #F58266; }

.apointment-details .d-lg-none {
  display: none; }

.apointment-details .max-w-250 {
  max-width: 250px;
  margin: auto; }

.apointment-details .rounded-gray-btn {
  padding: 10px;
  background: #EEEEEE;
  border-radius: 36px;
  width: 36px;
  height: 36px; }

.apointment-details .p-fixed {
  position: fixed; }

.apointment-details .p-absolute {
  position: absolute; }

.apointment-details .flex-2 {
  flex: 2; }

.apointment-details .set-overflow {
  overflow-x: hidden;
  overflow-y: scroll; }

.apointment-details .bg-img {
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover; }

.apointment-details .full-w-h {
  height: calc(100vh - 80px);
  width: 100%; }

.apointment-details .max-h-full {
  max-height: calc(100vh - 80px); }

.apointment-details ::-webkit-scrollbar {
  width: 4px; }

.apointment-details ::-webkit-scrollbar-thumb {
  background-color: rgba(0, 0, 0, 0.08) !important;
  cursor: pointer; }

#time-scroller ::-webkit-scrollbar-thumb {
  background-color: transparent !important; }

.btn-action,
.btn-action:hover,
.btn-action:focus {
  background-color: #F58266;
  color: white;
  padding: 16px 20px;
  font-size: 18px;
  line-height: 24px; }

.mt-nav {
  margin-top: 80px; }

.mb-extra {
  margin-bottom: 60px; }

.details-header .dashed-tracker-wrapper {
  width: 100%; }

.padding-8-0 {
  padding: 8px 0px; }

.padding-10-12 {
  padding: 10px 12px; }

.padding-10-16 {
  padding: 10px 16px; }

.border-radius-1000 {
  border-radius: 1000px; }

.border-radius-30 {
  border-radius: 30px; }

.border-radius-8 {
  border-radius: 8px; }

.mrl-0 {
  margin-right: 0px;
  margin-left: 0px; }

.mrl-16 {
  margin-right: 16px;
  margin-left: 16px; }

.full-w-100 {
  width: 100%; }

.w-100-h-21 {
  width: 100% !important;
  height: 21px !important; }

.bottom-0 {
  bottom: 0; }

.bottom-24 {
  bottom: 24px; }

.add-a-new-address-input-style {
  height: 56px;
  background-color: #EEEEEE;
  backdrop-filter: blur(20px);
  border-radius: 1000px;
  border: none;
  align-self: stretch;
  margin-bottom: 32px;
  font-size: 18px;
  line-height: 28px;
  display: flex;
  align-items: center;
  color: #000;
  padding: 14px 14px 14px 50px;
  box-shadow: none !important; }

.add-a-new-address-input-style::placeholder {
  color: #757575; }

#atcb-bgoverlay {
  position: relative !important;
  width: 100% !important;
  height: 100% !important;
  background-color: transparent !important;
  backdrop-filter: blur(0px) !important;
  display: block !important;
  padding: 0px !important; }

.atcb-list-item.atcb-list-item-close {
  display: none !important; }

.atcb-list-item {
  background-color: transparent !important;
  border: 0px !important;
  font-size: 16px !important;
  line-height: 24px !important;
  color: #000000 !important; }

.atcb-list-item .atcb-icon {
  width: 30px !important; }
  .atcb-list-item .atcb-icon svg {
    width: 100% !important; }

.full-width-btn {
  width: 100%; }

.borderless-btn {
  border: none; }

.max-width-480 {
  min-width: 400px !important;
  width: 400px !important; }

.extraHeightAnchor {
  visibility: hidden;
  height: 250px;
  margin-top: -250px;
  display: block; }

@media (max-width: 1200px) {
  .sm-display-block {
    display: block; }
  .d-sm-none {
    display: none; }
  .d-lg-none {
    display: block !important; }
  .sm-mr-0 {
    margin-right: 0px; }
  .details-header {
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover; }
  .details-header.redish {
    background-color: #A14538;
    background-blend-mode: lighten; } }

@media (max-width: 800px) {
  .mt-nav {
    margin-top: 60px; } }
