.grayed-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  z-index: 999999999999; }

.hands-icon {
  width: 50px;
  margin: auto;
  align-self: center;
  justify-self: center; }

.circle-spinner {
  width: 120px;
  margin: auto;
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0; }
