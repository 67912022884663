.m-16 {
  margin: 16px; }

input[type="radio"] {
  border: 2px solid rgba(0, 0, 0, 0.54);
  margin: 16px; }

input[type="radio"]:checked {
  border: 2px solid #586B94; }

input[type="radio"]:checked::before {
  background: #586B94; }

.MuiRadio-colorSecondary.Mui-checked {
  color: #586B94 !important; }

.MuiTypography-body1 {
  font-size: 16px !important;
  line-height: 20px !important; }
