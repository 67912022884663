.size-20-28 {
  font-size: 20px;
  line-height: 28px; }

.size-14-20 {
  font-size: 14px;
  line-height: 20px; }

.size-12-20 {
  font-size: 12px;
  line-height: 20px; }

.mb-8 {
  margin-bottom: 8px; }

.mt-24 {
  margin-top: 24px; }

.medium-font {
  font-family: "Poppins-Medium", sans-serif; }

.regular-font {
  font-family: "Poppins-Regular", sans-serif; }

.br-gray {
  border: 1px solid #E2E2E2; }

.p-24-16 {
  padding: 24px 16px 20px; }

.border-radius-8 {
  border-radius: 8px; }

.mrl-0 {
  margin-right: 0px;
  margin-left: 0px; }

.border-radius-1000 {
  border-radius: 1000px; }

.padding-8-0 {
  padding: 8px 0px; }

.bg-light-blue {
  background-color: #F1F6FF; }

.font-color-blue-gray {
  color: #39455E !important; }

.full-w-100 {
  width: 100%; }

.anchor {
  visibility: hidden;
  height: 92px;
  margin-top: -92px;
  display: block; }
