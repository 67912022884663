.slick-slider .slick-prev {
  left: -15px;
  top: 35%;
  transform: rotate(180deg);
  background: url("../../../../../Assets/Images/Circular_arrow.png") center no-repeat !important;
}

.slick-slider .slick-next {
  right: -15px;
  top: 46%;
  background: url("../../../../../Assets/Images/Circular_arrow.png") center no-repeat !important;
}

.slick-slider .slick-prev,
.slick-slider .slick-next {
  z-index: 1;
  cursor: pointer;
  width: 3.5vw !important;
  height: 3.5vw !important;
  border-radius: 50%;
}

.slick-slider .slick-disabled {
  display: none !important;
}

.check-icon {
  width: 18px;
  visibility: hidden;
}

.card-selected .MuiCardContent-root {
  background-color: #586B94;
}

.card-selected .check-icon {
  visibility: visible;
}

.card-selected div,
.card-selected .color-gray,
.card-selected .color-black,
.card-selected .color-blue {
  color: white;
}

.height-40 {
  height: 40px;
}

.txt-ellipsis-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.intl-tel-input:has(.country-list) {
  z-index: 99999999999 !important;
}

@media (max-width: 800px) {
  .sm-min-height-48 {
    min-height: 48px;
  }
}