a:hover,
a:active,
a:focus {
  color: inherit !important;
}

.App {
  text-align: center;
}

.App .App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 80px;
}

.App .App-header {
  background-color: #222;
  height: 150px;
  padding: 20px;
  color: white;
}

.App .App-title {
  font-size: 1.5em;
}

.App .App-intro {
  font-size: large;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(360deg);
  }
}

.d-block-imp {
  display: block !important;
}

.tip-label {
  padding: 2px 4px;
  border-radius: 4px;
  background-color: #FEEDE9;
  color: #A14538;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
}

.mr-4 {
  margin-right: 4px;
}

.underline {
  text-decoration: underline;
}

.deco-none,
.deco-none:hover,
.deco-none:focus,
.deco-none:active {
  text-decoration: none;
}

#chat-message-list {
  grid-area: chat-message-list;
  display: flex;
  flex-direction: column-reverse;
  padding: 0 20px;
  overflow-y: scroll;
}

.lottie-generic {
  width: 100%;
}

.blue-check-lottie {
  text-align: center;
  height: 182px;
}

.centered-188 {
  text-align: center;
  height: 188px;
}

.txt-left {
  text-align: left;
}

.txt-right {
  text-align: right;
}

.size-52-64 {
  font-size: 52px;
  line-height: 64px;
}

.size-32-40 {
  font-size: 32px;
  line-height: 40px;
}

.size-28-36 {
  font-size: 28px;
  line-height: 36px;
}

.size-24-32 {
  font-size: 24px;
  line-height: 32px;
}

.size-22-30 {
  font-size: 22px;
  line-height: 30px;
}

.size-20-28 {
  font-size: 20px;
  line-height: 28px;
}

.size-18-28 {
  font-size: 18px;
  line-height: 28px;
}

.size-18-24 {
  font-size: 18px;
  line-height: 24px;
}

.size-18-28-imp {
  font-size: 18px !important;
  line-height: 28px !important;
}

.size-18-24-imp {
  font-size: 18px !important;
  line-height: 24px !important;
}

.size-16-24 {
  font-size: 16px;
  line-height: 24px;
}

.size-16-20 {
  font-size: 16px;
  line-height: 20px;
}

.size-14-16-imp {
  font-size: 14px !important;
  line-height: 16px !important;
}

.size-14-16 {
  font-size: 14px;
  line-height: 16px;
}

.size-14-20 {
  font-size: 14px;
  line-height: 20px;
}

.size-12-16 {
  font-size: 12px;
  line-height: 16px;
}

.size-12-18 {
  font-size: 12px;
  line-height: 18px;
}

.size-12-20 {
  font-size: 12px;
  line-height: 20px;
}

.size-12-20-imp .MuiBadge-badge {
  font-size: 12px !important;
  line-height: 20px !important;
}

.size-10-20 {
  font-size: 10px;
  line-height: 20px;
}

.size-10-15 {
  font-size: 10px;
  line-height: 15px;
}

.font-weight-bold {
  font-family: "Poppins-Medium", sans-serif;
}

.contentPrimary,
.contentPrimary:hover,
.contentPrimary:focus {
  color: #000000;
}

.contentPrimary-imp,
.contentPrimary-imp:hover,
.contentPrimary-imp:focus {
  color: #000000 !important;
  background: none !important;
}

.contentInversePrimary,
.contentInversePrimary:hover,
.contentInversePrimary:focus {
  color: white !important;
}

.contentSecondary,
.contentSecondary:hover,
.contentSecondary:focus {
  color: #545454;
}

.contentTertiary,
.contentTertiary:hover,
.contentTertiary:focus {
  color: #757575;
}

.systemRed,
.systemRed:hover,
.systemRed:focus {
  color: #C11500;
}

.systemGreen,
.systemGreen:hover,
.systemGreen:focus {
  color: #05944F;
}

.MuiSvgIcon-root.contentTertiary {
  color: #757575 !important;
}

.DarkBluePrimary,
.DarkBluePrimary:hover,
.DarkBluePrimary:focus {
  color: #39455E;
}

.LavendarAsh,
.LavendarAsh:hover,
.LavendarAsh:focus {
  color: #464A5B;
}

.BluePrimary,
.BluePrimary:hover,
.BluePrimary:focus {
  color: #586B94;
}

.CactusFlowerPrimary,
.CactusFlowerPrimary:hover,
.CactusFlowerPrimary:focus {
  color: #F58266;
}

.LightBlack,
.LightBlack:hover,
.LightBlack:focus {
  color: #333333;
}

.BluishBlack,
.BluishBlack:hover,
.BluishBlack:focus {
  color: #2E364D;
}

.SoftBlack,
.SoftBlack:hover,
.SoftBlack:focus,
.SoftBlack:active {
  color: #2C3239;
}

.NickleColor,
.NickleColor:hover,
.NickleColor:focus,
.NickleColor:active {
  color: #5F6368;
}

.WarningYellow,
.WarningYellow:hover,
.WarningYellow:focus,
.WarningYellow:active {
  color: #BC8B2C;
}

.m-auto {
  margin: auto;
}

.m-26-24 {
  margin: 26px 24px;
}

.m-26-24-20 {
  margin: 26px 24px 20px;
}

.m-26-24-0 {
  margin: 26px 24px 0px;
}

.mtb-90 {
  margin-top: 90px;
  margin-bottom: 90px;
}

.mtb-32 {
  margin-top: 32px;
  margin-bottom: 32px;
}

.mtb-24 {
  margin-top: 24px;
  margin-bottom: 24px;
}

.mtb-16 {
  margin-top: 16px;
  margin-bottom: 16px;
}

.mt-36 {
  margin-top: 36px;
}

.mt-24 {
  margin-top: 24px;
}

.mt-20 {
  margin-top: 20px;
}

.mt-16 {
  margin-top: 16px;
}

.m-16 {
  margin: 16px;
}

.ml-16 {
  margin-left: 16px;
}

.ml-8 {
  margin-left: 8px;
}

.ml-6 {
  margin-left: 6px;
}

.ml-0 {
  margin-left: 0px;
}

.mlr-5 {
  margin-left: 5px;
  margin-right: 5px;
}

.mr-16 {
  margin-right: 16px;
}

.mr-12 {
  margin-right: 12px;
}

.mr-10 {
  margin-right: 10px;
}

.mr-8 {
  margin-right: 8px;
}

.mr-24 {
  margin-right: 24px;
}

.mr-28 {
  margin-right: 28px;
}

.mr-32 {
  margin-right: 32px;
}

.minus-mrl-20 {
  margin-left: -20px;
  margin-right: -20px;
}

.b-55-imp {
  bottom: 55px !important;
}

.mb-235 {
  margin-bottom: 235px;
}

.mb-150 {
  margin-bottom: 150px;
}

.mb-100 {
  margin-bottom: 100px;
}

.mb-48 {
  margin-bottom: 48px;
}

.mb-45 {
  margin-bottom: 45px;
}

.mb-40 {
  margin-bottom: 40px;
}

.mb-36 {
  margin-bottom: 36px;
}

.mb-34 {
  margin-bottom: 34px;
}

.mb-32 {
  margin-bottom: 32px;
}

.mb-30 {
  margin-bottom: 30px;
}

.mt-30 {
  margin-top: 30px;
}

.mb-28 {
  margin-bottom: 28px;
}

.mb-24 {
  margin-bottom: 24px;
}

.mb-20 {
  margin-bottom: 20px;
}

.mb-16 {
  margin-bottom: 16px;
}

.mb-12 {
  margin-bottom: 12px;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-8 {
  margin-bottom: 8px;
}

.mb-6 {
  margin-bottom: 6px;
}

.mb-4 {
  margin-bottom: 4px;
}

.mb-2 {
  margin-bottom: 2px;
}

.mb-0-imp {
  margin-bottom: 0px !important;
}

.mb-minus-5 {
  margin-bottom: -5px;
}

.mx-minus-24 {
  margin-right: -24px;
  margin-left: -24px;
}

.nav-pills>li+li {
  margin-left: 0px;
}

.nav-pills>li>a {
  border-radius: 0px;
}

.pr-0 {
  padding-right: 0px;
}

.pr-8 {
  padding-right: 8px;
}

.pr-24 {
  padding-right: 24px;
}

.pb-12 {
  padding-bottom: 12px;
}

.pb-24 {
  padding-bottom: 24px;
}

.pb-45 {
  padding-bottom: 45px;
}

.pb-80 {
  padding-bottom: 80px;
}

.ptb-20 {
  padding-bottom: 20px;
  padding-top: 20px;
}

.ptb-16 {
  padding-bottom: 16px;
  padding-top: 16px;
}

.ptb-14 {
  padding-bottom: 14px;
  padding-top: 14px;
}

.ptb-8 {
  padding-bottom: 8px;
  padding-top: 8px;
}

.ptb-4 {
  padding-bottom: 4px;
  padding-top: 4px;
}

.plr-16 {
  padding-right: 16px;
  padding-left: 16px;
}

.pl-64 {
  padding-left: 64px;
}

.pl-28 {
  padding-left: 28px;
}

.pl-0 {
  padding-left: 0px;
}

.prl-0 {
  padding-right: 0px;
  padding-left: 0px;
}

.prl-5 {
  padding-right: 5px;
  padding-left: 5px;
}

.c-plr-64 {
  padding: 0px 64px;
}

.p-10-12 {
  padding: 10px 12px;
}

.p-10-12-imp {
  padding: 10px 12px !important;
}

.p-12-16 {
  padding: 12px 16px;
}

.p-10-20-imp {
  padding: 10px 20px !important;
}

.p-14-64-imp {
  padding: 14px 14px 14px 64px !important;
}

.p-14-16 {
  padding: 14px 16px;
}

.p-14 {
  padding: 14px;
}

.p-13 {
  padding: 13px;
}

.ptb-13 {
  padding-top: 13px;
  padding-bottom: 13px;
}

.p-38-35-33 {
  padding: 38px 35px 33px;
}

.pl-16 {
  padding-left: 16px;
}

.pt-80 {
  padding-top: 80px;
}

.pt-32-imp {
  padding-top: 32px !important;
}

.p-16 {
  padding: 16px;
}

.p-32 {
  padding: 32px;
}

.p-16-20 {
  padding: 16px 20px;
}

.p-16-20-imp,
.p-16-20-imp:hover,
.p-16-20-imp:focus {
  padding: 16px 20px !important;
}

.p-16-0-24-24 {
  padding: 16px 0px 24px 24px;
}

.p-16-16-22 {
  padding: 16px 16px 22px;
}

.p-16-16-24 {
  padding: 16px 16px 24px;
}

.p-0-imp {
  padding: 0px !important;
}

.p-0 {
  padding: 0px;
}

.p-0-16 {
  padding: 0px 16px;
}

.p-6-16 {
  padding: 6px 16px;
}

.p-18-16-0 {
  padding: 18px 16px 18px 0px;
}

.p-26-16 {
  padding: 26px 16px;
}

.p-16-64 {
  padding: 16px 64px;
}

.p-24 {
  padding: 24px;
}

.p-24-16-16 {
  padding: 24px 16px 16px;
}

.pt-24 {
  padding-top: 24px;
}

.p-24-16-0 {
  padding: 24px 16px 0px;
}

.p-24-16 {
  padding: 24px 16px;
}

.p-10 {
  padding: 10px;
}

.p-2-4 {
  padding: 2px 4px;
}

.p-40-64 {
  padding: 40px 64px;
}

.bg-cover {
  background-size: cover;
}

.bg-primary {
  background-color: #FFFFFF;
}

.bg-secondary {
  background-color: #F6F6F6;
}

.bg-gray {
  background-color: #EBEBEB;
}

.grd-bg-gray {
  background-image: linear-gradient(to right, #EBEBEB, #ECECEC);
}

.bg-gray-100 {
  background-color: #EEEEEE;
}

.bg-accent-light {
  background-color: #F1F6FF;
}

.bg-accent {
  background-color: #586B94;
}

.bg-peach {
  background-color: #FEEDE9;
}

.bg-cactus-flower {
  background-color: #F58266;
}

.bg-deep-orange {
  background-color: #D9664F;
}

.bg-lavendar-subtle {
  background-color: #F2F4FF;
}

.bg-yellow {
  background-color: #FFFAF0;
}

.br-rd-24 {
  border-radius: 24px;
}

.br-rd-50 {
  border-radius: 50%;
}

.top-br-rd-16 {
  border-radius: 16px 16px 0px 0px;
}

.btn-accent-light,
.btn-accent-light:hover,
.btn-accent-light:focus {
  border-radius: 9999px;
  font-size: 14px;
  line-height: 16px;
  font-family: "Poppins-Medium", sans-serif;
  color: #39455E;
  padding: 10px 12px;
  background-color: #F1F6FF;
}

.cta-primary,
.cta-primary:hover,
.cta-primary:focus {
  background-color: #F58266;
  color: #FFFFFF;
  font-family: "Poppins-Medium", sans-serif;
  font-size: 18px;
  line-height: 24px;
  padding: 16px 20px;
  border-radius: 30px;
  width: 100%;
}

.md-cta-primary,
.md-cta-primary:hover,
.md-cta-primary:focus {
  background-color: #F58266;
  color: #FFFFFF;
  font-family: "Poppins-Medium", sans-serif;
  font-size: 14px;
  line-height: 16px;
  padding: 16px 20px;
  border-radius: 30px;
  width: 100%;
  border: 0px;
}

.small-cta-primary,
.small-cta-primary:hover,
.small-cta-primary:focus {
  background-color: #F58266;
  color: #FFFFFF;
  font-family: "Poppins-Medium", sans-serif;
  font-size: 14px;
  line-height: 16px;
  padding: 10px 24px;
  border-radius: 18px;
}

.cta-secondary,
.cta-secondary:hover,
.cta-secondary:focus {
  background-color: #F6F6F6;
  color: #39455E;
  font-family: "Poppins-Medium", sans-serif;
  font-size: 18px;
  line-height: 24px;
  padding: 16px 20px;
  border-radius: 30px;
  width: 100%;
}

.small-cta-secondary,
.small-cta-secondary:hover,
.small-cta-secondary:focus {
  background-color: #F6F6F6;
  color: #F58266;
  font-family: "Poppins-Medium", sans-serif;
  font-size: 14px;
  line-height: 16px;
  padding: 10px 24px;
  border-radius: 18px;
}

.cta-tertiary,
.cta-tertiary:hover,
.cta-tertiary:focus {
  background-color: #F1F6FF;
  color: #39455E;
  font-family: "Poppins-Medium", sans-serif;
  font-size: 18px;
  line-height: 24px;
  padding: 16px 20px;
  border-radius: 30px;
  width: 100%;
}

.md-cta-tertiary,
.md-cta-tertiary:hover,
.md-cta-tertiary:focus {
  background-color: #F1F6FF;
  color: #39455E;
  font-family: "Poppins-Medium", sans-serif;
  font-size: 14px;
  line-height: 16px;
  padding: 16px 20px;
  border-radius: 30px;
  width: 100%;
  border: 0px;
}

.md-cta-quaternary,
.md-cta-quaternary:hover,
.md-cta-quaternary:focus {
  background-color: #586B94;
  color: #FFFFFF;
  font-family: "Poppins-Medium", sans-serif;
  font-size: 14px;
  line-height: 16px;
  padding: 10px 12px;
  border-radius: 30px;
  border: 0px;
  white-space: normal;
}

.centered {
  text-align: center;
}

.end {
  text-align: end;
}

.align-items-center {
  align-items: center;
}

.align-items-end {
  align-items: end;
}

.justify-content-centered {
  justify-content: center;
  -webkit-justify-content: center;
}

.justify-content-spaced {
  justify-content: space-between;
  -webkit-justify-content: space-between;
}

.justify-content-around {
  justify-content: space-around;
  -webkit-justify-content: space-around;
}

.justify-content-end {
  justify-content: end;
  -webkit-justify-content: end;
}

.display-contents {
  display: contents;
}

.d-inline-block {
  display: inline-block;
}

.flex-1-1-45 {
  flex: 1 1 45%;
}

.flex-1-1-10 {
  flex: 1 1 10%;
}

.maxW-75-vw {
  max-width: 75vw;
}

.w-120-imp {
  width: 120px !important;
}

.w-450 {
  width: 450px;
}

.resp-w-303 {
  width: 303px;
}

.w-75-imp {
  width: 75vw !important;
}

.maxW-ml0 {
  max-width: inherit;
  margin-left: 0;
}

.overflow-y-scroll {
  overflow-x: hidden;
  overflow-y: scroll;
}

.set-overflow {
  overflow-x: hidden;
  overflow-y: scroll;
}

.max-h-full {
  max-height: calc(100vh - 80px);
}

.max-h-full-90 {
  max-height: calc(100vh - 90px);
}

.max-h-full-55 {
  max-height: calc(100vh - 54.4px);
}

.max-h-full-44 {
  max-height: calc(100vh - 124px);
}

.max-w-60 {
  max-width: 62.1vw;
}

.max-w-50-vw {
  max-width: 50vw;
  margin: auto;
}

.max-w-27 {
  max-width: 27.26vw;
}

.w-25-minus-48 {
  width: calc(25% - 48px);
}

.ht-100-70 {
  height: calc(100% - 70px);
}

.no-scroll-x {
  overflow-x: hidden;
}

.intl-tel-input:has(.z-index-0) {
  z-index: 0;
}

.z-index-0 {
  z-index: 0;
}

.z-index-1 {
  z-index: 1;
}

.z-index-2 {
  z-index: 2;
}

.z-index-9999 {
  z-index: 9999;
}

.z-index-5x9-imp {
  z-index: 99999 !important;
}

.z-index-11x9 {
  z-index: 99999999999;
}

.z-index-13x9-imp {
  z-index: 9999999999999 !important;
}

.flex-centered {
  display: flex;
  align-items: center;
  -webkit-align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
}

.d-flex-only {
  display: flex;
}

.flex-grow-1 {
  flex-grow: 1;
}

.flex-grow-05 {
  flex-grow: .5;
}

.width-30vw {
  width: 30vw;
}

.width-419 {
  width: 419px;
}

.width-fill-available,
.width-fill-available:hover,
.width-fill-available:active,
.width-fill-available:focus {
  width: stretch;
}

.width-auto {
  width: auto !important;
}

.width-464 {
  width: 464px;
}

.width-485 {
  width: 485px;
}

.width-485-imp {
  width: 485px !important;
}

.width-82 {
  width: 82px;
}

.width-67 {
  width: 67px;
}

.width-64 {
  width: 64px;
}

.width-55 {
  width: 55px;
}

.height-320 {
  height: 320px;
}

.height-64 {
  height: 64px;
}

.height-67 {
  height: 67px;
}

.height-44 {
  height: 44px;
}

.height-40 {
  height: 40px;
}

.height-30 {
  height: 30px;
}

.height-24 {
  height: 24px;
}

.height-17 {
  height: 17px;
}

.width-200 {
  width: 200px;
}

.width-54 {
  width: 54vw;
}

.width-20 {
  width: 20px;
}

.dim-10-12 {
  width: 10px;
  height: 12px;
}

.width-23 {
  width: 23vw;
}

.width-60 {
  width: 60px;
}

.height-60 {
  height: 60px;
}

.height-80 {
  height: 80px;
}

.height-full {
  min-height: calc(100vh - 524px);
  overflow-y: scroll;
}

.height-full-small {
  min-height: calc(100vh - 434px);
  overflow-y: scroll;
}

.height-custom-100 {
  height: calc(100vh - 200px);
}

.height-100-80 {
  height: calc(100vh - 80px);
}

.height-100-140 {
  height: calc(100vh - 20px);
}

.height-100-perc-mobile {
  height: 100%;
}

.ht-100 {
  height: 100%;
}

.height-100-perc {
  height: calc(100vh - 140px);
}

.height-266 {
  height: 266px;
}

.height-23 {
  height: 23vh;
}

.height-1205 {
  height: 12.5vh;
}

.w-h-18 {
  width: 18px;
  height: 18px;
}

.w-h-20-imp {
  width: 20px !important;
  height: 20px !important;
}

.w-h-20 {
  width: 20px;
  height: 20px;
}

.w-h-24 {
  width: 24px;
  height: 24px;
}

.w-h-28 {
  width: 28px;
  height: 28px;
}

.w-h-56 {
  width: 56px;
  height: 56px;
}

.w-h-60 {
  width: 60px;
  height: 60px;
}

.w-h-80 {
  width: 80px;
  height: 80px;
}

.w-h-100 {
  width: 100px;
  height: 100px;
}

.w-h-150 {
  width: 150px;
  height: 150px;
}

.w-h-118-130 {
  width: 118px;
  height: 130px;
}

.w-h-200 {
  width: 200px;
  height: 200px;
}

.cstm-avatar-img {
  justify-content: 'center';
  align-items: "center";
  border-radius: 50%;
  border: none !important;
  z-index: 0 !important;
}

.shrink-avatar-group .MuiAvatarGroup-avatar {
  margin-left: -16px !important;
  margin-top: 10px !important;
}

.shrink-avatar-group .MuiAvatarGroup-avatar:first-child {
  margin-left: 0px !important;
  margin-top: 0px !important;
}

.shrink-avatar-group .MuiDivider-root {
  height: .5px;
}

.br-none {
  border: none;
}

.light-mode-br-transparent {
  border-bottom: 5px solid rgba(0, 0, 0, 0.08);
}

.br-top-none-imp {
  border-top: none !important;
}

.br-top-none {
  border-top: none;
}

.br-cactus-flower {
  border: 1px solid #F58266;
}

.br-yellow {
  border: 1px solid #BC8B2C;
}

.br-t-opaque {
  border-top: 1px solid #E2E2E2;
}

.br-t-opaque-2 {
  border-top: 2px solid #E2E2E2;
}

.br-opaque {
  border: 1px solid #E2E2E2;
}

.br-opaque-lavendar-dark {
  border: 1px solid #5F637A;
}

.br-opaque-2 {
  border: 2px solid #E2E2E2;
}

.br-rd-9999 {
  border-radius: 9999px;
}

.br-rd-48 {
  border-radius: 48px;
}

.br-rd-36 {
  border-radius: 36px;
}

.br-rd-30 {
  border-radius: 30px;
}

.br-rd-16 {
  border-radius: 16px;
}

.br-rd-8 {
  border-radius: 8px;
}

.br-rd-4 {
  border-radius: 4px;
}

.br-tb-opaque {
  border-top: 1px solid #E2E2E2;
  border-bottom: 1px solid #E2E2E2;
}

.br-r-opaque {
  border-right: 1px solid #E2E2E2;
}

.br-l-opaque {
  border-left: 1px solid #E2E2E2;
}

.br-b-opaque {
  border-bottom: 1px solid #E2E2E2;
}

.notLast-br-b-opaque:not(:last-of-type) {
  border-bottom: 1px solid #E2E2E2;
}

.br-b-opaque-2 {
  border-bottom: 2px solid #E2E2E2;
}

.br-b-blue-2 {
  border-bottom: 2px solid #586B94;
}

.br-b-blue-5 {
  border-bottom: 5px solid #586B94;
}

.br-blue-2 {
  border: 2px solid #586B94;
}

.relative {
  position: relative;
}

.relative-imp {
  position: relative !important;
}

.bottom-sticky {
  position: sticky;
  bottom: 0px;
}

.top-sticky {
  position: sticky;
  top: 0;
  z-index: 1;
}

.top-sticky-80 {
  position: sticky;
  top: 80px;
}

.top-sticky-141 {
  position: sticky;
  top: 141px;
}

.bottom-minus-25 {
  bottom: -25px;
}

.abs-btm {
  position: absolute;
  bottom: 0;
}

.abs-top {
  position: absolute;
  top: 0;
}

.r-24 {
  right: 24px;
}

.r-16 {
  right: 16px;
}

.r-0 {
  right: 0px;
}

.r-82 {
  right: 82px !important;
}

.t-16 {
  top: 16px;
}

.cursor-pointer {
  cursor: pointer;
}

#persona-custom-parent iframe,
#persona-custom-parent-2 iframe {
  position: absolute;
  top: 55%;
  max-height: 80vh;
}

.label-xs-lavender-ash {
  color: #464A5B;
  font-size: 12px;
  font-style: normal;
  font-weight: 500;
  line-height: 16px;
  border-radius: 4px;
  border: 1px solid #464A5B;
  padding: 2px 4px;
  margin: 0px 8px;
}

@media (max-width: 600px),
(max-height: 420px) {

  #persona-custom-parent iframe,
  #persona-custom-parent-2 iframe {
    position: absolute;
    top: 50%;
    max-height: 100%;
  }

  html body>div#root {
    display: block !important;
  }
}

.m-12 {
  margin: 12px;
}

.mt-8 {
  margin-top: 8px;
}

.mt-60 {
  margin-top: 60px;
}

.mt-80 {
  margin-top: 80px;
}

.min-height-100 {
  min-height: 100vh;
}

.min-ht-40 {
  min-height: 40px;
}

.w-100-perc {
  width: 100%;
}

.w-100-perc-minus-64 {
  width: calc(100% - 64px);
}

.w-75-minus-64 {
  width: calc(75vw - 64px);
}

.w-h-119 {
  width: 119px;
  height: 119px;
}

.w-h-48 {
  width: 48px;
  height: 48px;
}

.w-h-36 {
  width: 36px;
  height: 36px;
}

.w-33 {
  width: 33.33vw;
}

.obj-fit-cover {
  object-fit: cover;
}

.ellipsis-2 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
}

.ellipsis-3 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  -webkit-box-orient: vertical;
}

.ellipsis-4 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.MuiBadge-colorSecondary {
  background-color: #F58266 !important;
}

.MuiBadge-colorPrimary {
  background-color: #586B94 !important;
  color: #FFFFFF !important;
}

.centered-badge .MuiBadge-anchorOriginBottomLeftRectangular {
  transform: scale(1) translate(78%, 50%);
}

.badge-w-46 .MuiBadge-badge {
  min-width: 46px;
}

.xs-bdg-top .MuiBadge-badge {
  transform: scale(1) translate(25%, 0%);
  padding: 0px;
  font-size: 9px;
  line-height: 13px;
  background-color: #2E364D;
  color: #FFFFFF;
}

.generic-selector-style-white .select__control {
  height: 44px;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
  border-radius: 3px;
  border: 1px solid #E2E2E2 !important;
  background: #FFF !important;
}

.generic-selector-style-white .select__control.select__control--menu-is-open,
.generic-selector-style-white .select__control.select__control--is-focused {
  box-shadow: none !important;
}

.generic-selector-style-white.invalid .select__control {
  color: #ff4d4f;
  border-color: #ff4d4f !important;
}

.generic-selector-style-white.invalid~.invalid-msg {
  display: block;
}

.generic-input-style {
  background: #EEEEEE;
  border-radius: 4px;
  width: 100%;
  color: black;
  border: 0px solid #F58266;
  font-size: 16px;
  line-height: 24px;
  padding: 16px 16px 17px;
  height: 48px;
  box-shadow: none;
}

.generic-input-style:focus {
  border: 1px solid #F58266;
}

.generic-input-style.error-state,
.generic-input-style.error-state:focus {
  border: 1px solid #C11500;
  background: #FFEFED;
}

.generic-input-style-blue {
  background: #EEEEEE;
  border-radius: 4px;
  width: 100%;
  color: black;
  border: 0px solid #586B94;
  font-size: 16px;
  line-height: 24px;
  padding: 16px 16px 17px;
}

.generic-input-style-blue:focus {
  border: 1px solid #586B94;
}

.generic-input-style-white {
  height: 44px;
  padding: 12px 15px 14px 10px;
  border-radius: 3px;
  border: 1px solid #E2E2E2;
  background: #FFF;
  color: #000;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.generic-input-style-white:focus {
  border: 1px solid #586B94;
}

.generic-input-style-white::placeholder {
  color: #9299B8;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.generic-input-style-white.invalid,
.generic-input-style-white.invalid:focus {
  color: #ff4d4f;
  border: 1px solid #ff4d4f;
  background-color: #fff;
}

.invalid-msg {
  color: #ff4d4f;
  font-size: 12px;
  margin-top: 10px;
  display: none;
}

.generic-input-style-white.invalid~.invalid-msg {
  display: block;
}

.generic-input-style-white.rdt.invalid~.invalid-msg {
  display: block;
}

.small-centered-grids .centered-cell {
  border-radius: 4px;
  border: 1px solid #E2E2E2;
  background: #FFF;
  color: #586B94;
}

.small-centered-grids .cell-title {
  font-family: "Poppins-Regular", sans-serif;
  font-size: 14px;
  line-height: 16px;
  font-weight: 500;
}

.plain-gray-input {
  background: #EEEEEE;
  border-radius: 4px;
  height: 48px;
  padding: 12px 16px;
  font-size: 16px;
  line-height: 24px;
  width: 100%;
  color: #000000;
}

.plain-gray-input:focus {
  border: 2px solid #586B94;
}

.plain-gray-input::placeholder {
  color: #757575;
  font-size: 16px;
  line-height: 24px;
}

.error-state-input {
  border-radius: 4px;
  border: 2px solid #F1998E;
  background: #FFEFED;
}

.fixed-top-80 {
  position: fixed;
  top: 80px;
  left: 0;
}

.fixed-btm {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.fxd-btm {
  position: fixed;
  bottom: 0;
}

.fxd-btm-right {
  position: fixed !important;
  bottom: 0;
  right: 0;
}

.fxd-btm-90 {
  position: fixed;
  bottom: 90px;
}

.mobile-toolbar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
}

.mobile-toolbar .MuiSvgIcon-root {
  height: 20px;
  width: auto;
  padding-bottom: 4px;
}

.mobile-toolbar .MuiBottomNavigationAction-label {
  font-size: 12px;
  line-height: 16px;
  font-family: "Poppins-Medium", sans-serif;
  color: #757575;
}

.mobile-toolbar .MuiBottomNavigationAction-root.Mui-selected {
  color: #586B94;
  text-decoration: underline;
}

.mobile-toolbar .MuiBottomNavigationAction-root.Mui-selected .MuiBottomNavigationAction-label {
  font-size: 12px;
  line-height: 16px;
  font-family: "Poppins-Medium", sans-serif;
  color: #586B94;
}

.light-header {
  background-color: #FFFFFF !important;
  border: none;
  color: #000000 !important;
}

.shadow-none-imp {
  box-shadow: none !important;
}

a:focus {
  outline: none;
}

.floatR {
  float: right;
}

.shadow-btm {
  box-shadow: 0px 4px 4px 1px rgba(0, 0, 0, 0.1);
}

.shadow-top {
  box-shadow: 0px 0px 6px 0px rgba(0, 0, 0, 0.02), 0px -2px 4px 0px rgba(0, 0, 0, 0.08);
}

.txt-shadow {
  text-shadow: 1px 0 0 #000, 0 -1px 0 #000, 0 1px 0 #000, -1px 0 0 #000;
}

.fixed-btn-shadow {
  box-shadow: 0px 8px 18px -6px rgba(0, 0, 0, 0.12), 0px 12px 42px -4px rgba(0, 0, 0, 0.12);
}

.fixed-btn-shadow-simple {
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);
}

.form-control {
  box-shadow: none;
}

.full-background {
  background: rgba(255, 255, 255, 0.4);
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  align-content: center;
  justify-content: center;
  z-index: 9999999999999;
  backdrop-filter: blur(10px);
}

.popup-frame-2 {
  background: #FFFFFF;
  box-shadow: 0px 8px 28px -6px rgba(0, 0, 0, 0.12), 0px 18px 88px -4px rgba(0, 0, 0, 0.14);
  border-radius: 16px;
  padding: 32px;
  width: 35vw;
  margin: auto;
  max-height: 90vh;
  overflow-y: scroll;
}

.or-separator {
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.gray-hr-line {
  height: 1px;
  background-color: #eee;
}

.login-popup .centered-cell {
  background-color: #FFF;
  border: 1px solid #eee;
  padding: 6px;
}

.transparent-button {
  background-color: transparent;
  border: none;
}

button.transparent-button~span {
  display: contents;
}

.success {
  position: relative;
}

.success .input-checkmark {
  visibility: visible;
}

.success:focus-within .input-checkmark,
.success .input-checkmark:focus {
  visibility: hidden;
}

.input-checkmark-editted {
  top: 16px !important;
  right: 16px !important;
}

.input-with-addon {
  position: relative;
}

.input-addon-r {
  visibility: hidden;
  position: absolute;
  top: 55%;
  right: 5%;
}

.input-with-addon .input-addon-r {
  visibility: visible;
}

.hidden-anchor-96 {
  visibility: hidden;
  height: 96px;
  margin-top: -96px;
  display: block;
}

.tabs-radio {
  background-color: #F6F6F6;
  display: inline-flex;
  overflow: hidden;
  border-radius: 20px;
  border: 1px solid #E2E2E2;
  flex-direction: inherit !important;
}

.tabs-radio-input {
  display: none !important;
}

.tabs-radio-label {
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  background-color: #F6F6F6;
  cursor: pointer;
  transition: background-color 0.1s;
  margin: 0px !important;
  font-family: "Poppins-Medium", sans-serif !important;
  padding: 8px 18px !important;
}

.tabs-radio-label .MuiTypography-body1 {
  font-family: "Poppins-Medium", sans-serif !important;
}

.tabs-radio>.tabs-radio-label {
  border-right: 1px solid #E2E2E2;
  border-radius: 20px;
}

.tabs-radio-label:has(.tabs-radio-input.Mui-checked) {
  background-color: #586B94;
  border: 1px solid #586B94;
  margin: -1px;
  color: #FFFFFF;
}

.opacity-05 {
  opacity: 0.5;
}

.hideFlags-force .iti-flag,
.hideFlags-force .flag-box {
  display: none !important;
}

.hideFlags-force .separate-dial-code .selected-dial-code {
  margin-left: 0 !important;
  font-size: 16px;
  line-height: 24px;
  padding-left: 0px !important;
}

.hideFlags-force .separate-dial-code .selected-flag {
  background-color: transparent !important;
  border-radius: 1px solid rgba(0, 0, 0, 0.05);
}

.reverse-modal-content {
  border-radius: 16px !important;
  height: auto !important;
  max-height: none !important;
}

/* div#gladlyChat_container:has(#gladlyChatTempWrapper),
div#gladlyChat_container:has(> #gladlyChatDragHandle) {
  left: 0px !important;
  right: unset !important;
} */

.rdtPicker tr:has(td.rdtToday)~tr td {
  color: #000;
}

.rdtDisabled {
  color: #757575 !important;
}

.generic-datepicker-style-white .rdtPicker table {
  background-color: #FFF !important;
}

.generic-datepicker-style-white.rdt input {
  height: 44px;
  padding: 12px 15px 14px 10px;
  border-radius: 3px;
  border: 1px solid #E2E2E2;
  background: #FFF;
  color: #000;
  font-size: 14px;
  font-weight: 400;
  line-height: 20px;
}

.generic-datepicker-style-white.rdt input:focus {
  border: 1px solid #586B94;
}

.generic-datepicker-style-white.rdt input::placeholder {
  color: #9299B8;
}

.generic-datepicker-style-white.rdt.invalid input,
.generic-datepicker-style-white.rdt.invalid input:focus {
  color: #ff4d4f;
  border-color: #ff4d4f;
}

.generic-datepicker-style-white.rdt.invalid~.invalid-msg {
  display: block;
}

.max-width-88 {
  max-width: 88vw;
}

.blue-badge {
  color: #5F637A;
  background-color: #CFDEFF;
  border-radius: 4px;
  padding: 2px 4px;
}

.navy-badge {
  color: #FFFFFF;
  background-color: #586B94;
  border-radius: 4px;
  padding: 2px 4px;
}

.drawer-wrapper .MuiBackdrop-root {
  background-color: transparent !important;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.child-w-50>a {
  width: 50%;
}

@media (max-width: 1000px) {
  .md-size-32-40 {
    font-size: 32px;
    line-height: 40px;
  }
}

@media (max-width: 800px) {
  .sm-fxd-btm-90 {
    position: fixed;
    bottom: 90px;
  }
  .width-485-imp {
    width: 100% !important;
  }

  .sm-d-none {
    display: none;
  }

  .sm-mb-177 {
    margin-bottom: 177px;
  }

  .sm-mb-254 {
    margin-bottom: 254px;
  }

  .sm-bg-secondary {
    background-color: #F6F6F6 !important;
  }

  .sm-display-block {
    display: block;
  }

  .sm-display-flex {
    display: flex !important;
  }

  /* div#gladlyChat_container:has(#gladlyChatTempWrapper),
  div#gladlyChat_container:has(> #gladlyChatDragHandle) {
    bottom: 60px !important;
    left: 0px !important;
    right: unset !important;
  } */

  .sm-size-28-36 {
    font-size: 28px !important;
    line-height: 36px !important;
  }

  .top-sticky-80 {
    top: 60px;
  }

  .sm-top-sticky-56 {
    top: 56px;
  }

  .xs-txt-align-center {
    text-align: center;
  }

  .sm-txt-left {
    text-align: left;
  }

  .c-plr-64 {
    padding: 0px;
  }

  .sm-p-24-16 {
    padding: 24px 16px;
  }

  .sm-p-10-12 {
    padding: 10px 12px;
  }

  .sm-p-0-16 {
    padding: 0px 16px;
  }

  .sm-p-16-16-0 {
    padding: 16px 16px 0px;
  }

  .sm-p-0 {
    padding: 0px;
  }

  .sm-mt-0 {
    margin-top: 0px;
  }

  .sm-mt-0-imp {
    margin-top: 0px !important;
  }

  .sm-mb-0 {
    margin-bottom: 0px !important;
  }

  .sm-pb-72 {
    padding-bottom: 72px !important;
  }

  .sm-m-0 {
    margin: 0px;
  }

  .sm-mr-0 {
    margin-right: 0px;
  }

  .sm-mr-0-imp {
    margin-right: 0px !important;
  }

  .sm-m-16-16-22 {
    margin: 16px 16px 22px;
  }

  .sm-align-left {
    text-align: left !important;
  }

  .popup-frame-2 {
    width: 100%;
    padding-bottom: 180px;
    max-height: 100vh;
    margin: unset;
  }

  .popup-frame-2 .ctaButton-wrapper {
    z-index: 1;
  }

  .sm-ellipsis-2 {
    -webkit-line-clamp: 2;
  }

  .sm-w-100 {
    max-width: 100vw;
  }

  .sm-h-auto-imp {
    height: auto !important;
  }

  .sm-hidden {
    display: none;
  }

  .sm-relative {
    position: relative;
  }

  .sm-relative-imp {
    position: relative !important;
  }

  .sm-mb-12 {
    margin-bottom: 12px;
  }

  .sm-mb-8 {
    margin-bottom: 8px;
  }

  .sm-mb-150 {
    margin-bottom: 150px;
  }

  .sm-br-rd-0 {
    border-radius: 0px;
  }

  .sm-br-rd-16-imp {
    border-radius: 16px !important;
  }

  .max-w-50-vw {
    max-width: 100%;
  }

  .w-450 {
    width: 100%;
  }

  .resp-w-303 {
    width: 100%;
  }

  .max-width-88 {
    max-width: 100%;
  }
}

@media (max-width: 369px) {
  .tabs-radio {
    display: inline-block !important;
  }

  .tabs-radio-label {
    display: block !important;
  }
}