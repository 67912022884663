.creditCardModal.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7); }
  .creditCardModal.overlay .creditCards {
    overflow: auto; }
  .creditCardModal.overlay .popup {
    margin: 70px auto;
    padding: 20px;
    background: #fff;
    width: 40%;
    position: relative;
    transition: all 5s ease-in-out; }
    .creditCardModal.overlay .popup h2 {
      margin: 5px 5px 15px 5px;
      color: #333;
      text-align: center;
      line-height: 28px !important;
      font-size: 24px !important;
      font-family: "Poppins-Medium", sans-serif !important;
      color: #29323B !important; }
    .creditCardModal.overlay .popup label {
      clear: both;
      float: left;
      width: 100%;
      color: #5F6368;
      font-family: "Poppins-Medium", sans-serif;
      font-size: 13px;
      line-height: 21px;
      margin: 15px 0 10px 0; }
    .creditCardModal.overlay .popup input {
      box-shadow: 0;
      -webkit-box-shadow: none;
      -moz-box-shadow: none;
      color: #2C3239;
      font-family: "Poppins-Light", sans-serif;
      height: 44px;
      border-radius: 50px;
      border: 1px solid transparent;
      background: #F9F9F9;
      line-height: 26px; }
    .creditCardModal.overlay .popup .stripe-cardnumber-element, .creditCardModal.overlay .popup .stripe-expiration-element, .creditCardModal.overlay .popup .stripe-cvc-element, .creditCardModal.overlay .popup input {
      box-shadow: 0 !important;
      -webkit-box-shadow: none !important;
      -moz-box-shadow: none !important;
      color: #2C3239 !important;
      font-family: "Poppins-Light", sans-serif !important;
      height: 44px !important;
      border-radius: 50px !important;
      border: 1px solid transparent !important;
      background: #F9F9F9 !important;
      line-height: 26px !important; }
    .creditCardModal.overlay .popup .contentform input {
      margin: 10px 0; }
    .creditCardModal.overlay .popup .content {
      max-height: 30%;
      overflow: auto; }
      .creditCardModal.overlay .popup .content .add-card-container {
        text-align: center; }
        .creditCardModal.overlay .popup .content .add-card-container .add-card {
          height: 52px !important;
          width: 250px !important;
          background: #F58266 !important;
          color: #FFFFFF !important;
          border-radius: 50px !important;
          font-family: "Poppins-SemiBold", sans-serif !important;
          font-size: 18px !important;
          line-height: 22px !important;
          padding: 16px 0 !important;
          margin: 40px auto 0 auto !important; }
      .creditCardModal.overlay .popup .content .back-btn {
        font-family: "Poppins-SemiBold", sans-serif;
        font-size: 18px;
        line-height: 22px;
        color: #F58266;
        border: none;
        box-shadow: none;
        margin-top: 20px; }
      .creditCardModal.overlay .popup .content .input-name-margin-bottom input {
        margin-bottom: 10px; }
      .creditCardModal.overlay .popup .content input {
        margin: 10px 0;
        font-family: "gotham-htf-medium", sans-serif; }
    .creditCardModal.overlay .popup .form-pod .form-pod-split input, .creditCardModal.overlay .popup .creditCardModal .form-pod-large input {
      font-family: "gotham-htf-medium", sans-serif; }
    .creditCardModal.overlay .popup .form-pod .form-pod-split {
      display: inline-block;
      width: 49%; }
    .creditCardModal.overlay .popup .form-pod #input-margin-right {
      margin-right: 9px; }

@media screen and (max-width: 700px) {
  .creditCardModal .popup {
    width: 90% !important; } }
