.custom-modal {
  position: fixed;
  z-index: 1;
  padding-top: 100px;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background: rgba(255, 255, 255, 0.4); }
  .custom-modal .modal-content {
    font-family: "Poppins-Regular", sans-serif;
    margin: auto;
    padding: 20px;
    max-width: 725px;
    width: 100%;
    color: #2B323A;
    background: #FFFFFF;
    box-shadow: 0px 8px 28px -6px rgba(0, 0, 0, 0.12), 0px 18px 88px -4px rgba(0, 0, 0, 0.14);
    border-radius: 16px; }
    @media (max-width: 600px) {
      .custom-modal .modal-content {
        width: 90% !important; } }
    .custom-modal .modal-content .modal-title {
      font-family: "Poppins-Medium", sans-serif;
      font-size: 24px;
      line-height: 32px;
      color: #000000; }
    .custom-modal .modal-content .content {
      max-width: 560px;
      color: #2C3239;
      font-family: "Poppins-Light", sans-serif;
      font-size: 16px;
      line-height: 25px;
      margin: 0 auto;
      text-align: center; }
    .custom-modal .modal-content .btn-container .btn {
      color: #FFFFFF !important;
      background: #F58266;
      border-radius: 30px;
      margin: 10px 0px;
      padding: 16px 0px;
      text-align: center;
      font-size: 18px;
      line-height: 24px; }
    .custom-modal .modal-content .btn-container.d-none {
      display: none !important; }
