#section-download-global {
  margin: 0 auto;
  width: 100%;
  max-width: 1050px; }
  #section-download-global .title-client {
    color: #586B94; }
  #section-download-global .title-pro {
    color: #CFDEFF; }
  #section-download-global .copy-color-client {
    color: #5F6368; }
  #section-download-global .copy-color-pro {
    color: #FFFFFF; }
  #section-download-global .title-mobile {
    display: none;
    font-family: "Poppins-SemiBold", sans-serif;
    font-size: 28px;
    line-height: 36px;
    text-align: center;
    margin-bottom: 40px; }
  #section-download-global .dowload-image-column {
    display: inline-block;
    max-width: 260px;
    padding-left: 40px; }
    #section-download-global .dowload-image-column img {
      background-size: contain;
      background-repeat: no-repeat;
      width: 260px !important;
      height: 523px;
      display: block; }
  #section-download-global .dowload-form-column {
    width: 565px;
    display: inline-block;
    float: right;
    margin-top: 90px;
    text-align: left;
    padding-right: 40px; }
    #section-download-global .dowload-form-column .download-the-app {
      font-family: "Poppins-SemiBold", sans-serif;
      font-size: 45px;
      line-height: 48px; }
    #section-download-global .dowload-form-column .download-copy {
      font-family: "Poppins-Light", sans-serif;
      font-size: 18px;
      line-height: 28px;
      margin: 20px 0; }
    #section-download-global .dowload-form-column .copy-title {
      font-family: "Poppins-Medium", sans-serif !important;
      font-size: 20px; }
    #section-download-global .dowload-form-column .phone-number-disclaimer {
      font-family: "Poppins-Regular", sans-serif;
      font-size: 9px;
      line-height: 14px;
      margin-top: 10px; }
    #section-download-global .dowload-form-column .download-icon-container {
      margin-top: 50px; }
      #section-download-global .dowload-form-column .download-icon-container a:hover {
        text-decoration: none; }
      #section-download-global .dowload-form-column .download-icon-container .apple {
        background-image: url("./../Images/download_app_apple.png");
        background-size: cover;
        width: 170px;
        height: 56px;
        display: inline-block; }
      #section-download-global .dowload-form-column .download-icon-container .google {
        background-image: url("./../Images/download_app_google.png");
        background-size: cover;
        width: 170px;
        height: 58px;
        display: inline-block;
        margin-left: 20px; }
  @media (max-width: 1050px) {
    #section-download-global {
      max-width: 80%; }
      #section-download-global .title-mobile {
        display: block; }
      #section-download-global .dowload-image-column {
        display: block;
        margin: 0 auto;
        max-width: 222px;
        padding: 0; }
        #section-download-global .dowload-image-column img {
          max-width: 222px;
          width: 100%;
          height: 442px;
          margin: 0 auto;
          display: block; }
      #section-download-global .dowload-form-column {
        margin: 0 auto;
        max-width: 300px;
        display: block;
        float: none;
        text-align: center;
        padding: 0; }
        #section-download-global .dowload-form-column .download-the-app {
          display: none; }
        #section-download-global .dowload-form-column .download-copy {
          font-family: "Poppins-Regular", sans-serif;
          font-size: 12px;
          line-height: 18px;
          margin: 25px 0; }
        #section-download-global .dowload-form-column .phone-number-disclaimer {
          font-family: "Poppins-Regular", sans-serif;
          font-size: 8px;
          line-height: 14px; }
        #section-download-global .dowload-form-column .download-icon-container {
          margin-top: 25px; }
          #section-download-global .dowload-form-column .download-icon-container .apple {
            display: block;
            margin: 0 auto; }
          #section-download-global .dowload-form-column .download-icon-container .google {
            display: block;
            margin: 20px auto 0 auto; } }

.background-color-client {
  background-color: #F9F9F9; }

.background-color-pro {
  background-color: #39455E; }

.section-container {
  padding: 130px 0; }
  @media (max-width: 1280px) {
    .section-container {
      padding: 60px 0; } }

.slick-prev:before {
  content: '' !important; }

.slick-prev {
  background-image: url("./../Images/arrow-left-light.png") !important;
  height: 30px !important;
  width: 30px !important;
  background-size: contain !important; }

.slick-next:before {
  content: '' !important; }

.slick-next {
  background-image: url("./../Images/arrow-right-light.png") !important;
  height: 30px !important;
  width: 30px !important;
  background-size: contain !important; }
