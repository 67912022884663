.overlay-bg {
  background: #F9F9F9; }

.signupModal.overlay {
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 90000; }
  .signupModal.overlay .box-shadow-container {
    box-shadow: 0px 0px 5px 3px rgba(214, 215, 217, 0.32);
    border-radius: 5px; }
  .signupModal.overlay .form-control {
    box-shadow: 0 !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    color: #2C3239;
    border: 1px solid transparent;
    border-radius: 50px;
    background: #F9F9F9; }
  .signupModal.overlay .signup-container {
    text-align: center;
    margin-top: 20px; }
    .signupModal.overlay .signup-container .btn.active {
      background: #F58266 !important;
      font-family: "Poppins-SemiBold", sans-serif;
      color: #FFFFFF;
      box-shadow: none;
      border-radius: 50px;
      height: 52px;
      max-width: 250px;
      margin: 0 auto;
      text-align: center; }

.signupModal .popup {
  margin: 2% auto;
  background: #FFFFFF;
  max-width: 725px;
  position: relative;
  transition: all 1s ease-in-out; }

.signupModal .popup .header a {
  text-align: center;
  border-radius: 0px;
  margin: 0 !important;
  color: #000 !important;
  background-color: #D9D9D9;
  font-family: "Poppins-SemiBold", sans-serif !important;
  padding: 10px !important;
  font-size: 14px !important; }

.signupModal .popup .header a.active {
  background-color: #FFFFFF !important; }

.signupModal .popup .content {
  clear: both;
  overflow: hidden;
  padding: 50px 0 80px 0;
  max-width: 475px;
  margin: 0 auto; }

.signupModal .popup .content .signup-header {
  font-family: "Poppins-SemiBold", sans-serif;
  font-size: 45px;
  line-height: 55px;
  color: #39455E;
  text-align: center; }

.signupModal .popup .content .signup-subheader {
  font-family: "Poppins-Light", sans-serif;
  font-size: 16px;
  line-height: 25px;
  color: #2B323A;
  text-align: center;
  padding: 30px 0 20px 0; }
  .signupModal .popup .content .signup-subheader a {
    font-family: "Poppins-SemiBold", sans-serif;
    color: #7089BE; }

.signupModal .popup .content .apple-sign-in-container {
  text-align: center;
  margin: 20px auto !important; }
  .signupModal .popup .content .apple-sign-in-container .apple-sign-in {
    font-family: "Poppins-Regular", sans-serif;
    font-size: 16px;
    border-radius: 50px;
    height: 40px;
    width: 310px;
    color: #FFFFFF;
    background-color: #000000;
    border: none; }
    .signupModal .popup .content .apple-sign-in-container .apple-sign-in .apple-logo {
      width: 18px;
      vertical-align: top;
      margin: 2px 5px 0 0; }
    @media (max-width: 1024px) {
      .signupModal .popup .content .apple-sign-in-container .apple-sign-in {
        max-width: 270px; } }

.signupModal .popup .content .signup-form-lable {
  display: block;
  color: #5F6368;
  width: auto;
  font-size: 13px;
  font-family: "Poppins-Medium", sans-serif;
  background: white;
  padding: 0 5px 0 3px;
  -webkit-transition: all 0.5s ease;
  transition: all 0.5s ease;
  pointer-events: none; }

.signupModal .popup .content .marketing-optin {
  font-family: "Poppins-Light", sans-serif;
  padding-left: 20px;
  letter-spacing: .5px;
  max-width: 470px; }

.signupModal .popup .content .optin-text {
  font-family: "Poppins-Light", sans-serif;
  line-height: 25px;
  padding: 0 0 10px 10px;
  max-width: 400px;
  width: 100%; }

.signupModal input {
  font-family: "Poppins-Light", sans-serif;
  font-size: 16px;
  color: #2C3239;
  height: 40px;
  margin: 5px 0; }

.signupModal input:focus {
  border: 1.5px solid #7089BE !important; }

.signupModal .my-facebook-button-class {
  background-color: #304888 !important;
  border-color: #304888 !important;
  border-radius: 50px;
  color: white !important;
  font-size: 16px;
  font-family: "Poppins-Regular", sans-serif;
  height: 40px;
  max-width: 310px;
  text-align: center;
  margin: 0 auto; }
  @media (max-width: 1024px) {
    .signupModal .my-facebook-button-class {
      max-width: 270px; } }

.signupModal .back-btn-container {
  margin-top: 20px;
  text-align: center; }
  .signupModal .back-btn-container .back-btn {
    color: #F58266;
    font-family: "Poppins-SemiBold", sans-serif;
    font-size: 18px;
    line-height: 22px;
    box-shadow: none; }

@media screen and (max-width: 700px) {
  .signupModal .popup {
    width: 95%; } }

@media screen and (max-width: 370px) {
  .signupModal .my-facebook-button-class {
    height: 60px; } }

.signupModal .intl-tel-input {
  width: 100%;
  z-index: 9999999999999; }

.signupModal .invalid {
  border: 1px solid #ff0000; }

.signupModal .form-group {
  margin: 5px 0px; }

.signupModal .terms {
  color: #5D6369;
  font-family: "Poppins-Light", sans-serif;
  font-size: 12px;
  text-align: center;
  width: 100%;
  margin: 30px auto 0 auto;
  max-width: 410px; }

.signupModal .terms p {
  margin-bottom: 0;
  font-family: "Poppins-Light", sans-serif;
  font-size: 12px;
  margin-top: 5px;
  float: left;
  color: #5D6369; }

.signupModal .terms a {
  text-decoration: none;
  font-family: "Poppins-SemiBold", sans-serif;
  color: #7089BE;
  cursor: pointer; }

.signupModal .forgotPassword {
  float: right;
  color: #7089BE;
  text-decoration: none; }

.signupModal .line-wrapper {
  overflow: hidden;
  text-align: center;
  display: block;
  margin: 15px 0px;
  color: #D9D9D9; }

.signupModal .line-wrapper:before,
.signupModal .line-wrapper:after {
  background-color: rgba(112, 137, 190, 0.15);
  content: "";
  display: inline-block;
  height: 1px;
  position: relative;
  vertical-align: middle;
  width: 140px; }

.signupModal .line-wrapper:before {
  right: 0.5em;
  margin-left: -50%; }

.signupModal .line-wrapper:after {
  left: 0.5em;
  margin-right: -50%; }

.small-red-message {
  font-size: 14px;
  line-height: 20px;
  color: #C11500; }

.mb-16 {
  margin-bottom: 16px; }
