@import '../../../Assets/variables';

.message-row {
    margin-bottom: 16px;

    >.message-content {
        display: grid;

        >img {
            border-radius: 100%;
            grid-row: span 2;
            width: 48px;
            height: 48px;
        }

        >.message-time {
            font-size: 10px;
            line-height: 20px;
            color: $gray-500;
            justify-self: end;
        }

        >.message-text {
            padding: 16px;
            font-size: 14px;
            line-height: 20px;
            margin-bottom: 4px;
        }
    }

    &.you-message {
        justify-content: end;

        >.message-content {
            justify-items: end;

            >.message-text {
                background: $deep-blue;
                color: $white;
                border-radius: 8px;
            }
        }
    }

    &.other-message {
        justify-items: start;

        >.message-content {
            grid-template-columns: 48px 1fr;
            grid-column-gap: 15px;

            >.message-text {
                background: $gray-50;
                color: $gray-600;
                border-radius: 8px;
            }
        }
    }
    &.you-message.rejected-message, &.other-message.rejected-message {

        >.message-content {

            >.message-text {
                font-size: 12px;
                line-height: 20px;
                background: $gray-50;
                color: $gray-400;
                a, a:hover, a:active, a:focus {
                    text-decoration: underline;
                    color: $cactus-flower;
                    cursor: pointer;
                }
            }
        }
    }
}