.creditCardModal.overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(0, 0, 0, 0.7); }
  .creditCardModal.overlay .creditCards {
    overflow: auto; }
  .creditCardModal.overlay .popup {
    margin: 70px auto;
    padding: 20px;
    width: 40%;
    position: relative;
    transition: all 5s ease-in-out;
    border-radius: 3px;
    max-height: calc(100vh - 30px);
    overflow-y: auto; }
    .creditCardModal.overlay .popup h2 {
      margin: 5px 5px 15px 5px;
      color: #333;
      text-align: center;
      font-size: 17px;
      font-family: "Poppins-SemiBold", sans-serif; }
    .creditCardModal.overlay .popup .contentform input {
      margin: 10px 0; }
    .creditCardModal.overlay .popup .content {
      max-height: 30%;
      overflow: auto;
      padding: 0 10px; }
      .creditCardModal.overlay .popup .content .input-name-margin-bottom input {
        margin-bottom: 20px; }
      .creditCardModal.overlay .popup .content input {
        margin: 10px 0;
        font-family: "Poppins-Medium", sans-serif; }
    .creditCardModal.overlay .popup .form-pod .form-pod-split input, .creditCardModal.overlay .popup .creditCardModal .form-pod-large input {
      font-family: "Poppins-Medium", sans-serif; }
    .creditCardModal.overlay .popup .form-pod .form-pod-split {
      display: inline-block;
      width: 49%; }
    .creditCardModal.overlay .popup .form-pod #input-margin-right {
      margin-right: 9px; }

.stripe-elements-label {
  float: none !important; }

.stripe-cardnumber-element, .stripe-expiration-element, .stripe-cvc-element {
  padding: 8px 12px;
  height: 34px;
  color: #555;
  background-color: #FFFFFF;
  background-image: none;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-shadow: inset 0 1px 1px rgba(0, 0, 0, 0.075); }

.stripe-cvc-element {
  display: inline-block !important;
  min-width: 105px !important; }

.stripe-elements-cc-icon-layout {
  display: inline-block !important;
  position: relative !important;
  right: 5px !important; }

@media screen and (max-width: 700px) {
  .creditCardModal .popup {
    width: 90% !important; } }

.modal-cancel-button-custom {
  margin-top: 24px;
  padding: 16px 20px;
  border-radius: 30px;
  font-size: 18px;
  line-height: 24px;
  color: #39455E;
  font-family: "Poppins-Medium", sans-serif;
  height: 52px;
  width: 100%; }
