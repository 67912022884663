.booking-main-background {
  position: fixed;
  top: 0px;
  bottom: 0;
  left: 0;
  right: 0;
  background-repeat: no-repeat;
  background-size: cover;
  z-index: -1;
  overflow: scroll;
  width: 100%; }

.mt-big {
  margin-top: 80px;
  margin-bottom: 0px; }
.mt-big div:has(.MuiLinearProgress-root) {
  z-index: 1100;
}

.w-100 {
  width: 100%;
  object-fit: cover;
  height: 100%; }

.filter-05 {
  filter: brightness(0.5); }

.gray-overlay {
  box-shadow: inset 0 0 0 2000px rgba(0, 0, 0, 0.15); }

@media (max-width: 800px) {
  .mt-big {
    margin-top: 60px; }
  .booking-main-background {
    box-shadow: none;
    background-color: #F5F5F5;
    background-image: none !important; } }
