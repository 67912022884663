.br-btm-gray {
  border-bottom: 4px solid rgba(0, 0, 0, 0.08); }

.br-btm-gray-thin {
  border-bottom: 1px solid rgba(0, 0, 0, 0.08); }

.second-title-style {
  font-size: 24px;
  line-height: 32px;
  color: #000000;
  padding: 24px 0px 8px 0px; }

.third-title-style {
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  padding: 12px 0px; }

.ptb-16 {
  padding: 16px 0px; }

.addons-card {
  border: 1px solid #DEDEDE;
  padding: 16px 12px;
  font-size: 12px;
  line-height: 16px;
  color: #000000;
  cursor: pointer;
  border-radius: 8px;
  margin-right: 16px; }

.w-h-48 {
  width: 48px;
  height: 48px; }

.border-radius-4 {
  border-radius: 4px; }

.selection-overlay {
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0px;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  cursor: pointer; }
  .selection-overlay img {
    max-width: 50%; }

.mimic-modal-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  top: 0px;
  left: 0px;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  justify-content: center;
  -webkit-justify-content: center;
  z-index: 9999999999999;
  overflow-y: scroll;
  padding-top: 60px; }

.mimic-modal-content {
  margin: auto;
  width: 85%;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);
  border-radius: 16px;
  overflow-y: scroll;
  max-height: calc(90vh);
  background-color: #FFF; }

.fixed-white-bg {
  position: absolute !important;
  bottom: 0 !important;
  background-color: #FFF !important; }

.MuiList-root .MuiTypography-root, .MuiList-root .MuiSvgIcon-root {
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  font-family: "Poppins-Medium", sans-serif; }
  .MuiList-root .MuiTypography-root svg, .MuiList-root .MuiSvgIcon-root svg {
    font-family: "Poppins-Medium", sans-serif; }

.MuiList-root .MuiCollapse-wrapperInner {
  font-size: 12px;
  line-height: 20px;
  color: #757575;
  font-family: "Poppins-Regular", sans-serif;
  margin: 12px 16px; }
  .MuiList-root .MuiCollapse-wrapperInner svg {
    font-family: "Poppins-Medium", sans-serif; }

.color-darker-gray {
  color: #757575; }

.overflow-wrap-break-word {
  overflow-wrap: break-word; }

@media (max-width: 1100px) {
  .mimic-modal-content {
    width: 85%; } }

@media (max-width: 800px) {
  .mimic-modal-content {
    width: 100%; } }
