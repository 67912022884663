.latest-design.modal-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.08);
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  overflow-y: scroll;
  padding-top: 64px;
  backdrop-filter: blur(2.5px); }

.latest-design .modal-content {
  margin: auto;
  background: #FFFFFF;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);
  border-radius: 16px;
  padding: 24px;
  overflow-y: scroll;
  width: 480px; }
.latest-design .modal-content .cta-secondary {
  background-color: #FFFFFF; }
  
.back-arrow-icon,
.back-arrow-icon:hover,
.back-arrow-icon:active,
.back-arrow-icon:focus {
  cursor: pointer;
  color: #000000;
  margin-bottom: 16px; }

.modal-title {
  font-size: 28px;
  line-height: 36px;
  color: #000000;
  font-family: "Poppins-Medium", sans-serif;
  margin-bottom: 16px; }

.modal-subtitle {
  font-size: 16px;
  line-height: 24px;
  color: #000000;
  margin-bottom: 16px; }

.modal-apply-link,
.modal-apply-link:hover,
.modal-apply-link:active,
.modal-apply-link:focus {
  padding: 16px 20px;
  font-size: 18px;
  line-height: 24px;
  color: #FFFFFF;
  background: #F58266;
  border-radius: 30px; }

.modal-apply-link.disabled {
  background: #F6F6F6;
  color: #AFAFAF; }

.modal-cancel-link,
.modal-cancel-link:hover,
.modal-cancel-link:active,
.modal-cancel-link:focus {
  margin-right: 16px;
  padding: 16px 20px;
  font-size: 18px;
  line-height: 24px;
  color: #39455E;
  cursor: pointer; }

.txt-align-right {
  text-align: right; }

.margin-top-40 {
  margin-top: 40px; }

@media (max-width: 800px) {
  .latest-design .modal-wrapper {
    padding-top: 58px;
    background: #FFFFFF; }
  .latest-design .modal-content {
    border-radius: 0px;
    height: 100%;
    max-height: 100%; }
  .latest-design .modal-content-exception {
    border-radius: 16px!important;
    height: auto!important;
    position: fixed;
    bottom: 0px;
    left: 0;
    right: 0;
    width: 100%; }
  .latest-design .modal-title {
    font-size: 24px;
    line-height: 32px; }
  .latest-design .atcb-list.atcb-modal {
    box-shadow: none; }
  .latest-design .sm-fix {
    position: fixed;
    bottom: 0px;
    left: 0px;
    right: 0px;
    background-color: #FFF;
    padding: 24px; }
  .latest-design .sm-fix-exception {
    position: relative;
    padding: 0px;}
  .latest-design .sm-margin-50 {
    margin-bottom: 50px; }
  .latest-design .sm-btn-display {
    margin-bottom: 8px;
    display: block;
    text-align: center; } }
