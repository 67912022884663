.corona-header-container {
  background: #F3F3F5;
  min-height: 400px;
  padding: 80px 0; }

.corona-header {
  max-width: 800px;
  margin: 0 auto;
  vertical-align: middle;
  color: #39455E; }
  .corona-header .corona-title {
    font-family: "Poppins-Medium", sans-serif;
    font-size: 56px;
    line-height: 66px;
    margin-bottom: 50px; }
  .corona-header p {
    font-family: "Poppins-Regular", sans-serif;
    font-size: 18px;
    line-height: 28px; }

.section-corona {
  max-width: 800px;
  margin: 70px auto;
  font-family: "Poppins-Light", sans-serif;
  font-size: 18px;
  line-height: 28px;
  color: #5F6368; }

.section-corona .copy-block {
  margin: 35px 0; }

@media (max-width: 800px) {
  .corona-header-container {
    padding: 70px 40px; }
  .corona-header-container .corona-header .corona-title {
    margin-bottom: 30px;
    font-size: 40px; }
  .section-corona {
    padding: 0 30px;
    margin: 35px auto; } }

@media (max-width: 500px) {
  .corona-header-container {
    padding: 50px 30px; }
  .corona-header-container .corona-header .corona-title {
    margin-bottom: 25px;
    font-size: 26px; } }
