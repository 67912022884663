.ptrb-16 {
  padding: 16px 16px 16px 0px; }

.header-style {
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  margin-bottom: 4px;
  padding: 0px; }

.item-style {
  font-size: 14px;
  line-height: 20px;
  color: #545454;
  font-family: "Poppins-Regular", sans-serif; }

.txt-end {
  text-align: end; }
