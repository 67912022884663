.medium-font {
  font-family: "Poppins-Medium", sans-serif; }

.size-20-28 {
  font-size: 20px;
  line-height: 28px; }

.size-16-24 {
  font-size: 16px;
  line-height: 24px; }

.color-black {
  color: #000; }

.p-16 {
  padding: 16px; }

.p-32 {
  padding: 32px; }

.mb-16 {
  margin-bottom: 16px; }

.mb-32 {
  margin-bottom: 32px; }

.mtb-30 {
  margin-top: 30px;
  margin-bottom: 30px; }

.mt-32 {
  margin-top: 32px; }

.forced-modal-wrapper {
  position: fixed;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
  top: 0px;
  left: 0px;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999999999999;
  overflow-y: scroll;
  padding-top: 60px; }

.forced-modal-content {
  margin: auto;
  width: 60%;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12);
  border-radius: 16px;
  overflow-y: scroll;
  max-height: calc(90vh);
  background-color: #FFF; }

.forced-cta-btn-wrapper {
  margin: auto;
  width: 343px; }

.forced-cta-btn,
.forced-cta-btn:focus,
.forced-cta-btn:active,
.forced-cta-btn:hover {
  width: 100%;
  height: 56px;
  padding: 16px 0px;
  border-radius: 30px;
  font-size: 18px;
  line-height: 24px;
  font-family: "Poppins-Medium", sans-serif;
  background: #F58266;
  color: #FFFFFF;
  margin: auto; }

.forced-cta-btn:disabled {
  color: #AFAFAF;
  background: #F6F6F6; }

.forced-fixed-cta-wrapper {
  bottom: 0;
  background-color: #FFF;
  border-top: 1px solid #E2E2E2;
  width: 100%; }

.forced-modal-wrapper .MuiList-root .MuiTypography-root,
.MuiList-root .MuiSvgIcon-root {
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  font-family: "Poppins-Medium", sans-serif; }
  .forced-modal-wrapper .MuiList-root .MuiTypography-root svg,
  .MuiList-root .MuiSvgIcon-root svg {
    font-family: "Poppins-Medium", sans-serif; }

.forced-modal-wrapper .MuiList-root .MuiCollapse-wrapperInner {
  font-size: 12px;
  line-height: 20px;
  color: #757575;
  font-family: "Poppins-Regular", sans-serif;
  margin: 12px 16px; }
  .forced-modal-wrapper .MuiList-root .MuiCollapse-wrapperInner svg {
    font-family: "Poppins-Medium", sans-serif; }

.p-relative {
  position: relative; }

.check-box-wrapper {
  text-align: center;
  margin: auto;
  width: 343px; }

.check-box {
  width: 5%;
  display: inline-block;
  vertical-align: top; }

.check-box-copy {
  width: 95%;
  display: inline-block;
  text-align: left;
  padding-left: 5px; }

.btn-container {
  display: flex !important;
  justify-content: center !important; }
  .btn-container .btn {
    width: 300px; }

@media (max-width: 800px) {
  .forced-modal-content {
    width: 100%; }
  .forced-cta-btn-wrapper {
    width: 100%; }
  .forced-fixed-cta-wrapper {
    position: fixed; }
  .sm-mb-190 {
    margin-bottom: 190px !important; } }
