@font-face {
  font-family: 'gotham-htf-black';
  src: url(./Assets/Fonts/Gotham_HTF_family/GothamHTF-Black.otf) format("opentype"); }

@font-face {
  font-family: 'gotham-htf-black-italic';
  src: url(./Assets/Fonts/Gotham_HTF_family/GothamHTF-BlackItalic.otf) format("opentype"); }

@font-face {
  font-family: 'gotham-htf-bold';
  src: url(./Assets/Fonts/Gotham_HTF_family/GothamHTF-Bold.otf) format("opentype"); }

@font-face {
  font-family: 'gotham-htf-bold-condensed';
  src: url(./Assets/Fonts/Gotham_HTF_family/GothamHTF-BoldCondensed.otf) format("opentype"); }

@font-face {
  font-family: 'gotham-htf-bold-italic';
  src: url(./Assets/Fonts/Gotham_HTF_family/GothamHTF-BoldItalic.otf) format("opentype"); }

@font-face {
  font-family: 'gotham-htf-book';
  src: url(./Assets/Fonts/Gotham_HTF_family/GothamHTF-Book.otf) format("opentype"); }

@font-face {
  font-family: 'gotham-htf-book-condensed';
  src: url(./Assets/Fonts/Gotham_HTF_family/GothamHTF-BookCondensed.otf) format("opentype"); }

@font-face {
  font-family: 'gotham-htf-book-italic';
  src: url(./Assets/Fonts/Gotham_HTF_family/GothamHTF-BookItalic.otf) format("opentype"); }

@font-face {
  font-family: 'gotham-htf-light';
  src: url(./Assets/Fonts/Gotham_HTF_family/GothamHTF-Light.otf) format("opentype"); }

@font-face {
  font-family: 'gotham-htf-light-condensed';
  src: url(./Assets/Fonts/Gotham_HTF_family/GothamHTF-LightCondensed.otf) format("opentype"); }

@font-face {
  font-family: 'gotham-htf-light-italic';
  src: url(./Assets/Fonts/Gotham_HTF_family/GothamHTF-LightItalic.otf) format("opentype"); }

@font-face {
  font-family: 'gotham-htf-medium';
  src: url(./Assets/Fonts/Gotham_HTF_family/GothamHTF-Medium.otf) format("opentype"); }

@font-face {
  font-family: 'gotham-htf-thin';
  src: url(./Assets/Fonts/Gotham_HTF_family/GothamHTF-Thin.otf) format("opentype"); }

@font-face {
  font-family: 'gotham-htf-thin-italic';
  src: url(./Assets/Fonts/Gotham_HTF_family/GothamHTF-ThinItalic.otf) format("opentype"); }

@font-face {
  font-family: 'gotham-htf-ultra';
  src: url(./Assets/Fonts/Gotham_HTF_family/GothamHTF-Ultra.otf) format("opentype"); }

@font-face {
  font-family: 'gotham-htf-ultra-italic';
  src: url(./Assets/Fonts/Gotham_HTF_family/GothamHTF-UltraItalic.otf) format("opentype"); }

@font-face {
  font-family: 'gotham-htf-xlight';
  src: url(./Assets/Fonts/Gotham_HTF_family/GothamHTF-XLight.otf) format("opentype"); }

@font-face {
  font-family: 'gotham-htf-xlight-italic';
  src: url(./Assets/Fonts/Gotham_HTF_family/GothamHTF-XLightItalic.otf) format("opentype"); }

@font-face {
  font-family: 'Poppins-Bold';
  src: url("./Assets/Fonts/Poppins_Family/Poppins-Bold.ttf") format("truetype"); }

@font-face {
  font-family: 'Poppins-Light';
  src: url("./Assets/Fonts/Poppins_Family/Poppins-Light.ttf") format("truetype"); }

@font-face {
  font-family: 'Poppins-Medium';
  src: url("./Assets/Fonts/Poppins_Family/Poppins-Medium.ttf") format("truetype"); }

@font-face {
  font-family: 'Poppins-Regular';
  src: url("./Assets/Fonts/Poppins_Family/Poppins-Regular.ttf") format("truetype"); }

@font-face {
  font-family: 'Poppins-SemiBold';
  src: url("./Assets/Fonts/Poppins_Family/Poppins-SemiBold.ttf") format("truetype"); }

body {
  margin: 0;
  padding: 0;
  font-family: 'Poppins-Regular';
  overflow-wrap: break-word; }
  body .back-arrow-img {
    width: 8px;
    height: 12px;
    margin-bottom: 2.5px;
    margin-right: 3px; }
  body div:focus,
  body textarea:hover,
  body input:hover,
  body textarea:active,
  body input:active,
  body textarea:focus,
  body input:focus,
  body button:focus,
  body button:active,
  body button:hover,
  body label:focus,
  body .btn:active,
  body .btn.active {
    outline: 0px !important;
    box-shadow: none !important; }
  body .select__indicator-separator {
    display: none !important; }
  body .select__option:hover {
    background-color: rgba(0, 0, 0, 0.035) !important; }
  body .select__option--is-selected,
  body .select__option--is-selected:hover {
    background-color: rgba(0, 0, 0, 0.08) !important;
    color: black !important; }
  body .select__control {
    background: #EEEEEE !important;
    border-color: transparent !important; }
  body .select__control.select__control--menu-is-open,
  body .select__control.select__control--is-focused {
    border-color: #586B94 !important;
    box-shadow: 0 0 0 1px #586B94 !important; }
  body .select__menu-list {
    max-height: 150px; }

.pac-container,
.address-container {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 8px;
  margin-top: 11px;
  box-shadow: none;
  max-height: 30vh;
  overflow-y: scroll;
  background-color: #fff;
  z-index: 9999; }

.address-container::-webkit-scrollbar {
  width: 4.5px; }

.address-container::-webkit-scrollbar-track {
  background-color: #9993 !important;
  border-radius: 0px 8px 8px 0px;
  margin: 5px 0px; }

.address-container::-webkit-scrollbar-thumb {
  background-color: #586B94 !important;
  cursor: pointer; }

.pac-container:after {
  background-image: none !important;
  height: 0px; }

.pac-icon,
.address-icon {
  background-image: url("./Assets/Images/places_input_marker.png") !important;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 31px;
  margin: 0px; }

.pac-item-selected .pac-icon,
.address-item .address-icon {
  background-image: url("./Assets/Images/selected_place_input_marker.png") !important;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 31px;
  margin: 0px; }

.pac-item {
  padding: 16px 25px;
  position: relative;
  border-top: none; }

.pac-item-query {
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  padding-left: 40px; }

.pac-item-query + span {
  display: block;
  padding-left: 40px;
  font-size: 14px;
  line-height: 20px;
  color: #545454; }
