@media (max-width: 600px) {
  #payments {
    padding: 0 5px; } }

#payments .form-control {
  box-shadow: 0 !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important; }

#payments .step-four-container {
  margin: 0 auto;
  max-width: 1280px;
  min-height: calc(100vh - 400px); }
  #payments .step-four-container .credit-cards {
    max-width: 626px;
    width: 100%;
    margin: 50px auto;
    display: inline-block;
    background: white;
    border-radius: 3px; }
    #payments .step-four-container .credit-cards .hero-container {
      background-size: contain;
      background-repeat: no-repeat;
      max-width: 630px;
      padding-bottom: 40px; }
      @media (max-width: 800px) {
        #payments .step-four-container .credit-cards .hero-container {
          margin-top: 70px; } }
      #payments .step-four-container .credit-cards .hero-container .hero-content .summary-title {
        font-family: "Poppins-SemiBold", sans-serif;
        font-size: 45px;
        line-height: 55px;
        color: #FFFFFF;
        padding: 70px 0;
        margin: 0; }
        @media (max-width: 1279px) {
          #payments .step-four-container .credit-cards .hero-container .hero-content .summary-title {
            font-size: 30px; } }
    #payments .step-four-container .credit-cards .title2 {
      text-align: center;
      margin: 60px auto 40px auto;
      font-family: "Poppins-SemiBold", sans-serif;
      font-size: 32px;
      line-height: 30px;
      color: #5F6368; }
      @media (max-width: 1024px) {
        #payments .step-four-container .credit-cards .title2 {
          font-size: 20px; } }
    @media (max-width: 1279px) {
      #payments .step-four-container .credit-cards {
        display: block;
        margin-bottom: 30px; } }
    #payments .step-four-container .credit-cards #client-terms {
      text-align: left; }
      #payments .step-four-container .credit-cards #client-terms a {
        color: #7089BE !important; }
    #payments .step-four-container .credit-cards .terms-btn-container {
      text-align: center;
      margin: 40px 0 20px 0; }
      #payments .step-four-container .credit-cards .terms-btn-container .accept-terms-btn {
        background: #F58266;
        color: #FFFFFF;
        width: 100%;
        padding: 12px 70px;
        border-radius: 50px;
        font-family: "Poppins-SemiBold", sans-serif; }
        @media (max-width: 1024px) {
          #payments .step-four-container .credit-cards .terms-btn-container .accept-terms-btn {
            padding: 10px 60px;
            height: 44px; } }
    #payments .step-four-container .credit-cards .details-wrapper {
      text-align: center;
      margin: 0 auto; }
      #payments .step-four-container .credit-cards .details-wrapper .appt-detail-container {
        background: #FFFFFF;
        margin: 20px auto 10px auto;
        padding: 0 20px 20px 20px;
        max-width: 540px;
        max-height: 320px;
        -webkit-box-shadow: 0px 2px 5px 0px #e8e8e8;
        -moz-box-shadow: 0px 2px 5px 0px #e8e8e8;
        box-shadow: 0px 2px 5px 0px #e8e8e8;
        font-family: "Poppins-Regular", sans-serif;
        line-height: 23px;
        color: #5E6369;
        border-radius: 10px; }
        #payments .step-four-container .credit-cards .details-wrapper .appt-detail-container .edit-address {
          font-family: "Poppins-SemiBold", sans-serif;
          font-size: 13px;
          color: #F58266;
          text-decoration: none; }
        #payments .step-four-container .credit-cards .details-wrapper .appt-detail-container .avatar .a1, #payments .step-four-container .credit-cards .details-wrapper .appt-detail-container .avatar .a2 {
          background-size: cover;
          margin: -20px auto 0 auto;
          height: 40px; }
        #payments .step-four-container .credit-cards .details-wrapper .appt-detail-container .avatar .a1 {
          width: 40px; }
        #payments .step-four-container .credit-cards .details-wrapper .appt-detail-container .avatar .a2 {
          width: 61px; }
        #payments .step-four-container .credit-cards .details-wrapper .appt-detail-container .modality {
          font-size: 16px;
          line-height: 17px;
          color: #2C3239; }
        #payments .step-four-container .credit-cards .details-wrapper .appt-detail-container .mt-gender {
          font-size: 15px;
          line-height: 17px;
          color: #5F6368;
          padding: 10px 0 15px 0; }
        #payments .step-four-container .credit-cards .details-wrapper .appt-detail-container .bottom {
          padding-top: 10px; }
        #payments .step-four-container .credit-cards .details-wrapper .appt-detail-container .left {
          height: 100%;
          text-align: left;
          vertical-align: top; }
        #payments .step-four-container .credit-cards .details-wrapper .appt-detail-container .right {
          height: 100%;
          text-align: left;
          vertical-align: top; }
        #payments .step-four-container .credit-cards .details-wrapper .appt-detail-container .icon-container {
          vertical-align: top;
          width: 25px;
          margin-top: 3px; }
          #payments .step-four-container .credit-cards .details-wrapper .appt-detail-container .icon-container .icon-1 {
            width: 15px;
            height: 15px; }
          #payments .step-four-container .credit-cards .details-wrapper .appt-detail-container .icon-container .icon-2 {
            width: 11px;
            height: 15px; }
          #payments .step-four-container .credit-cards .details-wrapper .appt-detail-container .icon-container .icon-3 {
            width: 13px;
            height: 15px; }
          #payments .step-four-container .credit-cards .details-wrapper .appt-detail-container .icon-container .icon-4 {
            width: 15px;
            height: 15px; }
        #payments .step-four-container .credit-cards .details-wrapper .appt-detail-container .icon-container, #payments .step-four-container .credit-cards .details-wrapper .appt-detail-container .copy-container {
          display: inline-block; }
        @media (max-width: 550px) {
          #payments .step-four-container .credit-cards .details-wrapper .appt-detail-container {
            padding: 20px; }
            #payments .step-four-container .credit-cards .details-wrapper .appt-detail-container .bottom {
              padding-top: 0; }
            #payments .step-four-container .credit-cards .details-wrapper .appt-detail-container .right, #payments .step-four-container .credit-cards .details-wrapper .appt-detail-container .left {
              width: 100%;
              margin: 0 auto;
              padding: 5px 0; } }
    #payments .step-four-container .credit-cards .credit-card-select {
      display: flex;
      max-width: 470px;
      margin: 30px auto 10px auto;
      padding: 20px; }
      #payments .step-four-container .credit-cards .credit-card-select .icon {
        background-size: 16px 12px !important;
        background-repeat: no-repeat !important;
        margin-top: 3px;
        height: 12px;
        width: 20px; }
      #payments .step-four-container .credit-cards .credit-card-select .detail {
        padding-left: 15px;
        color: #2C3239;
        font-family: "Poppins-Regular", sans-serif;
        font-size: 16px;
        width: 100%;
        max-width: 400px; }
      #payments .step-four-container .credit-cards .credit-card-select .edit-card {
        color: #7089BE;
        padding-top: 10px; }
        #payments .step-four-container .credit-cards .credit-card-select .edit-card .edit-icon {
          height: 20px;
          width: 20px; }
        #payments .step-four-container .credit-cards .credit-card-select .edit-card .edit-icon:focus {
          outline: none; }
    #payments .step-four-container .credit-cards .credit-card-add {
      max-width: 470px;
      margin: 0 auto; }
    #payments .step-four-container .credit-cards .dotted-border {
      background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='rgba(95, 99, 104, 0.3)' stroke-width='2' stroke-dasharray='6%2c 9' stroke-dashoffset='14' stroke-linecap='round'/%3e%3c/svg%3e");
      border-radius: 5px;
      height: 85px;
      max-width: 470px;
      line-height: 85px;
      text-align: center;
      color: #7089BE;
      font-family: "Poppins-Medium", sans-serif;
      font-size: 16px; }
      #payments .step-four-container .credit-cards .dotted-border .plus-icon {
        height: 20px;
        width: 20px; }
    #payments .step-four-container .credit-cards .back-btn-container {
      text-align: center; }
      #payments .step-four-container .credit-cards .back-btn-container .back-btn {
        font-family: "Poppins-SemiBold", sans-serif;
        font-size: 18px;
        line-height: 22px;
        color: #F58266; }
        @media (max-width: 1024px) {
          #payments .step-four-container .credit-cards .back-btn-container .back-btn {
            font-size: 16px; } }
    #payments .step-four-container .credit-cards h3 {
      text-align: center;
      font-family: "Poppins-SemiBold", sans-serif;
      font-size: 45px;
      line-height: 55px;
      color: #39455E;
      margin-bottom: 30px; }
      @media (max-width: 1280px) {
        #payments .step-four-container .credit-cards h3 {
          padding: 35px 0;
          margin-bottom: 0px; } }
      @media (max-width: 800px) {
        #payments .step-four-container .credit-cards h3 {
          margin-top: 40px; } }
    #payments .step-four-container .credit-cards .content-add-card {
      padding: 0 55px; }
      #payments .step-four-container .credit-cards .content-add-card .stripe-cardnumber-element, #payments .step-four-container .credit-cards .content-add-card .stripe-expiration-element, #payments .step-four-container .credit-cards .content-add-card .stripe-cvc-element, #payments .step-four-container .credit-cards .content-add-card input {
        box-shadow: 0 !important;
        -webkit-box-shadow: none !important;
        -moz-box-shadow: none !important;
        color: #2C3239 !important;
        font-family: "Poppins-Light", sans-serif !important;
        height: 44px !important;
        border-radius: 50px !important;
        border: 1px solid transparent !important;
        background: #F9F9F9 !important;
        line-height: 26px !important; }
      #payments .step-four-container .credit-cards .content-add-card input {
        box-shadow: 0;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        color: #2C3239;
        font-family: "Poppins-Light", sans-serif;
        height: 44px;
        border-radius: 50px;
        border: 1px solid transparent;
        background: #F9F9F9;
        line-height: 26px; }
      #payments .step-four-container .credit-cards .content-add-card label {
        clear: both;
        float: left;
        width: 100%;
        color: #5f6368;
        font-family: "Poppins-Medium", sans-serif;
        font-size: 13px;
        line-height: 21px;
        margin: 15px 0 10px; }
      @media (max-width: 485px) {
        #payments .step-four-container .credit-cards .content-add-card {
          padding: 0; } }
      #payments .step-four-container .credit-cards .content-add-card .add-card-container {
        text-align: center; }
        #payments .step-four-container .credit-cards .content-add-card .add-card-container .add-credit-card {
          height: 52px !important;
          width: 250px !important;
          background: #F58266 !important;
          color: #FFFFFF !important;
          border-radius: 50px !important;
          font-family: "Poppins-SemiBold", sans-serif !important;
          font-size: 18px !important;
          line-height: 22px !important;
          padding: 16px 0 !important;
          margin: 40px auto 0 auto !important; }
        #payments .step-four-container .credit-cards .content-add-card .add-card-container .add-credit-card:focus {
          border: none; }
      #payments .step-four-container .credit-cards .content-add-card .back {
        background-color: #FFFFFF;
        font-family: "Poppins-SemiBold", sans-serif;
        font-size: 18px;
        line-height: 22px;
        color: #F58266; }
      #payments .step-four-container .credit-cards .content-add-card .btn {
        margin-bottom: 20px; }
    #payments .step-four-container .credit-cards .content {
      padding: 0 10px; }
      #payments .step-four-container .credit-cards .content .selected {
        border: 1px solid #F58266;
        border-radius: 5px; }
      #payments .step-four-container .credit-cards .content .credit-card-wrapper {
        position: relative;
        max-width: 470px;
        height: 85px;
        margin: 0 auto;
        padding: 20px; }
        #payments .step-four-container .credit-cards .content .credit-card-wrapper .radio-container {
          display: inline-block; }
          #payments .step-four-container .credit-cards .content .credit-card-wrapper .radio-container .radio-item {
            display: none !important; }
          #payments .step-four-container .credit-cards .content .credit-card-wrapper .radio-container .label-item {
            margin-top: 0px; }
            #payments .step-four-container .credit-cards .content .credit-card-wrapper .radio-container .label-item img {
              width: 15px;
              height: 10px;
              cursor: pointer; }
          #payments .step-four-container .credit-cards .content .credit-card-wrapper .radio-container .radio-item:checked + label {
            opacity: 1;
            width: 10px;
            height: 15px; }
          #payments .step-four-container .credit-cards .content .credit-card-wrapper .radio-container input[type='radio']:checked {
            position: absolute;
            visibility: hidden; }
          #payments .step-four-container .credit-cards .content .credit-card-wrapper .radio-container input[type='radio']:checked ~ .check::before {
            border: 1px solid #F58266; }
        #payments .step-four-container .credit-cards .content .credit-card-wrapper input[type=radio] {
          margin: auto 5px; }
        #payments .step-four-container .credit-cards .content .credit-card-wrapper .credit-card-content {
          height: 60px;
          display: inline-block;
          margin-left: 25px; }
          @media screen and (max-width: 766px) {
            #payments .step-four-container .credit-cards .content .credit-card-wrapper .credit-card-content {
              height: 80px; } }
          #payments .step-four-container .credit-cards .content .credit-card-wrapper .credit-card-content .cc-icon {
            height: 23px;
            width: 39px;
            display: inline-block;
            vertical-align: top;
            margin-top: 25px;
            margin-right: 20px; }
          #payments .step-four-container .credit-cards .content .credit-card-wrapper .credit-card-content .details-container {
            display: inline-block;
            font-size: 16px; }
            #payments .step-four-container .credit-cards .content .credit-card-wrapper .credit-card-content .details-container .cc-details {
              display: block;
              color: #2C3239;
              font-family: "Poppins-Regular", sans-serif; }
            #payments .step-four-container .credit-cards .content .credit-card-wrapper .credit-card-content .details-container .cc-details-2 {
              color: #5F6368;
              font-family: "Poppins-Light", sans-serif; }
            #payments .step-four-container .credit-cards .content .credit-card-wrapper .credit-card-content .details-container .btn-delete {
              color: #000000;
              text-decoration: underline;
              cursor: pointer; }
  #payments .step-four-container .summary-container {
    max-width: 626px;
    width: 100%;
    margin: 0 auto;
    padding: 40px;
    font-family: "Poppins-Regular", sans-serif;
    display: inline-block;
    float: right;
    background: #FFFFFF;
    max-height: 1060px;
    margin-top: 50px;
    border-radius: 3px; }
    @media (max-width: 1279px) {
      #payments .step-four-container .summary-container {
        margin: 0 auto 50px auto;
        display: block;
        float: none; } }
    @media (max-width: 450px) {
      #payments .step-four-container .summary-container {
        padding: 20px 0px; } }
    #payments .step-four-container .summary-container h3, #payments .step-four-container .summary-container h5 {
      font-family: "Poppins-SemiBold", sans-serif; }
    #payments .step-four-container .summary-container .summary-header {
      padding: 5px;
      display: flex; }
      #payments .step-four-container .summary-container .summary-header h5 {
        font-size: 14px;
        flex: 1;
        margin: 5px; }
      #payments .step-four-container .summary-container .summary-header a {
        margin: 0;
        padding: 5px; }
    #payments .step-four-container .summary-container .bold {
      font-family: "Poppins-SemiBold", sans-serif; }
    #payments .step-four-container .summary-container .wave-top {
      width: 100%;
      background-size: 100%;
      position: absolute;
      bottom: 0;
      height: 100px; }
    #payments .step-four-container .summary-container .detail-wrapper {
      display: flex;
      margin: 5px 0px; }
      #payments .step-four-container .summary-container .detail-wrapper .icon-wrapper {
        height: 30px;
        width: 30px;
        float: left; }
        #payments .step-four-container .summary-container .detail-wrapper .icon-wrapper .icon {
          background-size: 100% !important;
          background-repeat: no-repeat !important;
          height: 30px; }
        #payments .step-four-container .summary-container .detail-wrapper .icon-wrapper svg {
          color: #B9B9B9; }
      #payments .step-four-container .summary-container .detail-wrapper .summary-icon {
        background-size: 100%; }
    #payments .step-four-container .summary-container .gift-wrapper {
      border-bottom: 2px solid #D9D9D9; }

@media (max-width: 800px) {
  #payments {
    background: #FFFFFF; }
    #payments .step-four-container {
      width: 100%;
      max-width: 100% !important; }
      #payments .step-four-container .credit-cards {
        margin: 0 auto;
        width: 100%;
        display: block; }
        #payments .step-four-container .credit-cards .content {
          max-width: 100%; }
      #payments .step-four-container .summary-container {
        margin: 30px auto 50px auto; }
        #payments .step-four-container .summary-container #summary-wrapper {
          max-width: 540px;
          margin: 0 auto; } }

.payments-container-legacy {
  background: #F9F9F9; }
  @media (max-width: 800px) {
    .payments-container-legacy {
      background: #FFFFFF; } }
  .payments-container-legacy #cc {
    float: left;
    width: 110px;
    margin-right: 10px; }
  .payments-container-legacy .cc_icon_ccv {
    width: 37px;
    height: 22px;
    float: left;
    margin-top: 10px;
    left: 160px; }
    @media (max-width: 525px) {
      .payments-container-legacy .cc_icon_ccv {
        display: none !important; } }
  .payments-container-legacy .detail {
    clear: both; }

#soothe-plus-banner-container {
  padding: 20px 0;
  background: #7089BE;
  font-family: "Poppins-Regular", sans-serif; }
  #soothe-plus-banner-container .banner {
    max-width: 500px;
    margin: 0px auto;
    color: white;
    text-align: center; }
    #soothe-plus-banner-container .banner a {
      color: #FFFFFF;
      text-decoration: underline;
      cursor: pointer; }

.dark-card-title {
  font-size: 16px;
  line-height: 17px;
  color: #2C3239;
  padding-bottom: 10px; }

.call-to-action-container {
  margin: 0 auto;
  text-align: center; }

.call-to-action-container .continue-btn {
  margin: 0px;
  background: #F58266;
  color: white;
  border-radius: 50px;
  font-family: "Poppins-SemiBold", sans-serif;
  font-size: 18px;
  line-height: 22px;
  padding: 16px 0;
  text-decoration: none;
  text-transform: capitalize;
  border: none; }

.call-to-action-container .continue-btn:focus {
  outline: none; }

.bg-none {
  background: none !important; }

.box-shadow-none {
  box-shadow: none !important; }

.padding-0 {
  padding: 0px !important; }

.width-100 {
  width: 100%; }

.poppins-regular {
  font-family: "Poppins-Regular", sans-serif; }

.poppins-medium {
  font-family: "Poppins-Medium", sans-serif; }

.add-edit-btn {
  background: #F1F7FF;
  border-radius: 999px;
  display: inline-flex;
  align-items: center;
  color: #39455E;
  font-size: 14px;
  line-height: 16px; }

.black-card-title {
  color: black;
  font-size: 16px;
  line-height: 20px; }

.gray-card-text {
  color: #545454;
  font-size: 14px;
  line-height: 20px; }

.card-icon {
  width: 30px; }

.padding-16 {
  padding: 16px; }

.medium-card-style {
  background: #FFFFFF;
  box-shadow: 1px 4px 4px 1px rgba(99, 98, 119, 0.1);
  border-radius: 8px;
  margin: 12px 37px;
  display: flex;
  align-items: center;
  text-align: left; }

.flex-center {
  display: flex;
  justify-content: center; }

.flex-end {
  display: flex;
  justify-content: flex-end; }

.margin-2-0 {
  margin: 2px 0px; }

.padding-90-0 {
  padding: 90px 0px !important; }

.tip-card-style {
  background: #FFFFFF;
  box-shadow: 0px 4px 4px 1px rgba(99, 98, 119, 0.1);
  border-radius: 8px;
  padding: 16px; }

.margin-bottom-24 {
  margin-bottom: 24px; }

.margin-bottom-22 {
  margin-bottom: 22px; }

.margin-bottom-14 {
  margin-bottom: 14px; }

.tip-options-section {
  justify-content: center;
  align-items: center;
  width: 100%;
  display: inline-flex;
  font-size: 12px;
  line-height: 16px;
  color: black; }

.tip-option {
  background: #EEEEEE;
  margin: auto;
  padding: 10px 12px;
  border-radius: 4px;
  width: 100%;
  text-align: center;
  cursor: pointer; }

.tip-option:not(:last-child) {
  margin-right: 4px; }

.selected-option {
  background: #586B94;
  color: white; }

.gray-note {
  font-size: 12px;
  line-height: 20px;
  color: #757575; }

.summary-card-style {
  background: #FFFFFF;
  box-shadow: 0px 4px 4px 1px rgba(99, 98, 119, 0.1);
  border-radius: 8px;
  font-size: 14px;
  line-height: 20px;
  color: black;
  padding: 16px; }

.hr-style {
  color: #E0E2E9;
  height: 2px;
  margin: 8px 2px; }

.margin-8-0 {
  margin: 8px 0px; }

.summary-total-style {
  font-size: 16px;
  line-height: 20px; }

.tip-custom-input-group {
  background: #EEEEEE;
  border-radius: 4px;
  margin: 8px 0px;
  color: #757575;
  font-size: 16px;
  line-height: 24px; }

.tip-custom-input {
  background: transparent;
  border: none;
  height: 48px; }

.tip-currency-addon {
  color: black;
  border: none;
  background-color: transparent; }

.custom-tip-label {
  font-size: 16px;
  line-height: 20px;
  margin: 0px;
  font-weight: normal; }

.new-box-shadow {
  box-shadow: 0px 4px 4px 1px rgba(0, 0, 0, 0.1);
  border-radius: 0px 0px 8px 8px; }

.gray-input-style {
  background: #EEEEEE;
  border-radius: 4px;
  margin: 8px 0px;
  color: #757575;
  font-size: 16px;
  line-height: 24px;
  border: none;
  height: 48px; }

@media (max-width: 1024px) {
  .call-to-action-container .continue-btn {
    font-size: 16px !important;
    line-height: 20px !important;
    margin: 0px !important;
    padding: 12px; }
  .medium-card-style {
    margin: 12px 16px; }
  .padding-90-0 {
    padding: 70px 0px !important; }
  .tip-option {
    padding: 10px 2px; } }

.cc_icon_ccv {
  width: 37px;
  height: 22px;
  float: left;
  margin-top: 10px;
  left: 160px; }
  @media (max-width: 525px) {
    .cc_icon_ccv {
      display: none !important; } }
