#soothe-plus .btn.active {
  background: #F58266 !important;
  font-family: "Poppins-SemiBold", sans-serif;
  color: #FFFFFF;
  box-shadow: none; }

#soothe-plus .container-full {
  background-color: #F9F9F9;
  text-align: center; }
  #soothe-plus .container-full .hero {
    height: 650px;
    overflow: hidden;
    -webkit-transition: all 0.3s ease 0s;
    transition: all 0.3s ease 0s;
    width: 100%;
    background-size: cover;
    background-position: bottom !important;
    background-repeat: no-repeat;
    position: absolute;
    background-image: url("../Images/soothe-plus-hero.png");
    background-position: 0 0; }
  #soothe-plus .container-full .details-card {
    border-radius: 4px;
    overflow: hidden;
    position: relative;
    padding: 160px 0px 20px 0;
    color: #FFFFFF; }
    #soothe-plus .container-full .details-card h2 {
      font-family: "Poppins-Medium", sans-serif;
      font-size: 56px;
      line-height: 66px;
      margin-bottom: 10px;
      position: relative; }
    #soothe-plus .container-full .details-card p {
      font-family: "Poppins-Regular", sans-serif;
      font-size: 18px;
      line-height: 28px;
      max-width: 660px;
      margin: 20px auto;
      position: relative; }
    #soothe-plus .container-full .details-card i {
      margin-right: 5px; }
    @media (max-width: 540px) {
      #soothe-plus .container-full .details-card h2 {
        font-family: "Poppins-Medium", sans-serif;
        font-size: 28px;
        line-height: 32px;
        margin-bottom: 10px;
        position: relative; }
      #soothe-plus .container-full .details-card p {
        font-family: "Poppins-Regular", sans-serif;
        font-size: 14px;
        line-height: 22px;
        max-width: 660px;
        margin: 20px auto;
        position: relative; } }
  #soothe-plus .container-full .zip-form-container {
    position: relative; }
  #soothe-plus .container-full .location-pin {
    width: 20px;
    height: 26px;
    margin-right: -35px;
    position: relative; }
  #soothe-plus .container-full .submit-zip-code-btn {
    font-family: "Poppins-SemiBold", sans-serif;
    border: none;
    border-radius: 50px;
    cursor: pointer;
    font-size: 16px;
    line-height: 22px;
    height: 50px;
    background: #F58266;
    color: #FFF;
    max-width: 160px;
    width: 100%;
    margin-left: -60px;
    padding: 15px 0; }
    @media (max-width: 627px) {
      #soothe-plus .container-full .submit-zip-code-btn {
        margin-left: 0;
        margin-top: 20px;
        height: 44px;
        padding: 12px 0; } }
  #soothe-plus .container-full input[type=text] {
    font-family: "Poppins-Regular", sans-serif;
    font-size: 18px;
    line-height: 32px;
    height: 49px;
    max-width: 480px;
    border: none;
    padding: 2px 0 1px 50px;
    cursor: pointer;
    border-radius: 50px;
    width: 100%;
    color: #5F6368; }
    @media (max-width: 540px) {
      #soothe-plus .container-full input[type=text] {
        height: 44px;
        font-size: 12px;
        width: 330px; } }
  #soothe-plus .container-full input[type=text]::placeholder {
    color: rgba(44, 50, 57, 0.35); }

#soothe-plus .zip-form-container {
  position: relative; }

#soothe-plus .memberships {
  text-align: center; }
  @media (max-width: 700px) {
    #soothe-plus .memberships .section .section-inner {
      max-width: none !important;
      width: 90% !important;
      margin: 0 5%; } }
  #soothe-plus .memberships .section {
    padding: 50px 0 40px 0; }
    #soothe-plus .memberships .section .section-inner {
      max-width: 1440px;
      width: 100%;
      margin: 0 auto;
      height: auto;
      display: inline-block;
      font-size: 16px;
      letter-spacing: .5px;
      line-height: 26px;
      margin-top: 100px; }
  #soothe-plus .memberships .section-gray {
    background: #F9F9F9; }
  #soothe-plus .memberships .section-white {
    background: #FFFFFF; }
  #soothe-plus .memberships .details {
    padding-bottom: 50px;
    background: #FFFFFF; }
    #soothe-plus .memberships .details .pricing-h2 {
      color: #39455E;
      font-family: "Poppins-SemiBold", sans-serif;
      font-size: 45px;
      margin-top: 160px; }
    #soothe-plus .memberships .details .lengths {
      margin: 20px auto 80px auto;
      clear: both;
      max-width: 700px;
      width: 100%;
      overflow: hidden; }
      #soothe-plus .memberships .details .lengths p {
        float: left;
        width: 33.33%;
        border: 2px solid #F58266;
        color: #F58266;
        font-family: "Poppins-SemiBold", sans-serif;
        font-size: 30px;
        display: block;
        height: 55px;
        line-height: 55px;
        padding: 0 !important;
        text-align: center;
        text-decoration: none; }
      #soothe-plus .memberships .details .lengths p.first {
        border-top-left-radius: 25px;
        border-bottom-left-radius: 25px;
        cursor: pointer; }
      #soothe-plus .memberships .details .lengths p.last {
        border-top-right-radius: 25px;
        border-bottom-right-radius: 25px;
        cursor: pointer; }
      #soothe-plus .memberships .details .lengths p.active {
        background-color: #F58266;
        color: #FFFFFF; }
    #soothe-plus .memberships .details .card-placeholder-div {
      display: contents; }
    #soothe-plus .memberships .details .cards-mobile {
      margin: 0 auto 50px auto !important;
      max-width: 290px !important; }
      #soothe-plus .memberships .details .cards-mobile .card-mobile-hide {
        display: none !important; }
    #soothe-plus .memberships .details .cards {
      max-width: 1440px;
      width: 100%;
      margin-bottom: 70px; }
      #soothe-plus .memberships .details .cards .four-cards {
        margin: 0 10px !important;
        max-width: 290px !important; }
        @media (max-width: 1279px) {
          #soothe-plus .memberships .details .cards .four-cards {
            margin: 0 auto 30px auto !important; } }
      #soothe-plus .memberships .details .cards .card {
        font-family: "Poppins-Regular", sans-serif;
        color: #000000 !important;
        border: 1px solid #BDC3CA;
        background: #fff;
        padding: 0 0 20px 0;
        border-radius: 5px;
        margin: 0 2%;
        height: 430px;
        max-width: 290px;
        display: inline-block;
        width: 100%;
        vertical-align: top; }
        #soothe-plus .memberships .details .cards .card .gray-divider {
          border-top: 2px solid rgba(74, 94, 115, 0.1);
          margin: 0px auto 30px auto;
          width: 80px; }
        #soothe-plus .memberships .details .cards .card .best-value {
          background-color: #CFDEFF; }
        #soothe-plus .memberships .details .cards .card .st-badge {
          text-align: center;
          color: #39455E;
          font-family: "Poppins-SemiBold", sans-serif;
          font-size: 16px;
          letter-spacing: 1.78px;
          margin: 0;
          padding: 25px 0; }
        #soothe-plus .memberships .details .cards .card .minute-value {
          margin: 10px 0;
          font-family: "Poppins-Regular", sans-serif;
          font-size: 20px;
          line-height: 30px;
          color: #39455E; }
        #soothe-plus .memberships .details .cards .card .minute-padding {
          padding-top: 70px; }
        #soothe-plus .memberships .details .cards .card .value-prop-head {
          margin-top: 10px; }
        #soothe-plus .memberships .details .cards .card .pricing-container {
          height: 80px; }
          #soothe-plus .memberships .details .cards .card .pricing-container h3 {
            margin: 5px auto;
            letter-spacing: 1.7px;
            font-size: 16px;
            font-family: "Poppins-Bold", sans-serif;
            color: #D9664F; }
          #soothe-plus .memberships .details .cards .card .pricing-container h1 {
            margin: 0 auto;
            padding-top: 10px;
            color: #39455E;
            font-family: "Poppins-SemiBold", sans-serif;
            font-size: 40px; }
        #soothe-plus .memberships .details .cards .card a {
          color: #7089BE;
          font-family: "Poppins-SemiBold", sans-serif; }
        #soothe-plus .memberships .details .cards .card p {
          margin: 0 0 10px;
          font-size: 14px; }
        #soothe-plus .memberships .details .cards .card .terms-and-conditions {
          padding: 20px 0 5px 0; }
      @media (max-width: 1279px) {
        #soothe-plus .memberships .details .cards {
          margin-bottom: 40px; }
          #soothe-plus .memberships .details .cards .card {
            height: 500px;
            margin: 0 auto 30px auto;
            max-width: 330px;
            min-width: 290px;
            width: 100%;
            display: block;
            float: none; } }
    #soothe-plus .memberships .details .p-disclaimer {
      text-align: center;
      font-size: 15px;
      font-family: "Poppins-Light", sans-serif;
      line-height: 26px;
      color: #6F7492;
      max-width: 1024px;
      margin: 0 auto; }
      @media (max-width: 540px) {
        #soothe-plus .memberships .details .p-disclaimer {
          font-size: 12px;
          line-height: 20px; } }
    #soothe-plus .memberships .details .in-minutes {
      margin-bottom: 20px;
      font-family: "Poppins-SemiBold", sans-serif;
      font-size: 16px;
      color: #39455E; }
      #soothe-plus .memberships .details .in-minutes p {
        margin: 0 auto; }
      #soothe-plus .memberships .details .in-minutes #update-zip-code {
        color: #7089BE;
        font-size: 16px;
        text-decoration: none; }
        @media (max-width: 540px) {
          #soothe-plus .memberships .details .in-minutes #update-zip-code {
            font-size: 12px; } }
      @media (max-width: 440px) {
        #soothe-plus .memberships .details .in-minutes #annual-value-prop br {
          display: none; } }
      @media (max-width: 540px) {
        #soothe-plus .memberships .details .in-minutes {
          font-size: 12px; } }
    @media (max-width: 540px) {
      #soothe-plus .memberships .details .pricing-h2 {
        font-size: 28px;
        line-height: 30px;
        margin-top: 80px; }
      #soothe-plus .memberships .details .lengths {
        margin: 10px auto 20px auto; }
        #soothe-plus .memberships .details .lengths p {
          font-size: 20px;
          line-height: 50px; }
      #soothe-plus .memberships .details .pricing-container h1 {
        font-size: 28px !important;
        line-height: 30px; }
      #soothe-plus .memberships .details .pricing-container h3 {
        font-size: 16px !important;
        line-height: 30px; }
      #soothe-plus .memberships .details .pricing-container .regular-price-strike-thru {
        font-size: 16px; }
      #soothe-plus .memberships .details .value-prop-head, #soothe-plus .memberships .details .terms-and-conditions {
        font-size: 14px; }
      #soothe-plus .memberships .details .button {
        font-size: 16px; } }
  #soothe-plus .memberships .details-homepage {
    text-align: center;
    padding-top: 40px;
    text-align: center; }
  #soothe-plus .memberships .section-download-app {
    background: #F9F9F9; }
    #soothe-plus .memberships .section-download-app .left,
    #soothe-plus .memberships .section-download-app .right {
      float: left; }
    #soothe-plus .memberships .section-download-app .left {
      width: 75%; }
      #soothe-plus .memberships .section-download-app .left p {
        font-size: 1.5rem;
        letter-spacing: 1px;
        font-family: "Poppins-Regular", sans-serif;
        color: #000000; }
    #soothe-plus .memberships .section-download-app .right {
      width: 25%; }
    #soothe-plus .memberships .section-download-app .linkTextingInner {
      margin-top: 15px; }
    #soothe-plus .memberships .section-download-app .download-icons {
      margin-left: 25px; }
      #soothe-plus .memberships .section-download-app .download-icons .download-app-store,
      #soothe-plus .memberships .section-download-app .download-icons .download-google-store {
        background-size: 120px 40px;
        width: 120px;
        height: 40px;
        margin: 5px 0px; }
    #soothe-plus .memberships .section-download-app .download-icons-mobile {
      display: none; }
  #soothe-plus .memberships .section-questions p {
    font-family: "Poppins-Regular", sans-serif; }
  #soothe-plus .memberships .faq-link {
    display: block;
    text-align: left;
    font-family: "Poppins-SemiBold", sans-serif;
    letter-spacing: 3px;
    font-size: 19px;
    margin-top: 20px;
    margin-bottom: 20px;
    color: #CFDEFF;
    text-align: center; }
  #soothe-plus .memberships .button {
    font-size: 18px;
    padding: 15px 40px;
    font-family: "Poppins-SemiBold", sans-serif;
    background: #F58266;
    color: #FFFFFF !important;
    display: inline-block;
    margin-top: 20px;
    border-radius: 50px !important;
    border: 0; }
  #soothe-plus .memberships .text-align-right {
    text-align: right; }
  #soothe-plus .memberships .text-align-left {
    text-align: left; }
  #soothe-plus .memberships .linkTextingInner input[type=tel]:placeholder-shown {
    box-shadow: none !important;
    border: 1px solid #000000 !important; }
  #soothe-plus .memberships .linkTextingInner button {
    background: #CFDEFF;
    border: 1px solid #CFDEFF;
    color: #FFFFFF; }
  #soothe-plus .memberships .section-how-to-soothe-plus {
    background: #FFFFFF; }
    #soothe-plus .memberships .section-how-to-soothe-plus .how-to-soothe-container {
      width: 700px;
      margin: auto; }
    #soothe-plus .memberships .section-how-to-soothe-plus .how-to-soothe-image {
      width: 200px;
      height: 200px;
      background-size: 100%;
      float: left; }
    #soothe-plus .memberships .section-how-to-soothe-plus .how-to-soothe-right {
      float: left;
      width: 500px;
      padding-left: 30px;
      text-align: left;
      height: 200px; }
    #soothe-plus .memberships .section-how-to-soothe-plus .how-to-soothe-header {
      font-family: "Poppins-SemiBold", sans-serif;
      color: #000000 !important;
      letter-spacing: 0px;
      font-size: 19px;
      margin-top: 16px;
      margin-bottom: 16px; }
      #soothe-plus .memberships .section-how-to-soothe-plus .how-to-soothe-header a {
        font-family: "Poppins-SemiBold", sans-serif;
        color: #CFDEFF; }
    #soothe-plus .memberships .section-how-to-soothe-plus .how-to-soothe-body {
      letter-spacing: .5px;
      line-height: 26px;
      font-size: 16px; }
  @media (max-width: 700px) {
    #soothe-plus .memberships .how-to-soothe-container {
      width: 100% !important; }
    #soothe-plus .memberships .how-to-soothe-image {
      float: none !important;
      width: 300px !important;
      height: 300px !important;
      margin-left: auto !important;
      margin-right: auto !important; }
    #soothe-plus .memberships .how-to-soothe-right {
      float: none !important;
      width: 90% !important;
      margin-left: auto !important;
      margin-right: auto !important;
      text-align: center !important;
      padding-left: 0 !important; }
    #soothe-plus .memberships .left {
      width: 100% !important; }
    #soothe-plus .memberships .download-icons-desktop {
      display: none; }
    #soothe-plus .memberships .download-icons-mobile {
      display: block !important;
      margin: 0 auto !important; }
    #soothe-plus .memberships .section-download-app .left,
    #soothe-plus .memberships .section-download-app .right {
      clear: both !important;
      width: 100% !important; }
    #soothe-plus .memberships .section-download-app .linkTextingInner {
      margin: 15px auto !important; }
    #soothe-plus .memberships .section-download-app .download-icons {
      overflow: hidden !important;
      width: 263px !important;
      margin: 0 auto !important; }
    #soothe-plus .memberships .section-download-app .download-app-store {
      margin-right: 20px !important;
      float: left; }
    #soothe-plus .memberships .section-download-app .download-google-store {
      float: right; } }
  #soothe-plus .memberships .section-how-to-soothe-plus:after, #soothe-plus .memberships .cards:after, #soothe-plus .memberships .in-minutes:after, #soothe-plus .memberships .lengths:after {
    content: "";
    display: table;
    clear: both; }
  #soothe-plus .memberships .panel-title {
    text-align: center !important; }
  #soothe-plus .memberships .panel-body p {
    letter-spacing: 0px !important; }
  @media (max-width: 911px) {
    #soothe-plus .memberships .details .cards .card {
      width: 100%; } }
  @media (max-width: 413px) {
    #soothe-plus .memberships .section-download-app p {
      display: none; }
    #soothe-plus .memberships .section-download-app .linkTextingInner {
      display: none; } }

#soothe-plus .slick-prev {
  left: -35px; }

#soothe-plus .slick-next {
  right: -35px; }

.desktop-wrapper {
  margin: 0 auto;
  max-width: 1280px; }
  @media (max-width: 600px) {
    .desktop-wrapper {
      padding: 0 10px; } }

.regular-booking {
  font-size: 18px;
  line-height: 28px;
  font-family: "Poppins-Light", sans-serif;
  color: #5F6368; }
  .regular-booking span {
    color: #39455E;
    font-family: "Poppins-SemiBold", sans-serif;
    font-size: 35px; }
  .regular-booking .one-time-container {
    max-width: 625px;
    margin: 25px auto 0 auto; }
  .regular-booking br {
    display: none; }
  @media (max-width: 540px) {
    .regular-booking {
      font-size: 14px; }
      .regular-booking span {
        font-size: 20px; } }
  @media (max-width: 647px) {
    .regular-booking br {
      display: block; } }

.section-container {
  padding: 130px 0; }
  @media (max-width: 1280px) {
    .section-container {
      padding: 60px 0; } }

.faq-section-container {
  background-color: #39455E; }

.section-faq {
  padding: 4em 0; }
  @media (max-width: 1050px) {
    .section-faq {
      margin: auto;
      width: 100%; } }
  @media (max-width: 420px) {
    .section-faq {
      padding: 40px 0; } }

.section-faq-memberships {
  background: #39455E !important; }
  .section-faq-memberships .panel-heading {
    background: #39455E !important; }
  .section-faq-memberships .panel-body {
    background: #39455E; }
  .section-faq-memberships .panel-title {
    text-align: center !important; }

.section-faq-container {
  margin: 0 auto;
  width: 1050px;
  padding: 25px 0; }
  @media (max-width: 1050px) {
    .section-faq-container {
      width: 100%; } }

.faq-title {
  text-align: center;
  margin-bottom: 40px;
  font-size: 2.5rem;
  color: #F9F9F9;
  letter-spacing: 8px;
  font-weight: lighter; }
  @media (max-width: 1050px) {
    .faq-title {
      display: none; } }

.faq-title-responsive {
  text-align: center;
  margin-bottom: 40px;
  font-size: 36px;
  display: none;
  color: #F9F9F9; }
  @media (max-width: 1050px) {
    .faq-title-responsive {
      display: block; } }

.faq-a {
  font-size: 35px !important;
  font-family: "Poppins-Medium", sans-serif;
  text-align: center !important;
  color: #FFFFFF;
  line-height: 45px; }
  .faq-a .faq-triangle-down {
    display: inline-block; }
  .faq-a .faq-triangle-right {
    display: none; }
  .faq-a.collapsed .faq-triangle-right {
    display: inline-block; }
  .faq-a.collapsed .faq-triangle-down {
    display: none; }
  .faq-a:hover {
    text-decoration: none; }
  @media (max-width: 540px) {
    .faq-a {
      font-family: "Poppins-Medium", sans-serif;
      font-size: 20px !important;
      line-height: 30px; } }

.faq-a2 {
  font-size: 18px !important;
  font-family: "Poppins-Regular", sans-serif;
  line-height: 1.5 !important;
  text-shadow: none !important;
  color: #F9F9F9 !important;
  text-decoration: none !important;
  letter-spacing: 6px !important; }
  .faq-a2 .faq-triangle-up {
    display: inline-block; }
  .faq-a2 .faq-triangle-down {
    display: none; }
  .faq-a2.collapsed .faq-triangle-down {
    display: inline-block; }
  .faq-a2.collapsed .faq-triangle-up {
    display: none; }

.faq-a2:hover {
  text-decoration: none; }

.faq-a2:focus {
  text-decoration: none; }

.faq-indent {
  padding-left: 30px; }

.faq-triangle-down {
  vertical-align: middle;
  width: 0;
  height: 0;
  border-color: #CFDEFF transparent transparent transparent;
  position: absolute;
  right: 1%;
  top: 42%; }
  @media (max-width: 1050px) {
    .faq-triangle-down {
      right: 1%;
      top: 49%; } }

.faq-triangle-right {
  vertical-align: middle;
  width: 0;
  height: 0;
  border-color: transparent transparent transparent #CFDEFF;
  position: absolute;
  right: 1%;
  top: 36%; }
  @media (max-width: 1050px) {
    .faq-triangle-right {
      right: 1%;
      top: 36%; } }

.faq-triangle-up {
  vertical-align: middle;
  width: 0;
  height: 0;
  border-style: solid;
  border-left: 9px solid transparent;
  border-right: 9px solid transparent;
  border-bottom: 9px solid #CFDEFF;
  position: absolute;
  right: 1%;
  top: 40%; }
  @media (max-width: 1050px) {
    .faq-triangle-up {
      right: 1%;
      top: 40%; } }

.faq-arrow-down {
  height: 70px;
  width: 70px;
  margin-top: -23px;
  float: right; }
  @media (max-width: 800px) {
    .faq-arrow-down {
      height: 40px;
      width: 40px;
      margin-top: -8px; } }

.panel-group {
  margin-bottom: 0;
  margin-top: 50px; }

.panel {
  box-shadow: none; }

.panel-default {
  border-radius: 6px;
  border: none;
  padding-bottom: 0;
  box-shadow: none;
  background: #39455E; }

.panel-heading {
  padding: 10px 15px; }

.panel-default > .panel-heading {
  background: #39455E;
  border: none;
  position: relative;
  border-radius: 6px;
  border: none;
  padding-bottom: 1em;
  box-shadow: none; }

.panel-default:hover > .panel-heading:hover {
  text-decoration: none; }

.panel-default2 {
  background: #39455E; }

.panel-default2 > .panel-heading {
  background: transparent;
  position: relative;
  border-radius: 6px;
  box-shadow: none; }

.panel-divider {
  border-top: 1px solid rgba(207, 222, 255, 0.5);
  border-radius: 0 !important; }

.make-straight {
  border-radius: 6px 6px 0px 0px !important; }

.panel-title {
  padding: 25px 40px;
  margin-top: 0;
  margin-bottom: 0;
  font-size: 16px;
  color: #FFFFFF; }
  @media (max-width: 540px) {
    .panel-title {
      padding: 15px 30px; } }

.faq-title-copy-container {
  max-width: 680px;
  margin: 0 auto;
  display: inline-block; }

.panel-body {
  padding: 0 0 45px 45px; }
  @media (max-width: 1024px) {
    .panel-body {
      padding: 15px 25px; } }

.panel-body p {
  font-family: "Poppins-Light", sans-serif;
  font-size: 16px !important;
  line-height: 26px !important;
  text-align: left !important;
  max-width: 680px;
  margin: 0 auto;
  color: #FFFFFF; }
  @media (max-width: 540px) {
    .panel-body p {
      font-size: 14px !important;
      margin: 0 auto 10px 0; } }

.panel-body2 {
  box-shadow: none;
  padding: 15px;
  font-size: 14px;
  color: #FFFFFF; }

.panel-body2 p {
  font-size: 18px; }

.gradient-padding {
  margin-bottom: 15%; }

.collapse {
  display: none; }

.collapse.in {
  display: block; }

.panel-group .panel-heading + .panel-collapse > .panel-body {
  border-top: 0px solid #ddd; }

.cancel-policy-padding {
  padding-bottom: 10px; }

.section-faq-container-v4 {
  color: #000000 !important; }

.section-faq-container-v4 h2 {
  font-family: "Poppins-SemiBold", sans-serif;
  font-size: 45px;
  text-align: center;
  line-height: 55px;
  color: #CFDEFF; }
  @media (max-width: 540px) {
    .section-faq-container-v4 h2 {
      font-size: 28px; } }

#link-color {
  text-decoration: none;
  color: #CFDEFF; }

.fact-title-dark {
  color: #FFFFFF !important; }

#about {
  background: #F9F9F9; }

.slick-dots li {
  height: 15px !important;
  width: 15px !important; }
  .slick-dots li button:before {
    border: 0 !important;
    background: transparent !important;
    display: block !important;
    height: 15px !important;
    width: 15px !important;
    outline: none !important;
    line-height: 0px !important;
    font-size: 0px !important;
    background-color: #4A5C73 !important;
    opacity: .20 !important;
    border-radius: 50px !important;
    padding: 5px !important;
    cursor: pointer !important; }

.slick-dots li.slick-active button:before {
  opacity: .75 !important;
  color: black !important; }
