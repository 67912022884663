.pro-card {
  padding: 16px 16px 24px;
  background: #FFFFFF;
  border-radius: 16px; }

.therapist-img {
  width: 100%;
  border-radius: 50%;
  border: 1px solid #E2E2E2; }

.rating-star {
  width: 1vw;
  margin-right: 4px; }

.actionButton, .actionButton:hover, .actionButton:active, .actionButton:focus {
  padding: 10px 12px;
  background: #F58266;
  border-radius: 30px;
  color: white;
  font-size: 14px;
  line-height: 16px; }

#select-a-pro {
  max-width: 1280px;
  min-height: calc(100vh - 400px);
  margin: 0 auto;
  padding: 60px 0;
  color: #2C3239; }
  #select-a-pro .choose-provider {
    text-align: center;
    font-family: "Poppins-Light", sans-serif;
    font-size: 14px;
    line-height: 30px; }
    #select-a-pro .choose-provider .choose-title {
      font-family: "Poppins-SemiBold", sans-serif;
      font-size: 40px;
      line-height: 55px;
      color: #39455E; }
    #select-a-pro .choose-provider .choose-subtitle {
      font-family: "Poppins-Medium", sans-serif;
      font-size: 24px;
      line-height: 32px; }
    #select-a-pro .choose-provider .date-time-bold {
      font-family: "Poppins-SemiBold", sans-serif;
      font-size: 18px; }
    #select-a-pro .choose-provider .we-choose-card-container {
      margin: 0 auto; }
  #select-a-pro .pro-options-container {
    margin: 60px auto 0 auto;
    max-width: 1240px; }
  #select-a-pro .choose-or-container {
    text-align: center; }
    #select-a-pro .choose-or-container .choose-or-divider {
      display: inline-block;
      max-width: 300px;
      width: 100%;
      border-bottom: 1px solid #7089BE;
      margin-bottom: 3px; }
    #select-a-pro .choose-or-container .choose-provider-or {
      margin: 90px auto 50px auto;
      font-family: "Poppins-Medium", sans-serif;
      font-size: 12px;
      text-align: center;
      padding: 0 20px;
      max-width: 60px;
      display: inline-block; }
  @media (max-width: 1279px) {
    #select-a-pro .choose-provider {
      font-size: 14px; }
      #select-a-pro .choose-provider .choose-title {
        font-size: 30px;
        line-height: 40px; }
      #select-a-pro .choose-provider .choose-subtitle {
        line-height: 30px;
        max-width: 200px;
        margin: 0 auto; }
    #select-a-pro .choose-or-container .choose-or-divider {
      max-width: 60px; }
    #select-a-pro .choose-or-container .choose-provider-or {
      margin: 35px auto 30px auto; } }
  @media (max-width: 450px) {
    #select-a-pro {
      max-width: 280px;
      padding: 40px 0px; } }

#select-a-pro-loading {
  min-height: calc(100vh - 300px);
  position: relative; }
  #select-a-pro-loading .loading {
    font-family: "Poppins-Medium", sans-serif;
    font-size: 32px;
    color: #39455E;
    text-align: center;
    width: 100%;
    margin: 0 auto;
    position: absolute;
    top: 50%;
    transform: translateY(-50%); }

#pro-profile-details {
  z-index: 999999999999;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: rgba(39, 47, 67, 0.65);
  color: #2C3239; }
  #pro-profile-details .pro-profile-container {
    min-height: calc(100vh);
    width: 565px;
    background-color: #FFFFFF;
    right: 0;
    position: absolute; }
    #pro-profile-details .pro-profile-container .pro-profile-upper {
      padding: 20px 32px;
      box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05); }
      #pro-profile-details .pro-profile-container .pro-profile-upper .back-to-search, #pro-profile-details .pro-profile-container .pro-profile-upper .back-to-search-mobile {
        font-family: "Poppins-Medium", sans-serif;
        font-size: 16px;
        line-height: 1.38;
        text-align: left;
        color: #2C3239;
        text-decoration: none; }
      #pro-profile-details .pro-profile-container .pro-profile-upper .back-to-search-mobile, #pro-profile-details .pro-profile-container .pro-profile-upper .soothe-logo-icon {
        display: none; }
      #pro-profile-details .pro-profile-container .pro-profile-upper .top-section .pro-img-container {
        margin: 50px auto 0 auto;
        text-align: center; }
        #pro-profile-details .pro-profile-container .pro-profile-upper .top-section .pro-img-container .pro-img {
          border-radius: 50%;
          width: 15vw;
          height: 15vw; }
      #pro-profile-details .pro-profile-container .pro-profile-upper .top-section .pro-name {
        font-family: "Poppins-SemiBold", sans-serif;
        font-size: 30px;
        line-height: 1.83;
        text-align: center;
        padding: 25px 0; }
      #pro-profile-details .pro-profile-container .pro-profile-upper .top-section .pro-details-container {
        font-family: "Poppins-Medium", sans-serif;
        font-size: 18px;
        line-height: 1.22;
        text-align: center;
        color: #39455E; }
        #pro-profile-details .pro-profile-container .pro-profile-upper .top-section .pro-details-container .pro-bookings {
          display: inline-block;
          padding: 21px 47px 21px 0; }
        #pro-profile-details .pro-profile-container .pro-profile-upper .top-section .pro-details-container .pro-rating {
          display: inline-block;
          width: 188.5px;
          border-left: 1px solid rgba(110, 135, 194, 0.5);
          border-right: 1px solid rgba(110, 135, 194, 0.5);
          padding: 21px 0; }
          #pro-profile-details .pro-profile-container .pro-profile-upper .top-section .pro-details-container .pro-rating .star-img {
            width: 21.7px;
            height: 21.9px;
            margin-top: -8px; }
        #pro-profile-details .pro-profile-container .pro-profile-upper .top-section .pro-details-container .pro-year {
          display: inline-block;
          vertical-align: top;
          padding: 21px 0 21px 47px; }
    #pro-profile-details .pro-profile-container .pro-profile-lower {
      padding: 16px; }
      #pro-profile-details .pro-profile-container .pro-profile-lower .middle-section .pro-detail:nth-last-child(1) {
        border-bottom: none; }
      #pro-profile-details .pro-profile-container .pro-profile-lower .middle-section {
        padding-bottom: 100px; }
        #pro-profile-details .pro-profile-container .pro-profile-lower .middle-section .pro-detail {
          padding: 20px 11px;
          border-bottom: 1px solid rgba(110, 135, 194, 0.5);
          font-family: "Poppins-Light", sans-serif;
          text-align: left;
          color: #39455E; }
          #pro-profile-details .pro-profile-container .pro-profile-lower .middle-section .pro-detail .pro-detail-title {
            font-family: "Poppins-Medium", sans-serif;
            font-size: 16px;
            line-height: 2.19; }
          #pro-profile-details .pro-profile-container .pro-profile-lower .middle-section .pro-detail .pro-compliments .compliment-container:nth-child(2), #pro-profile-details .pro-profile-container .pro-profile-lower .middle-section .pro-detail .pro-compliments .compliment-container:nth-child(5) {
            width: 56%; }
          #pro-profile-details .pro-profile-container .pro-profile-lower .middle-section .pro-detail .pro-compliments .compliment-container:nth-child(n - 4) {
            padding-top: 15px; }
          #pro-profile-details .pro-profile-container .pro-profile-lower .middle-section .pro-detail .pro-compliments .compliment-container:nth-child(n + 4) {
            padding-top: 30px; }
          #pro-profile-details .pro-profile-container .pro-profile-lower .middle-section .pro-detail .pro-compliments .compliment-container {
            display: inline-block;
            width: 22%;
            text-align: center;
            vertical-align: top; }
            #pro-profile-details .pro-profile-container .pro-profile-lower .middle-section .pro-detail .pro-compliments .compliment-container .compliment-inner-container .compliment-icon-container {
              margin: 0 auto; }
              #pro-profile-details .pro-profile-container .pro-profile-lower .middle-section .pro-detail .pro-compliments .compliment-container .compliment-inner-container .compliment-icon-container .icon {
                width: 75px;
                height: 75px; }
            #pro-profile-details .pro-profile-container .pro-profile-lower .middle-section .pro-detail .pro-compliments .compliment-container .compliment-inner-container .compliment-copy {
              margin: 10px auto 0 auto;
              max-width: 120px;
              font-family: "Poppins-Medium", sans-serif;
              font-size: 14px;
              line-height: 1.43;
              text-align: center;
              color: #2e364d; }
          #pro-profile-details .pro-profile-container .pro-profile-lower .middle-section .pro-detail .pro-bio {
            font-size: 14px; }
          #pro-profile-details .pro-profile-container .pro-profile-lower .middle-section .pro-detail .pro-languages {
            font-size: 12px; }
          #pro-profile-details .pro-profile-container .pro-profile-lower .middle-section .pro-detail .pro-services {
            font-size: 14px; }
            #pro-profile-details .pro-profile-container .pro-profile-lower .middle-section .pro-detail .pro-services ul {
              margin-bottom: 0; }
      #pro-profile-details .pro-profile-container .pro-profile-lower .pro-profile-btn-container {
        right: 0;
        position: fixed;
        bottom: 0;
        background-color: #FFFFFF;
        padding: 16px;
        width: 565px; }
        #pro-profile-details .pro-profile-container .pro-profile-lower .pro-profile-btn-container .pro-profile-btn {
          padding: 16px 20px;
          background: #F58266;
          border-radius: 30px;
          font-size: 18px;
          line-height: 24px;
          text-align: center;
          color: #FFFFFF;
          width: 100%; }
  @media (max-width: 564px) {
    #pro-profile-details .pro-profile-container {
      max-width: 564px;
      width: 100%; }
      #pro-profile-details .pro-profile-container .pro-profile-upper {
        padding: 20px 5px; }
        #pro-profile-details .pro-profile-container .pro-profile-upper .soothe-logo-icon {
          background-image: url(../../../../Shared/NavBar/Assets/Images/soothe-logo-vector.svg);
          background-repeat: no-repeat;
          background-size: 112px 30px;
          height: 30px;
          width: 112px;
          display: block;
          text-decoration: none;
          margin: -25px auto 0 auto; }
        #pro-profile-details .pro-profile-container .pro-profile-upper .top-section .pro-img-container {
          margin: 30px auto 0 auto;
          text-align: center; }
          #pro-profile-details .pro-profile-container .pro-profile-upper .top-section .pro-img-container .pro-img {
            width: 30vw;
            height: 30vw; }
        #pro-profile-details .pro-profile-container .pro-profile-upper .top-section .pro-name {
          font-size: 24px;
          padding: 15px 0; }
        #pro-profile-details .pro-profile-container .pro-profile-upper .top-section .pro-details-container {
          font-size: 14px; }
          #pro-profile-details .pro-profile-container .pro-profile-upper .top-section .pro-details-container .pro-bookings {
            padding: 18px 20px 18px 0; }
          #pro-profile-details .pro-profile-container .pro-profile-upper .top-section .pro-details-container .pro-rating {
            display: inline-block;
            width: 120px;
            padding: 18px 0; }
            #pro-profile-details .pro-profile-container .pro-profile-upper .top-section .pro-details-container .pro-rating .star-img {
              width: 16.7px;
              height: 16.9px;
              margin-top: -8px; }
          #pro-profile-details .pro-profile-container .pro-profile-upper .top-section .pro-details-container .pro-year {
            padding: 18px 0 18px 20px; }
      #pro-profile-details .pro-profile-container .pro-profile-lower {
        padding: 20px 15px; }
        #pro-profile-details .pro-profile-container .pro-profile-lower .middle-section .pro-detail {
          padding: 15px 8px; }
          #pro-profile-details .pro-profile-container .pro-profile-lower .middle-section .pro-detail .pro-detail-title {
            font-size: 14px; }
          #pro-profile-details .pro-profile-container .pro-profile-lower .middle-section .pro-detail .pro-compliments .compliment-container:nth-child(2), #pro-profile-details .pro-profile-container .pro-profile-lower .middle-section .pro-detail .pro-compliments .compliment-container:nth-child(5) {
            width: 40%; }
          #pro-profile-details .pro-profile-container .pro-profile-lower .middle-section .pro-detail .pro-compliments .compliment-container:nth-child(n - 4) {
            padding-top: 5px; }
          #pro-profile-details .pro-profile-container .pro-profile-lower .middle-section .pro-detail .pro-compliments .compliment-container:nth-child(n + 4) {
            padding-top: 15px; }
          #pro-profile-details .pro-profile-container .pro-profile-lower .middle-section .pro-detail .pro-compliments .compliment-container {
            width: 30%; }
            #pro-profile-details .pro-profile-container .pro-profile-lower .middle-section .pro-detail .pro-compliments .compliment-container .compliment-inner-container .compliment-icon-container .icon {
              width: 60px;
              height: 60px; }
            #pro-profile-details .pro-profile-container .pro-profile-lower .middle-section .pro-detail .pro-compliments .compliment-container .compliment-inner-container .compliment-copy {
              font-size: 12px; }
          #pro-profile-details .pro-profile-container .pro-profile-lower .middle-section .pro-detail .pro-bio, #pro-profile-details .pro-profile-container .pro-profile-lower .middle-section .pro-detail .pro-languages, #pro-profile-details .pro-profile-container .pro-profile-lower .middle-section .pro-detail .pro-services {
            font-size: 12px; }
        #pro-profile-details .pro-profile-container .pro-profile-lower .pro-profile-btn-container {
          width: 100%; } }

.rounded-corners-img {
  width: 100%;
  height: auto;
  border-radius: 5%; }

.main {
  padding: 20px 11px;
  font-family: "Poppins-Light", sans-serif;
  text-align: left;
  color: #39455E; }

.sub-title {
  font-family: "Poppins-Medium", sans-serif;
  font-size: 16px; }

.paragraph {
  font-family: "Poppins-Medium", sans-serif;
  font-size: 13px;
  color: #4A5C73; }

.shadowed {
  box-shadow: 0 5px 10px 0 rgba(0, 0, 0, 0.05); }

.badge-style .MuiBadge-badge {
  background-color: #586B94;
  color: #FFFFFF; }

@media (max-width: 1450px) {
  .max-width-75, .max-width-50 {
    max-width: 100% !important;
    width: 100%;
    padding: 16px; } }

.skip-section-style {
  background: #F1F6FF;
  border-radius: 8px;
  padding: 16px;
  border: 1px solid #586B94; }

.skip-btn-style, .skip-btn-style:hover, .skip-btn-style:active, .skip-btn-style:focus {
  background: #586B94;
  border-radius: 30px;
  padding: 10px 20px;
  color: white; }

.txt-ellipsis-4 {
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical; }

.min-ht-80 {
  min-height: 80px; }

.border-light-gray {
  border: 1px solid #E2E2E2; }

.border-radius-16 {
  border-radius: 16px; }

.border-radius-8 {
  border-radius: 8px; }
