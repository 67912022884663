.background-darkest-blue {
  background-color: #252C3F; }

.max-width-75 {
  max-width: 75vw;
  margin: auto; }

.content-primary-white {
  color: #fff; }

.medium-font {
  font-family: "Poppins-Medium", sans-serif; }

.size-18-24 {
  font-size: 18px;
  line-height: 24px; }

.size-14-16 {
  font-size: 14px;
  line-height: 16px; }

.size-12-20 {
  font-size: 12px;
  line-height: 20px; }

.mb-16 {
  margin-bottom: 16px; }

.mr-16 {
  margin-right: 16px; }

.ptb-40 {
  padding-top: 40px;
  padding-bottom: 40px; }

.p-10 {
  padding: 10px; }

.mb-20 {
  margin-bottom: 20px; }

.pb-32 {
  padding-bottom: 32px; }

.ht-40 {
  height: 40px; }

.pt-32 {
  padding-top: 32px; }

.max-width-1384 {
  max-width: 1384px; }

.max-width-50 {
  max-width: 50%; }

.text-align-left {
  text-align: left; }

.footer-bottom-section {
  padding: 0px 0px 35px 0px; }

.border-bottom-transparent {
  border-bottom: 1px solid rgba(255, 255, 255, 0.2); }

.d-flex {
  display: flex;
  flex-wrap: wrap;
  align-content: flex-end;
  align-items: flex-end; }

.flex-div {
  flex: 1 1 20%; }

.persist-d-flex {
  display: flex;
  align-content: center;
  align-items: center; }

.d-inline-flex {
  display: inline-flex; }

.gap-2 {
  gap: 2em; }

.border-radius-50 {
  border-radius: 50% !important; }

.flex-1 {
  flex: 1 1 20%; }

.ml-10 {
  margin-left: 10em; }

.txt-center {
  text-align: center; }

.custom-flex-div {
  flex: 0 1 14%; }

.font-16 {
  font-family: "Poppins-Regular", sans-serif;
  font-weight: 400;
  font-size: 16px; }

a {
  color: white; }

a:hover {
  text-decoration: none;
  color: white; }

.flex {
  flex: 1;
  text-align: center; }

.apple-width {
  width: 79%; }

.playStore-width {
  width: 89%; }

footer a,
footer a:focus,
footer a:hover,
footer a:active {
  color: #fff; }

.justify-content-space-between {
  justify-content: space-between; }

@media (max-width: 1000px) {
  .sm-text-align-center {
    text-align: center; } }
