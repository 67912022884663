#summary-wrapper, .summary-wrapper {
  font-family: "Poppins-Regular", sans-serif;
  width: 100% !important;
  color: #77787F; }
  #summary-wrapper button, .summary-wrapper button {
    outline: none; }
  #summary-wrapper hr, .summary-wrapper hr {
    margin-top: 20px;
    margin-bottom: 20px;
    border: 0;
    border-top: 1px solid rgba(112, 137, 190, 0.15); }
  #summary-wrapper .book-button, .summary-wrapper .book-button {
    padding: 6px 12px !important;
    background: #67D3CC !important;
    border-radius: 3px; }
  #summary-wrapper .billing-line-item, .summary-wrapper .billing-line-item {
    display: flex;
    margin: 0 0 15px 0px;
    font-family: "Poppins-Light", sans-serif;
    font-size: 16px;
    color: #2B323A; }
    #summary-wrapper .billing-line-item .item, .summary-wrapper .billing-line-item .item {
      flex: 1; }
    #summary-wrapper .billing-line-item .is-credit, .summary-wrapper .billing-line-item .is-credit {
      color: #6DB167; }
    #summary-wrapper .billing-line-item .total-label, .summary-wrapper .billing-line-item .total-label {
      color: #2C3239;
      font-size: 16px;
      font-family: "Poppins-SemiBold", sans-serif !important; }
    #summary-wrapper .billing-line-item .total-amount, .summary-wrapper .billing-line-item .total-amount {
      color: #000000;
      font-size: 20px;
      margin-top: -6px;
      font-family: "Poppins-SemiBold", sans-serif !important; }
  #summary-wrapper .promo-code-link, .summary-wrapper .promo-code-link {
    display: flex;
    margin: 25px 0;
    max-width: 470px; }
    #summary-wrapper .promo-code-link .icon, .summary-wrapper .promo-code-link .icon {
      background-size: contain !important;
      background-repeat: no-repeat !important;
      height: 18px;
      width: 18px; }
    #summary-wrapper .promo-code-link .enter-promo-code, #summary-wrapper .promo-code-link .spafinder-modal-link, .summary-wrapper .promo-code-link .enter-promo-code, .summary-wrapper .promo-code-link .spafinder-modal-link {
      cursor: pointer;
      color: #7089BE;
      font-family: "Poppins-Medium", sans-serif;
      font-style: italic;
      font-size: 16px;
      text-decoration: none;
      padding-left: 15px; }
    #summary-wrapper .promo-code-link .enter-promo-code:hover, #summary-wrapper .promo-code-link .spafinder-modal-link:hover,
    #summary-wrapper .promo-code-link .enter-promo-code:visited, #summary-wrapper .promo-code-link .spafinder-modal-link:visited,
    #summary-wrapper .promo-code-link .enter-promo-code:link, #summary-wrapper .promo-code-link .spafinder-modal-link:link,
    #summary-wrapper .promo-code-link .enter-promo-code:active, #summary-wrapper .promo-code-link .spafinder-modal-link:active, .summary-wrapper .promo-code-link .enter-promo-code:hover, .summary-wrapper .promo-code-link .spafinder-modal-link:hover,
    .summary-wrapper .promo-code-link .enter-promo-code:visited, .summary-wrapper .promo-code-link .spafinder-modal-link:visited,
    .summary-wrapper .promo-code-link .enter-promo-code:link, .summary-wrapper .promo-code-link .spafinder-modal-link:link,
    .summary-wrapper .promo-code-link .enter-promo-code:active, .summary-wrapper .promo-code-link .spafinder-modal-link:active {
      text-decoration: none;
      color: #7089BE; }
  #summary-wrapper .gift-code-container, .summary-wrapper .gift-code-container {
    margin: 20px 0; }
    #summary-wrapper .gift-code-container .gift-code-input, .summary-wrapper .gift-code-container .gift-code-input {
      margin-right: 3.8%;
      display: inline-block;
      border: 0;
      background: #EEE;
      border-radius: 4px;
      max-width: 380px;
      height: 44px;
      color: #555; }
    #summary-wrapper .gift-code-container .gift-apply-input, .summary-wrapper .gift-code-container .gift-apply-input {
      display: inline-block;
      width: 30%;
      height: 33px;
      letter-spacing: 0;
      color: #FFFFFF;
      padding: 0px 10px !important;
      border: 0px solid transparent !important;
      border-radius: 50px !important;
      background: #F58266 !important;
      margin-top: 0px;
      height: 44px; }
  #summary-wrapper .spafinder-title, .summary-wrapper .spafinder-title {
    margin-top: 20px; }
    #summary-wrapper .spafinder-title .wrapSpafinderLegacyForm, #summary-wrapper .spafinder-title .wrapSpafinderVplnForm, .summary-wrapper .spafinder-title .wrapSpafinderLegacyForm, .summary-wrapper .spafinder-title .wrapSpafinderVplnForm {
      margin-bottom: 50px; }
      #summary-wrapper .spafinder-title .wrapSpafinderLegacyForm .spafinder-input, #summary-wrapper .spafinder-title .wrapSpafinderVplnForm .spafinder-input, .summary-wrapper .spafinder-title .wrapSpafinderLegacyForm .spafinder-input, .summary-wrapper .spafinder-title .wrapSpafinderVplnForm .spafinder-input {
        display: block;
        max-width: 100%;
        padding: 20px;
        height: 34px;
        margin-bottom: 15px;
        font-size: 14px;
        line-height: 26px;
        background-color: #EEE;
        background-image: none;
        border-radius: 4px;
        border: 1px solid transparent;
        box-shadow: 0;
        -webkit-box-shadow: none;
        -moz-box-shadow: none;
        color: #555;
        font-family: "Poppins-Light", sans-serif;
        height: 44px; }
      #summary-wrapper .spafinder-title .wrapSpafinderLegacyForm .apply-gift-code, #summary-wrapper .spafinder-title .wrapSpafinderVplnForm .apply-gift-code, .summary-wrapper .spafinder-title .wrapSpafinderLegacyForm .apply-gift-code, .summary-wrapper .spafinder-title .wrapSpafinderVplnForm .apply-gift-code {
        width: 150px;
        border-radius: 50px;
        display: block;
        margin: 0 auto;
        background-color: #F58266 !important;
        color: #FFFFFF !important; }
  #summary-wrapper .spafinder_legacy_card_image, #summary-wrapper .spafinder_vpln_card_image, .summary-wrapper .spafinder_legacy_card_image, .summary-wrapper .spafinder_vpln_card_image {
    display: block;
    margin: auto;
    margin-bottom: 20px; }
    #summary-wrapper .spafinder_legacy_card_image:hover, #summary-wrapper .spafinder_vpln_card_image:hover, .summary-wrapper .spafinder_legacy_card_image:hover, .summary-wrapper .spafinder_vpln_card_image:hover {
      opacity: .7; }
  #summary-wrapper .spafinder_vpln_card_image, .summary-wrapper .spafinder_vpln_card_image {
    width: 100%; }
  #summary-wrapper .spafinder_legacy_card_image, .summary-wrapper .spafinder_legacy_card_image {
    width: 100%; }
  #summary-wrapper .active-btn, .summary-wrapper .active-btn {
    background-color: #67D3CC;
    border: 1px solid #67D3CC !important;
    color: #FFFFFF !important; }
  #summary-wrapper .annual-subscription-disclaimer, .summary-wrapper .annual-subscription-disclaimer {
    color: #77787F;
    max-width: 470px;
    margin: 0 auto 20px auto;
    font-family: "Poppins-Light", sans-serif;
    font-size: 12px;
    line-height: 17px;
    text-align: center; }
    #summary-wrapper .annual-subscription-disclaimer .teal-line, .summary-wrapper .annual-subscription-disclaimer .teal-line {
      max-width: 470px;
      border-top: 1px solid #7089BE;
      margin: 25px auto 15px auto; }
    #summary-wrapper .annual-subscription-disclaimer a, .summary-wrapper .annual-subscription-disclaimer a {
      color: #7089BE;
      font-family: "Poppins-SemiBold", sans-serif; }
    #summary-wrapper .annual-subscription-disclaimer .check-box, .summary-wrapper .annual-subscription-disclaimer .check-box {
      width: 5%;
      display: inline-block;
      vertical-align: top; }
    #summary-wrapper .annual-subscription-disclaimer .check-box-copy, .summary-wrapper .annual-subscription-disclaimer .check-box-copy {
      width: 95%;
      display: inline-block; }
    #summary-wrapper .annual-subscription-disclaimer .annual-disclaimer, .summary-wrapper .annual-subscription-disclaimer .annual-disclaimer {
      display: inline-block;
      text-align: left;
      font-family: "Poppins-Light", sans-serif;
      font-size: 15px;
      line-height: 23px; }
    #summary-wrapper .annual-subscription-disclaimer .annual-disclaimer-italic, .summary-wrapper .annual-subscription-disclaimer .annual-disclaimer-italic {
      width: 100%;
      margin-top: 20px;
      padding: 0;
      font-family: "Poppins-Light", sans-serif;
      font-size: 12px;
      line-height: 17px; }
      @media (max-width: 375px) {
        #summary-wrapper .annual-subscription-disclaimer .annual-disclaimer-italic, .summary-wrapper .annual-subscription-disclaimer .annual-disclaimer-italic {
          padding: 0 5px; } }
  #summary-wrapper .cta-container, .summary-wrapper .cta-container {
    max-width: 325px;
    margin: 0 auto;
    text-align: center; }
    #summary-wrapper .cta-container .continue-btn, .summary-wrapper .cta-container .continue-btn {
      margin: 40px auto 0 auto;
      height: 52px;
      width: 270px;
      background: #F58266;
      color: #FFFFFF;
      border-radius: 50px;
      font-family: "Poppins-SemiBold", sans-serif;
      font-size: 18px;
      line-height: 22px;
      padding: 16px 0;
      text-decoration: none;
      text-transform: capitalize;
      border: none; }
      @media (max-width: 1024px) {
        #summary-wrapper .cta-container .continue-btn, .summary-wrapper .cta-container .continue-btn {
          font-size: 16px !important;
          height: 44px !important;
          width: 230px !important;
          line-height: 20px !important;
          margin: 30px auto 0 auto !important;
          padding: 12px 0px !important; } }
    #summary-wrapper .cta-container .continue-btn:focus, .summary-wrapper .cta-container .continue-btn:focus {
      outline: none; }

.subscription-option {
  text-align: center;
  padding-bottom: 70px; }
  .subscription-option .heading {
    font-size: 14px;
    font-family: "Poppins-SemiBold", sans-serif; }
  .subscription-option .massage-pricings a {
    display: inline-block;
    background-color: #FFFFFF;
    border: 1px solid #67D3CC !important;
    color: #67D3CC !important;
    width: 49%;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none; }
  .subscription-option .massage-pricings .active {
    background-color: #67D3CC;
    border: 1px solid #67D3CC !important;
    color: #FFFFFF !important; }

.box-effect {
  background: #FFF;
  padding: 20px;
  -webkit-box-shadow: 0px 2px 5px 0px #e8e8e8;
  -moz-box-shadow: 0px 2px 5px 0px #e8e8e8;
  box-shadow: 0px 2px 5px 0px #e8e8e8;
  color: #5E6369;
  border-radius: 10px; }

.gray-text {
  font-family: "Poppins-Regular", sans-serif;
  line-height: 23px;
  color: #5E6369; }

.d-flex {
  display: flex; }

.flex-1 {
  flex: 1; }

.mt-15 {
  margin-top: 15px !important; }

.p-40 {
  padding: 40px !important; }

.call-to-action-container {
  margin: 0 auto;
  text-align: center; }
  .call-to-action-container .continue-btn {
    margin: 40px auto 0 auto;
    background: #F58266;
    color: #FFFFFF;
    border-radius: 50px;
    font-family: "Poppins-SemiBold", sans-serif;
    font-size: 18px;
    line-height: 22px;
    padding: 16px;
    text-decoration: none;
    text-transform: capitalize;
    border: none; }
    @media (max-width: 1024px) {
      .call-to-action-container .continue-btn {
        font-size: 16px !important;
        line-height: 20px !important;
        margin: 30px auto 0 auto !important;
        padding: 12px; } }
  .call-to-action-container .continue-btn:focus {
    outline: none; }

.dark-color {
  color: #2C3239; }

.float-right {
  float: right; }
