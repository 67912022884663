.address-search-input-style {
  height: 56px;
  background: rgba(255, 255, 255, 0.9);
  backdrop-filter: blur(20px);
  border-radius: 1000px;
  border: none;
  align-self: stretch;
  margin: 8px 0px;
  font-size: 18px;
  line-height: 28px;
  display: flex;
  display: -webkit-flex;
  align-items: center;
  color: #000;
  padding: 14px 14px 14px 50px;
  box-shadow: none !important; }

.address-search-input-style::placeholder {
  color: #757575; }

.subtitle-style {
  font-size: 18px;
  line-height: 24px;
  text-align: center;
  color: #FFFFFF; }

.login-link, .cta-link {
  text-decoration: underline;
  color: #F58266; }

.input-icon {
  width: 22px;
  height: 22px;
  top: 16.5px;
  left: 16.5px;
  position: absolute;
  cursor: pointer; }

.p-relative {
  position: relative; }

.pac-container, .address-container {
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
  border-radius: 8px;
  margin-top: 11px;
  box-shadow: none;
  max-height: 30vh;
  overflow-y: scroll;
  background-color: #fff; }

.address-container::-webkit-scrollbar {
  width: 4.5px; }

.address-container::-webkit-scrollbar-track {
  background-color: #9993 !important;
  border-radius: 0px 8px 8px 0px;
  margin: 5px 0px; }

.address-container::-webkit-scrollbar-thumb {
  background-color: #586B94 !important;
  cursor: pointer; }

.pac-container:after {
  background-image: none !important;
  height: 0px; }

.pac-icon, .address-icon {
  background-image: url("../../../../../Assets/Images/places_input_marker.png") !important;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 31px;
  margin: 0px; }

.pac-item-selected .pac-icon, .address-item .address-icon {
  background-image: url("../../../../../Assets/Images/selected_place_input_marker.png") !important;
  background-size: contain;
  background-repeat: no-repeat;
  background-position: center;
  position: absolute;
  top: 31px;
  margin: 0px; }

.pac-item {
  padding: 16px 25px;
  position: relative;
  border-top: none; }

.pac-item-query {
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  padding-left: 40px; }

.pac-item-query + span {
  display: block;
  padding-left: 40px;
  font-size: 14px;
  line-height: 20px;
  color: #545454; }

.address-item {
  padding: 0px 16px;
  list-style: none;
  cursor: pointer; }

@media (max-width: 800px) {
  .sm-mt-44 {
    margin-top: 44px; } }

.text-with-icon:last-child {
  margin-bottom: 0px; }
