.mb-22 {
  margin-bottom: 22px; }

.section-title {
  padding: 10px 0px;
  font-size: 16px;
  line-height: 20px;
  color: #000000; }

.grids-section {
  display: flex;
  align-items: center; }

.centered-cell {
  background-color: #EEE;
  color: #000;
  border-radius: 4px;
  cursor: pointer;
  flex: 1 1 100%;
  align-self: stretch;
  align-items: center;
  justify-content: center;
  display: flex;
  text-align: center; }

.selected.centered-cell {
  background-color: #586B94;
  color: white; }
  .selected.centered-cell .cell-title, .selected.centered-cell .cell-subtitle {
    color: white; }

.p-10 {
  padding: 10px 0px; }

.centered-cell:not(:last-child) {
  margin-right: 6px; }

.cell-icon {
  text-align: center; }

.cell-title {
  font-size: 18px;
  line-height: 24px; }

.cell-subtitle {
  font-size: 12px;
  line-height: 16px; }
