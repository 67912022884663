.br-btm-gray {
  border-bottom: 4px solid rgba(0, 0, 0, 0.08); }

.br-btm-gray-thin {
  border-bottom: 1px solid rgba(0, 0, 0, 0.08); }

.second-title-style {
  font-size: 24px;
  line-height: 32px;
  color: #000000;
  padding-top: 24px; }

.pb-8 {
  padding-bottom: 8px; }

.w-100 {
  width: 100%; }

.cursor-pointer {
  cursor: pointer; }

.max-w-50 {
  max-width: 50vw;
  margin: auto; }

.third-title-style {
  font-size: 16px;
  line-height: 20px;
  color: #000000;
  padding: 12px 0px; }

.gray-note {
  font-size: 12px;
  line-height: 20px;
  color: #757575;
  margin: 12px 0px 28px 0px; }

.tip-custom-input-group {
  background: #EEEEEE;
  border-radius: 4px;
  margin: 8px 0px;
  color: #757575;
  font-size: 16px;
  line-height: 24px; }

.tip-custom-input {
  background: transparent;
  border: none;
  height: 48px; }

.tip-currency-addon {
  color: black;
  border: none;
  background-color: transparent; }

.custom-tip-label {
  font-size: 16px;
  line-height: 20px;
  margin: 0px;
  font-weight: normal; }

.clickable {
  cursor: pointer; }

.negative-msg {
  font-size: 14px;
  line-height: 20px;
  color: #C11500; }

.payments-redesign .hero-container {
  background-size: contain;
  background-repeat: no-repeat;
  max-width: 630px;
  padding-bottom: 40px; }
  @media (max-width: 800px) {
    .payments-redesign .hero-container {
      margin-top: 70px; } }
  .payments-redesign .hero-container .hero-content .summary-title {
    font-family: "Poppins-SemiBold", sans-serif;
    font-size: 45px;
    line-height: 55px;
    color: #FFFFFF;
    padding: 70px 0;
    margin: 0; }
    @media (max-width: 1279px) {
      .payments-redesign .hero-container .hero-content .summary-title {
        font-size: 30px; } }

.payments-redesign .title2 {
  text-align: center;
  margin: 60px auto 40px auto;
  font-family: "Poppins-SemiBold", sans-serif;
  font-size: 32px;
  line-height: 30px;
  color: #5F6368; }
  @media (max-width: 1024px) {
    .payments-redesign .title2 {
      font-size: 20px; } }

@media (max-width: 1279px) {
  .payments-redesign {
    display: block; } }

.payments-redesign #client-terms-redesign {
  text-align: left; }
  .payments-redesign #client-terms-redesign a {
    color: #7089BE !important; }

.payments-redesign .terms-btn-container {
  text-align: center;
  margin: 40px 0 20px 0; }
  .payments-redesign .terms-btn-container .accept-terms-btn {
    background: #F58266;
    color: #FFFFFF;
    width: 100%;
    padding: 12px 70px;
    border-radius: 50px;
    font-family: "Poppins-SemiBold", sans-serif; }
    @media (max-width: 1024px) {
      .payments-redesign .terms-btn-container .accept-terms-btn {
        padding: 10px 60px;
        height: 44px; } }

.payments-redesign .details-wrapper {
  text-align: center;
  margin: 0 auto; }
  .payments-redesign .details-wrapper .appt-detail-container {
    background: #FFFFFF;
    margin: 20px auto 10px auto;
    padding: 0 20px 20px 20px;
    max-width: 540px;
    max-height: 320px;
    -webkit-box-shadow: 0px 2px 5px 0px #e8e8e8;
    -moz-box-shadow: 0px 2px 5px 0px #e8e8e8;
    box-shadow: 0px 2px 5px 0px #e8e8e8;
    font-family: "Poppins-Regular", sans-serif;
    line-height: 23px;
    color: #5E6369;
    border-radius: 10px; }
    .payments-redesign .details-wrapper .appt-detail-container .edit-address {
      font-family: "Poppins-SemiBold", sans-serif;
      font-size: 13px;
      color: #F58266;
      text-decoration: none; }
    .payments-redesign .details-wrapper .appt-detail-container .avatar .a1, .payments-redesign .details-wrapper .appt-detail-container .avatar .a2 {
      background-size: cover;
      margin: -20px auto 0 auto;
      height: 40px; }
    .payments-redesign .details-wrapper .appt-detail-container .avatar .a1 {
      width: 40px; }
    .payments-redesign .details-wrapper .appt-detail-container .avatar .a2 {
      width: 61px; }
    .payments-redesign .details-wrapper .appt-detail-container .modality {
      font-size: 16px;
      line-height: 17px;
      color: #2C3239; }
    .payments-redesign .details-wrapper .appt-detail-container .mt-gender {
      font-size: 15px;
      line-height: 17px;
      color: #5F6368;
      padding: 10px 0 15px 0; }
    .payments-redesign .details-wrapper .appt-detail-container .bottom {
      padding-top: 10px; }
    .payments-redesign .details-wrapper .appt-detail-container .left {
      height: 100%;
      text-align: left;
      vertical-align: top; }
    .payments-redesign .details-wrapper .appt-detail-container .right {
      height: 100%;
      text-align: left;
      vertical-align: top; }
    .payments-redesign .details-wrapper .appt-detail-container .icon-container {
      vertical-align: top;
      width: 25px;
      margin-top: 3px; }
      .payments-redesign .details-wrapper .appt-detail-container .icon-container .icon-1 {
        width: 15px;
        height: 15px; }
      .payments-redesign .details-wrapper .appt-detail-container .icon-container .icon-2 {
        width: 11px;
        height: 15px; }
      .payments-redesign .details-wrapper .appt-detail-container .icon-container .icon-3 {
        width: 13px;
        height: 15px; }
      .payments-redesign .details-wrapper .appt-detail-container .icon-container .icon-4 {
        width: 15px;
        height: 15px; }
    .payments-redesign .details-wrapper .appt-detail-container .icon-container, .payments-redesign .details-wrapper .appt-detail-container .copy-container {
      display: inline-block; }
    @media (max-width: 550px) {
      .payments-redesign .details-wrapper .appt-detail-container {
        padding: 20px; }
        .payments-redesign .details-wrapper .appt-detail-container .bottom {
          padding-top: 0; }
        .payments-redesign .details-wrapper .appt-detail-container .right, .payments-redesign .details-wrapper .appt-detail-container .left {
          width: 100%;
          margin: 0 auto;
          padding: 5px 0; } }

.payments-redesign .credit-card-select {
  display: flex;
  display: -webkit-flex;
  max-width: 470px;
  margin: 30px auto 10px auto;
  padding: 20px; }
  .payments-redesign .credit-card-select .icon {
    background-size: 16px 12px !important;
    background-repeat: no-repeat !important;
    margin-top: 3px;
    height: 12px;
    width: 20px; }
  .payments-redesign .credit-card-select .detail {
    padding-left: 15px;
    color: #2C3239;
    font-family: "Poppins-Regular", sans-serif;
    font-size: 16px;
    width: 100%;
    max-width: 400px; }
  .payments-redesign .credit-card-select .edit-card {
    color: #7089BE;
    padding-top: 10px; }
    .payments-redesign .credit-card-select .edit-card .edit-icon {
      height: 20px;
      width: 20px; }
    .payments-redesign .credit-card-select .edit-card .edit-icon:focus {
      outline: none; }

.payments-redesign .credit-card-add {
  max-width: 470px;
  margin: 0 auto; }

.payments-redesign .dotted-border {
  background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' rx='5' ry='5' stroke='rgba(95, 99, 104, 0.3)' stroke-width='2' stroke-dasharray='6%2c 9' stroke-dashoffset='14' stroke-linecap='round'/%3e%3c/svg%3e");
  border-radius: 5px;
  height: 85px;
  max-width: 470px;
  line-height: 85px;
  text-align: center;
  color: #7089BE;
  font-family: "Poppins-Medium", sans-serif;
  font-size: 16px; }
  .payments-redesign .dotted-border .plus-icon {
    height: 20px;
    width: 20px; }

.payments-redesign .back-btn-container {
  text-align: center; }
  .payments-redesign .back-btn-container .back-btn {
    font-family: "Poppins-SemiBold", sans-serif;
    font-size: 18px;
    line-height: 22px;
    color: #F58266; }
    @media (max-width: 1024px) {
      .payments-redesign .back-btn-container .back-btn {
        font-size: 16px; } }

.payments-redesign h3 {
  text-align: center;
  font-family: "Poppins-SemiBold", sans-serif;
  font-size: 45px;
  line-height: 55px;
  color: #39455E;
  margin-bottom: 30px; }
  @media (max-width: 1280px) {
    .payments-redesign h3 {
      padding: 35px 0;
      margin-bottom: 0px; } }
  @media (max-width: 800px) {
    .payments-redesign h3 {
      margin-top: 40px; } }

.payments-redesign .content-add-card {
  padding: 0 55px; }
  .payments-redesign .content-add-card .stripe-cardnumber-element, .payments-redesign .content-add-card .stripe-expiration-element, .payments-redesign .content-add-card .stripe-cvc-element, .payments-redesign .content-add-card input {
    box-shadow: 0 !important;
    -webkit-box-shadow: none !important;
    -moz-box-shadow: none !important;
    color: #2C3239 !important;
    font-family: "Poppins-Light", sans-serif !important;
    height: 44px !important;
    border-radius: 50px !important;
    border: 1px solid transparent !important;
    background: #F9F9F9 !important;
    line-height: 26px !important; }
  .payments-redesign .content-add-card input {
    box-shadow: 0;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    color: #2C3239;
    font-family: "Poppins-Light", sans-serif;
    height: 44px;
    border-radius: 50px;
    border: 1px solid transparent;
    background: #F9F9F9;
    line-height: 26px; }
  .payments-redesign .content-add-card label {
    clear: both;
    float: left;
    width: 100%;
    color: #5f6368;
    font-family: "Poppins-Medium", sans-serif;
    font-size: 13px;
    line-height: 21px;
    margin: 15px 0 10px; }
  @media (max-width: 485px) {
    .payments-redesign .content-add-card {
      padding: 0; } }
  .payments-redesign .content-add-card .add-card-container {
    text-align: center; }
    .payments-redesign .content-add-card .add-card-container .add-credit-card {
      height: 52px !important;
      background: #F58266 !important;
      color: #FFFFFF !important;
      border-radius: 50px !important;
      font-family: "Poppins-SemiBold", sans-serif !important;
      font-size: 18px !important;
      line-height: 22px !important;
      padding: 16px 0 !important;
      margin: 40px auto 0 auto !important; }
    .payments-redesign .content-add-card .add-card-container .add-credit-card:focus {
      border: none; }
  .payments-redesign .content-add-card .back {
    background-color: #FFFFFF;
    font-family: "Poppins-SemiBold", sans-serif;
    font-size: 18px;
    line-height: 22px;
    color: #F58266; }
  .payments-redesign .content-add-card .btn {
    margin-bottom: 20px; }

.payments-redesign .content {
  padding: 0 10px; }
  .payments-redesign .content .selected {
    border: 1px solid #F58266;
    border-radius: 5px; }
  .payments-redesign .content .credit-card-wrapper {
    position: relative;
    max-width: 470px;
    height: 85px;
    margin: 0 auto;
    padding: 20px; }
    .payments-redesign .content .credit-card-wrapper .radio-container {
      display: inline-block; }
      .payments-redesign .content .credit-card-wrapper .radio-container .radio-item {
        display: none !important; }
      .payments-redesign .content .credit-card-wrapper .radio-container .label-item {
        margin-top: 0px; }
        .payments-redesign .content .credit-card-wrapper .radio-container .label-item img {
          width: 15px;
          height: 10px;
          cursor: pointer; }
      .payments-redesign .content .credit-card-wrapper .radio-container .radio-item:checked + label {
        opacity: 1;
        width: 10px;
        height: 15px; }
      .payments-redesign .content .credit-card-wrapper .radio-container input[type='radio']:checked {
        position: absolute;
        visibility: hidden; }
      .payments-redesign .content .credit-card-wrapper .radio-container input[type='radio']:checked ~ .check::before {
        border: 1px solid #F58266; }
    .payments-redesign .content .credit-card-wrapper input[type=radio] {
      margin: auto 5px; }
    .payments-redesign .content .credit-card-wrapper .credit-card-content {
      height: 60px;
      display: inline-block;
      margin-left: 25px; }
      @media screen and (max-width: 766px) {
        .payments-redesign .content .credit-card-wrapper .credit-card-content {
          height: 80px; } }
      .payments-redesign .content .credit-card-wrapper .credit-card-content .cc-icon {
        height: 23px;
        width: 39px;
        display: inline-block;
        vertical-align: top;
        margin-top: 25px;
        margin-right: 20px; }
      .payments-redesign .content .credit-card-wrapper .credit-card-content .details-container {
        display: inline-block;
        font-size: 16px; }
        .payments-redesign .content .credit-card-wrapper .credit-card-content .details-container .cc-details {
          display: block;
          color: #2C3239;
          font-family: "Poppins-Regular", sans-serif; }
        .payments-redesign .content .credit-card-wrapper .credit-card-content .details-container .cc-details-2 {
          color: #5F6368;
          font-family: "Poppins-Light", sans-serif; }
        .payments-redesign .content .credit-card-wrapper .credit-card-content .details-container .btn-delete {
          color: #000000;
          text-decoration: underline;
          cursor: pointer; }

.mb-106 .popup {
  background: none !important; }

.applicable-option {
  display: flex;
  display: -webkit-flex;
  justify-content: space-between;
  -webkit-justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  padding: 16px;
  margin-right: 16px;
  border: 1px solid #E2E2E2; }
  .applicable-option .apply-btn {
    padding: 10px 12px;
    background: #F2F4FF;
    border-radius: 9999px;
    color: #5F637A;
    font-family: "Poppins-Medium", sans-serif; }

.applicable-option.applied {
  border: none;
  background: #F2F4FF; }
  .applicable-option.applied .apply-btn {
    padding: 10px 12px;
    border-radius: 9999px;
    background: #CCD2F5;
    color: #586B94;
    font-family: "Poppins-Medium", sans-serif; }

.slick-slider .slick-prev {
  top: 25%; }
