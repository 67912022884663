body {
  scroll-behavior: smooth; }

.max-w-100 {
  max-width: 100vw; }

.full-height-section {
  min-height: 100vh;
  scroll-behavior: smooth; }

.pb-0 {
  padding-bottom: 0px; }

.p-xlarge {
  padding: 100px 37vw; }

.p-large {
  padding: 100px 23vw; }

.p-medium {
  padding: 100px 20vw; }

.p-small {
  padding: 100px 10vw; }

.p-xsmall {
  padding: 40px 10vw; }

.blue-subtle {
  background: #F1F7FF; }

.bg-primary {
  background-color: white; }

.bg-secondary {
  background-color: #F6F6F6; }

.bg-tertiary {
  background: #EEEEEE; }

.cactus-flower-primary {
  background: #F58266; }

.font-cactus-flower-primary {
  color: #F58266; }

.dark-blue-primary {
  color: #39455E; }

.light-mode-secondary {
  color: #545454; }

a.dark-blue-primary:hover, a.dark-blue-primary:active {
  text-decoration: none;
  color: #39455E; }

.font-style-medium {
  font-family: "Poppins-Medium", sans-serif;
  color: #000000; }

.font-style-regular {
  font-family: "Poppins-Regular", sans-serif;
  color: #000000; }

.txt-align-center {
  text-align: center; }

.txt-align-justify {
  text-align: justify; }

.title-1 {
  font-size: 52px;
  line-height: 64px;
  font-weight: 500; }

.title-2 {
  font-weight: normal;
  font-size: 18px;
  line-height: 28px; }

.text {
  font-size: 18px;
  line-height: 28px; }

.small-text {
  font-size: 16px;
  line-height: 24px; }

.xs-text-size {
  font-size: 14;
  line-height: 20px; }

.color-dark-gray {
  color: #545454; }

.cta-btn {
  width: 343px;
  height: 56px;
  border-radius: 30px;
  color: white;
  padding: 0px 20px;
  font-size: 18px;
  line-height: 24px;
  margin-top: 14px;
  max-width: 100%; }

.cta-btn:hover, .cta-btn:active, .cta-btn:focus {
  color: white; }

.label-style {
  font-size: 18px;
  line-height: 24px;
  font-family: 'Poppins-Regular', sans-serif; }

.label-style:hover, .label-style:active, .label-style:focus {
  text-decoration: none; }

.steps-indicator {
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 12px; }

.double-card-img {
  width: 24vw; }

.selected-card-img {
  width: 448px;
  height: 283px; }

.light-box {
  background: white;
  border: 2px solid #E2E2E2;
  box-sizing: border-box;
  border-radius: 8px;
  margin: 0px 5px;
  padding: 16px;
  height: 100%;
  cursor: pointer; }

.selected-type {
  border: 2px solid #F58266; }

.equal {
  display: flex;
  display: -webkit-flex; }

.equal-flex {
  display: inline-flex; }

.img-icon {
  float: right;
  padding-top: 3px; }

#step5 .rdtPicker {
  font-family: 'Poppins-Regular', sans-serif;
  color: #000000;
  margin: auto;
  width: 374px;
  box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.12) !important;
  border-radius: 16px;
  font-size: 18px;
  line-height: 40px;
  max-width: 100%; }

.location-selector {
  border: none;
  height: 56px;
  margin: 8px 0px; }

.location-selector-wrapper {
  width: 45%;
  margin: auto; }

.location-selector-label {
  font-family: 'Poppins-Regular', sans-serif;
  font-size: 18px;
  line-height: 24px; }

.amount-heading {
  font-family: 'Poppins-Medium', sans-serif;
  font-size: 24px;
  line-height: 32px; }

.col {
  max-width: 20%; }

.input-checkmark {
  visibility: hidden;
  position: absolute;
  top: 55%;
  right: 5%; }

.success {
  position: relative; }

.success .input-checkmark {
  visibility: visible; }

.stripe-cardnumber-element.input-style {
  padding-top: 18px; }

.stripe-cardnumber-element.StripeElement--complete + .input-checkmark {
  visibility: visible;
  right: 15%;
  top: 42.5%; }

.with-icon .stripe-cardnumber-element.StripeElement--complete + .input-checkmark {
  right: 7.5%; }

.input-xmark {
  visibility: hidden;
  position: absolute;
  top: 55%;
  right: 5%; }

.erroneous {
  position: relative; }

.erroneous .input-xmark {
  visibility: visible; }

.stripe-cardnumber-element.StripeElement--invalid ~ .input-xmark, .stripe-cardnumber-element.StripeElement--focus ~ .input-xmark {
  visibility: visible;
  right: 15%;
  top: 42.5%; }

.with-icon .stripe-cardnumber-element.StripeElement--invalid ~ .input-xmark, .with-icon .stripe-cardnumber-element.StripeElement--focus ~ .input-xmark {
  right: 7.5%; }

.stripe-cardnumber-element.StripeElement--empty ~ .input-xmark, .stripe-cardnumber-element.StripeElement--complete ~ .input-xmark {
  visibility: hidden; }

.missing-info-message {
  color: #C11500;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  padding: 16px 16px 0px; }

.small-gray-text {
  font-family: 'Poppins-Regular', sans-serif;
  font-size: 12px;
  line-height: 20px;
  text-align: center;
  color: #757575;
  margin: 32px auto;
  max-width: 416px; }

.w-100 {
  width: 100%; }

.slick-next {
  background: url("./images/Arrow_right_blue.png") center no-repeat !important; }

.slick-prev {
  background: url("./images/Arrow_left_blue.png") center no-repeat !important; }

.txt-align-left {
  text-align: left; }

.m-auto {
  margin: auto; }

.input-style {
  background: #EEEEEE;
  border-radius: 4px;
  border: none;
  margin-bottom: 24px;
  min-height: 56px;
  padding: 14px;
  color: black; }

.p-8 {
  padding: 8; }

.border-radius-10 {
  border-radius: 10px; }

label {
  text-align: left; }

.br-16 {
  border-radius: 16px; }

.height-280 {
  height: 280px; }

.max-height-70 {
  max-height: 70px; }

.disabled-multi-level-options-container, .disabled-multi-selector-button, .disabled-options-group {
  display: none; }

.multi-selector-container {
  background-color: #EEE;
  font-family: 'Poppins-Regular', sans-serif; }

.disabled-options-value, .disabled-remove-group {
  display: inline-block !important; }

.options-selected-container.disabled-options-selected-container > div {
  display: none; }

.disabled-arrow-down, .disabled-arrow-up {
  display: none; }

.disabled-multi-level-options-container {
  display: none; }

.disabled-multi-level-options-container .options-main-menu {
  display: none; }

.multi-level-selector-container .multi-selector-container .multi-selector-button {
  height: 75% !important; }

.multi-level-selector-container .multi-selector-container {
  border: none !important;
  height: 56px;
  margin: 8px 0px;
  background-color: #EEE !important; }

.options-sub-menu-container label {
  display: block; }

.text-area-counter {
  font-size: 14px;
  line-height: 20px;
  color: #757575; }

.text-area-style {
  margin-bottom: 8px;
  min-height: 154px; }

.multi-level-selector-container .multi-level-options-container .options-main-menu .options-sub-menu .checkbox .checkmark {
  display: none !important; }

.cta-btn.disabled, .cta-btn.disabled:hover, .cta-btn.disabled:focus {
  background: #F6F6F6;
  color: #AFAFAF; }

.checkout-modal {
  padding: 8px 0px;
  margin: 15px auto;
  max-height: 100%;
  background: #FFFFFF;
  border: 1px solid #E2E2E2;
  border-radius: 16px; }

.add-gift-card {
  border-radius: 30px;
  align-items: center;
  color: #7089BE;
  border: 1px solid #7089BE;
  padding: 16px 40px;
  background: transparent;
  font-size: 18px;
  line-height: 24px; }

.plus-icon {
  margin: 0px 8px; }

.card-design {
  max-width: 200px; }

.mb-24 {
  margin-bottom: 24px; }

.mb-48 {
  margin-bottom: 48px; }

.mb-52 {
  margin-bottom: 52px; }

.mb-40 {
  margin-bottom: 40px; }

.mb-8 {
  margin-bottom: 8px; }

.mb-0 {
  margin-bottom: 0px; }

.mr-0 {
  margin-right: 0px; }

.ml-0 {
  margin-left: 0px; }

.pt-44 {
  padding-top: 44px; }

.p-16 {
  padding: 16px; }

.p-12-16 {
  padding: 12px 16px; }

.heading-1 {
  font-size: 24px;
  line-height: 32px; }

.pt-118 {
  padding-top: 118px; }

.cta-btn-size {
  width: 100%;
  border-radius: 30px; }

.m-10-0 {
  margin: 10px 0px; }

.height-48 {
  height: 48px; }

a, a:active, a:hover, a:focus {
  color: #F58266; }

.stripe-cardnumber-element {
  box-shadow: 0 !important;
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  color: #2C3239 !important;
  font-family: "Poppins-Regular", sans-serif !important;
  height: 48px !important;
  background: #EEEEEE;
  border-radius: 4px;
  border: none;
  margin-bottom: 24px;
  padding: 14px; }

.with-icon .stripe-cardnumber-element {
  padding: 18px 14px 14px 60px; }

.with-icon .img-icon {
  position: absolute;
  top: 36%;
  left: 6%; }

.display-flex-2 {
  display: flex;
  display: -webkit-flex; }

.dlt-crd-btn {
  background: transparent;
  width: 100%; }

.lottie-container {
  text-align: center;
  width: 100vw;
  z-index: 9999;
  position: absolute;
  top: 10vh;
  background-color: white;
  height: 100%; }

.lottie-container svg, .lottie-small-container svg {
  width: 250px !important; }

.lottie-small-container {
  text-align: center;
  z-index: 9999; }

.plain-link {
  background-color: transparent;
  text-decoration: underline;
  border: none;
  cursor: pointer;
  padding: 0px; }

.plain-link:focus {
  outline: none; }

form .intl-tel-input {
  width: 100%; }

.v-hidden {
  visibility: hidden;
  position: relative; }

.d-none {
  display: none; }

.mb-14 {
  margin-bottom: 14px; }

.mb-12 {
  margin-bottom: 12px; }

.animation-title {
  font-size: 28px;
  line-height: 36px; }

.custom-lottie-text {
  position: absolute;
  bottom: 15vh;
  width: 100vw; }

.mt-7vh {
  margin-top: 7vh; }

.mb-7vh {
  margin-bottom: 7vh; }

.mtb-105 {
  margin: 1.5vh auto; }

.mb-30 {
  margin-bottom: 30px; }

.mb-32 {
  margin-bottom: 32px; }

.mtb-12 {
  margin-top: 12px;
  margin-bottom: 12px; }

.multi-selector-placeholder {
  font-size: 18px;
  line-height: 28px; }

.p-24-16 {
  padding: 24px 16px; }

.multi-level-selector-container .multi-level-options-container .options-main-menu .options-sub-menu .checkbox {
  height: 1px !important;
  width: 1px !important;
  margin: 0px !important; }

.options-label {
  font-weight: normal !important;
  font-family: "Poppins-Regular", sans-serif !important;
  font-size: 16px !important;
  line-height: 20px !important; }

.multi-level-selector-container .multi-level-options-container .options-main-menu .options-container .options-label {
  max-width: 100% !important; }

.options-container .options-sub-menu-container .options-sub-menu-container .options-sub-menu {
  width: 100% !important; }

.options-container .options-sub-menu-container .options-sub-menu-container .options-sub-menu .options-label {
  flex: 0 1 100%; }

.options-container .options-sub-menu-container .options-sub-menu-container .options-sub-menu .options-label:after {
  content: "Select";
  background: #FEEDE9;
  padding: 10px 12px;
  border-radius: 9999px;
  font-family: "Poppins-Regular", sans-serif;
  color: #A14538;
  float: right;
  text-align: right;
  margin-top: -10px;
  margin-bottom: -7px;
  margin-left: 16px;
  margin-right: -16px; }

.rdtNew {
  color: black !important; }

.rdtPicker td.rdtActive, .rdtPicker td.rdtActive:hover {
  background-color: #2E364D;
  border-radius: 50%;
  padding: 5px 0px;
  color: white !important; }

.rdtPicker td.rdtToday:before {
  border-bottom: 7px solid #2E364D; }

.intl-tel-input .selected-flag {
  padding-left: 14px; }

.options-container {
  position: relative; }

.btn-selector {
  background: #FEEDE9;
  padding: 10px 12px;
  border-radius: 9999px;
  font-family: "Poppins-Regular", sans-serif;
  color: #A14538 !important;
  float: right; }

.top-neg-30 {
  top: -30vh; }

::-webkit-scrollbar {
  width: 3px; }

.MuiFormControl-root {
  border: none !important;
  background: #EEE !important;
  border-radius: 4px;
  height: 56px;
  cursor: pointer; }

li .MuiFormControl-root {
  border: none !important;
  background: none !important;
  border-radius: none;
  height: auto; }

.MuiFormLabel-root {
  cursor: pointer; }

.MuiListSubheader-root {
  font-family: "Poppins-Medium", sans-serif !important;
  font-size: 24px !important;
  line-height: 32px !important;
  color: #000000 !important;
  padding: 16px 16px 8px 16px !important;
  background-color: white !important; }

.MuiMenuItem-root {
  font-family: "Poppins-Medium", sans-serif !important;
  font-size: 16px !important;
  line-height: 20px !important;
  color: #000000 !important;
  padding-left: 16px !important;
  padding-right: 16px !important; }

.MuiMenuItem-root:not(:last-child) {
  border-bottom: 1px solid #E2E2E2 !important; }

.MuiMenuItem-root:last-child::after {
  border-bottom: none !important; }

.MuiList-padding {
  padding-bottom: 0px !important;
  padding-top: 0px !important; }

.flex-grow-1 {
  flex-grow: 1; }

.MuiListSubheader-root {
  z-index: 2 !important; }

.MuiPopover-paper {
  border-radius: 16px !important;
  max-height: calc(100% - 125px) !important;
  padding: 0px;
  filter: drop-shadow(0px 10px 10px rgba(0, 0, 0, 0.12));
  box-shadow: none; }

.MuiListItem-button:hover {
  background-color: none; }

.smooth-show-hide {
  overflow: hidden;
  transition: height 0.3s ease;
  height: 0px;
}
.smooth-show-hide.visible {
  height: auto;
  transition: height 0.3s ease;
}
.custom-color {
  color: #000000;
}
.custom-color::placeholder {
  color: #AFAFAF;
}
.custom-color.error-state,
.custom-color.error-state:focus {
    background: #FFEFED;
}
.light-box:has(.custom-color.error-state) {
    border: 2px solid #C11500;
    background: #FFEFED;
}
.fit-h-w {
  width: 100%;
  height: auto;
}
@media (max-width: 1200px) {
  .p-medium {
    padding: 100px 10vw; }
  .location-selector-wrapper {
    width: 100%; }
  .multi-level-selector-container > .multi-level-options-container > .options-main-menu > .options-container > .options-sub-menu-container {
    width: 35vw !important; }
  .multi-level-selector-container .multi-level-options-container .options-main-menu .options-container .options-sub-menu-container .options-container .options-sub-menu-container {
    right: 28vw !important;
    width: 28vw !important; } }

@media (max-width: 800px) {
  .equal {
    display: block; }
  #gc-checkout .full-height-section {
    min-height: auto; }
  .custom-lottie-text {
    bottom: 40vh; }
  .lottie-container {
    top: 0px; }
  .lottie-container svg {
    height: 70vh !important; }
  .p-xlarge {
    padding: 100px 16px; }
  .p-small {
    padding: 100px 16px 30px 16px; }
  .with-icon .img-icon {
    top: 36%;
    left: 15%; }
  .sm-7-left {
    left: 7%!important; }
  .double-card-img {
    width: 250px; }
  .title-1 {
    font-size: 24px;
    line-height: 32px; }
  .p-large {
    padding: 100px 16px; }
  .p-medium {
    padding: 100px 16px; }
  .text {
    font-size: 14px;
    line-height: 20px; }
  .mb-16 {
    margin-bottom: 16px; }
  .mb-sm-8 {
    margin-bottom: 8px; }
  .mb-sm-4 {
    margin-bottom: 4px; }
  .sm-16-20 {
    font-size: 16px;
    line-height: 20px; }
  .small-text {
    font-size: 14px;
    line-height: 20px; }
  .sm-gray-font {
    color: #545454; }
  .light-box {
    text-align: left; }
  .pt-sm-192 {
    padding-top: 192px; }
  .steps-indicator {
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 24px; }
  .mb-sm-0 {
    margin-bottom: 0px; }
  .sm-custom-text {
    font-size: 18px;
    line-height: 28px; }
  .mb-sm-24 {
    margin-bottom: 24px; }
  .location-selector-label {
    font-size: 14px;
    line-height: 16px; }
  .multi-selector-placeholder {
    font-size: 14px;
    line-height: 20px; }
  .mb-sm-32 {
    margin-bottom: 32px; }
  .mb-sm-45 {
    margin-bottom: 45px; }
  .amount-heading {
    font-size: 16px;
    line-height: 20px; }
  .equal-flex {
    display: inline-block; }
  .col {
    width: 100vw;
    max-width: 100%; }
  .mb-sm-23 {
    margin-bottom: 23px; }
  .small-gray-text {
    text-align: left; }
  #step5 .rdtPicker {
    width: 100%; }
  .options-sub-menu-container {
    left: -35%;
    top: 20px !important; }
  .multi-level-selector-container .multi-level-options-container .options-main-menu .options-sub-menu {
    padding: 0px !important; }
  .sm-txt-align-left {
    text-align: left; }
  .sm-18-24 {
    font-size: 18px;
    line-height: 24px; }
  .checkout-modal {
    max-height: 100%; }
  .pt-sm-24 {
    padding-top: 24px; }
  .mtb-sm-6 {
    margin-top: 6px;
    margin-bottom: 6px; }
  hr {
    margin-bottom: 12px;
    margin-top: 12px; }
  .mb-sm-28 {
    margin-bottom: 28px; }
  .options-container .options-sub-menu-container .options-sub-menu-container .options-sub-menu .options-label {
    padding: 16px; }
  .options-container .options-sub-menu-container .options-sub-menu-container .options-sub-menu .options-label:after {
    margin: 0px;
    display: block;
    float: none;
    text-align: center; }
  .multi-level-selector-container .multi-level-options-container .options-main-menu .options-container .options-label {
    word-break: normal !important;
    display: inline-block !important;
    width: 35vw !important; }
  .stripe-cardnumber-element.StripeElement--complete + .input-checkmark, .with-icon .stripe-cardnumber-element.StripeElement--complete + .input-checkmark {
    right: 8%;
    top: 55%; }
  .stripe-cardnumber-element.StripeElement--invalid ~ .input-xmark, .stripe-cardnumber-element.StripeElement--focus ~ .input-xmark {
    right: 8%;
    top: 55%; }
  .multi-level-selector-container > .multi-level-options-container > .options-main-menu > .options-container > .options-sub-menu-container {
    width: 61vw !important; }
  .multi-level-selector-container > .multi-level-options-container > .options-main-menu > .options-container > .options-sub-menu-container:hover {
    min-height: 27vh; }
  .multi-level-selector-container .multi-level-options-container .options-main-menu .options-container .options-sub-menu-container {
    transform: translate(80%, 0) !important; }
  .multi-level-selector-container .multi-level-options-container .options-main-menu .options-container .options-sub-menu-container .options-container .options-sub-menu-container {
    width: 45vw !important; }
  .label-style {
    font-size: 14px;
    line-height: 16px; } }
